import React from 'react';
import styles from './event.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import Close from 'svgComponents/close';
import { hexToRgbA } from 'util/styleUtil';
import IncrementArrow from 'svgComponents/incrementArrow';
import DecrementArrow from 'svgComponents/decrementArrow';
import FlagIcon from 'svgComponents/flagIcon';
import metricsKeyMapping from 'config/metrics';
import Budget from 'svgComponents/budget';
import Skill from 'svgComponents/skill';
import Morale from 'svgComponents/morale';
import CustSat from 'svgComponents/custSat';
import FilledButton from "commonComponents/buttons/filledButton";
import updateRoute from 'util/webAppRoutes/updateRoute';



const Event = (props) => {

	const myStyles = getSkin(props.skinGuide);

	const { userEventsList, eventsToBeShownCount } = props.userEvents;

	const latestEvent = userEventsList[userEventsList.length - eventsToBeShownCount];

	if (!latestEvent) {
		return (
			<div className={css(myStyles.container, myStyles.responseMessage)} styleName="container">
				No new event
			</div>
		);
	}

	let overallImpact = 0;
	latestEvent.userMetrics.map(userMetric => {
		overallImpact += userMetric.diff;
		return 1;
	});

	let eventType = 'neutral';
	if (overallImpact > 0) {
		eventType = 'positive';
	}
	else if (overallImpact < 0) {
		eventType = 'negative';
	}

	const getTagBGClass = (changeType) => {
		switch (changeType) {
			case 'positive':
				return 'greenTag';

			case 'negative':
				return 'redTag';

			default:
				return 'greenTag';
		}
	}

	const renderArrow = (changeType) => {
		switch (changeType) {
			case 'positive':
				return (
					<div styleName="arrow-container">
						<IncrementArrow />
					</div>
				);

			case 'negative':
				return (
					<div styleName="arrow-container">
						<DecrementArrow />
					</div>
				);

			default:
				return null;
		}
	}

	const renderTag = (changeValue, changeType, metricType) => {
		if (changeType == 'neutral') {
			return null;
		}
		const tagBGClass = getTagBGClass(changeType);
		let changePercent = changeValue;
		const title = props.getLabel(metricType);
		if(title=== 'Skill' || title === 'Morale' || title === 'Customer Value'){
			changePercent += '%'
		}

		return (
			<div
				className={css(myStyles.metricChange, myStyles[tagBGClass])}
				styleName="metrics-change"
			>
				{renderArrow(changeType)}
				<span>{changePercent}</span>
			</div>
		);
	}

	const getMetricImage = (metricType,color) => {
		switch (props.getLabel(metricType)) {
			case 'Budget Remaining':
				return <Budget color={color}/>;
			case 'Skill':
				return <Skill color={color}/>;
			case 'Morale':
				return <Morale color={color}/>;
			case 'Customer Value':
				return <CustSat color={color}/>;
			default:
				return;
		}
	}

	const renderGameMetrics = (title, value, changeValue, changeType) => {
		return (
			// <div className={css(myStyles.leftMetricContainer)} styleName="left-metric-container">
			// 	<div className={css(myStyles.metricTitle)} styleName="metric-title">{title}</div>
			// 	<div styleName="left-metrics-value-change-container">
			// 		<div styleName="metrics-image">
			// 			<FlagIcon />
			// 		</div>
			// 		<div className={css(myStyles.metricValue)} styleName="metrics-value">{value}</div>
			// 		{renderTag(changeValue, changeType)}
			// 	</div>
			// </div>
			<div styleName="metric-container" className={css(myStyles.metricContainer)}>
				<div styleName="metric-content" className={css(myStyles.metricContent)}>
					<div styleName="metric-image-container">
						<div styleName="metric-image">
							{getMetricImage(title,"black")}
						</div>
					</div>
					<div styleName="metric-name-value-container">
						<div styleName="metric-name-desc-container">
							<div styleName="metric-name" className={css(myStyles.metricName)}>
								{props.getLabel(title)}
							</div>
							
						</div>
						<div styleName="metric-value" className={css(myStyles.metricValue)}>
							{props.getLabel(value)}
						</div>
					</div>
					{renderTag(changeValue, changeType, title)}
				</div>
			</div>
		);
	}

	const renderMetrics = () => {
		let skillMetric = null;
		let moraleMetric = null;
		let csMetric = null;

		latestEvent.userMetrics.map(userMetric => {
			const individualMetric = props.metrics.metricsList.filter(
				metric => metric.metricsId === userMetric.metricsId
			)[0];

			if (individualMetric.key === metricsKeyMapping['SKILL']) {
				skillMetric = renderGameMetrics(
					'label_skill_event',
					`${userMetric.value}%`,
					`${userMetric.diff >= 0 ? userMetric.diff : -userMetric.diff}`,
					userMetric.diff > 0 ? 'positive' : userMetric.diff < 0 ? 'negative' : 'neutral'
				);
			}

			if (individualMetric.key === metricsKeyMapping['MORALE']) {
				moraleMetric = renderGameMetrics(
					'label_morale_event',
					`${userMetric.value}%`,
					`${userMetric.diff >= 0 ? userMetric.diff : -userMetric.diff}`,
					userMetric.diff > 0 ? 'positive' : userMetric.diff < 0 ? 'negative' : 'neutral'
				);
			}

			if (individualMetric.key === metricsKeyMapping['CS']) {
				csMetric = renderGameMetrics(
					'label_csat_event',
					`${userMetric.value}%`,
					`${userMetric.diff >= 0 ? userMetric.diff : -userMetric.diff}`,
					userMetric.diff > 0 ? 'positive' : userMetric.diff < 0 ? 'negative' : 'neutral'
				);
			}

			return null;
		});

		return [skillMetric, moraleMetric, csMetric];
	}

	const checkAndCallEndSprint = (props) => {
        const currentSprintNumber = props.userState.currentSprintNumber;
        props.endSprint(
            {
                sprintId: currentSprintNumber
            },
            () => {
                // if (currentSprintNumber === 1) {
                //     props.setWalkthroughState('executionSprintLaunch')
                // }
                // else {
                //     updateRoute({ route: `/execution/sprint/${currentSprintNumber}/report` })
                // }
				updateRoute({ route: `/execution/sprint/${currentSprintNumber}/report` })
            }
        );
    }

    const redirectToSimReport = (props) => {
        const currentSprintNumber = props.userState.currentSprintNumber || 1;
        if (props.userState.currentSprintState == null || props.userState.isGameCompleted) {
            updateRoute({ route: `/execution/sprint/${currentSprintNumber}/report` })
        }
    }

	const closeActionResponse = () => {
		const updatedEventsToBeShownCount = eventsToBeShownCount - 1;

		if (updatedEventsToBeShownCount === 0) {
			if (props.uiState.showReplanSprintPopup) {
				props.setUiState({
					showOverlay: true,
					highlightDesktopHeader: true,
					highlightMetricsHeader: false,
					overlayComponentType: 'CENTER',
					overlayComponent: 'REPLAN_SPRINT_HELP_POPUP'
				});
			} else {
				props.setUiState({
					showOverlay: false,
					overlayComponentType: '',
					highlightDesktopHeader: false,
					highlightMetricsHeader: false,
					overlayComponent: ''
				});
			}
		}

		props.updateEventsToBeShownCount(updatedEventsToBeShownCount);
		if(updatedEventsToBeShownCount == 0){
			if (
				props.userState.currentSprintDay > 15
				&& props.userState.currentSprintState !== 4
			) {
				checkAndCallEndSprint(props);
			} 
			// else if (props.userState.currentSprintState !== props.userState.currentSprintState) {
			// 	checkSprintStateAndRedirect(props);
			// } 
			else if (props.userState.isGameCompleted) {
				redirectToSimReport(props);
			}
		}
	}

	let imageKey = 'neutral_event_response';
	let containerClass = css(myStyles.container);
	if (eventType === 'negative') {
		imageKey = 'negative_event_response';
		containerClass = css(myStyles.container, myStyles.negativeContainer);
	}
	else if (eventType === 'positive') {
		imageKey = 'positive_event_response';
		containerClass = css(myStyles.container, myStyles.positiveContainer);
	}

	return (
		<div className={containerClass} styleName="container">
			<div styleName="image-container"> 
				{/* <img alt={imageKey} src={props.getImage(imageKey)} styleName="image" /> */}
				<img
					alt={latestEvent.responseImageKey}
					src={props.getImage(latestEvent.responseImageKey)}
					styleName="image"
				/>
			</div>
			<div className={css(myStyles.responseContainer)} styleName="response-container">
				<div className={css(myStyles.title)}>
					{props.getLabel(latestEvent.name)}
				</div>
				<div className={css(myStyles.responseMessage)} styleName="response-message">
					{props.getLabel(latestEvent.description)}
				</div>
				{/* <div className={css(myStyles.personName)} styleName="person-name">
					{`- ${props.getLabel('label_cpo_name')}, ${props.getLabel('label_cpo_pos')}`}
				</div> */}
				<div styleName="metrics-container">
					{renderMetrics()}
				</div>
			</div>
			<div styleName="close-container">
				<FilledButton
					textLabel={props.getLabel("label_continue")}
					clickFunction={closeActionResponse}
					customStyle={{
						width: '50px'
					}}
				/>
			</div>
		</div>
	);
}

export default applyWrappers(Event, styles);