import React from 'react';
import styles from './sprintWalkthrough.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import WalkthroughSteps from '../../walkthroughSteps';

const SprintWalkthrough = (props) => {

	const myStyles = getSkin(props.skinGuide);
	// const globalProfiles = props.skinGuide.globalProfiles;
	// const skin = globalProfiles.palette;

	let containerStyle = 'container';

	// if (props.isDisabled) {
	// 	containerStyle += ' disabled-container';
	// }

	const getClassName = () => {
		if(props.sprintNumber<props.currentSprintNumber) return css(myStyles.beforeContainer)
		if(props.sprintNumber===props.currentSprintNumber) return css(myStyles.currentContainer)
			return css(myStyles.afterContainer)
	}

	return (
		<div className={getClassName()} styleName={containerStyle}>
			
			<div className={css(myStyles.title)} styleName="title">
				{props.getLabel(props.title).replace(
					'%{SPRINT_NUMBER}',
					`0${props.sprintNumber}`
				)}
			</div>
			<div styleName="steps-container">
				<WalkthroughSteps steps={props.steps} planning={props.planning}/>
			</div>
		</div>
	);
}

export default applyWrappers(SprintWalkthrough, styles);