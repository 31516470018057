import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins'

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		container: {
			background: skin.white,
			boxShadow: `0px 2px 10px rgba(0, 0, 0, 0.3)`,
			borderRadius: '4px'
		},
		header: {
			background: 'linear-gradient(90deg, #972082 0%, #4B1C40 100%)',
			borderTopLeftRadius: '4px',
			borderTopRightRadius: '4px'
		},
		title: {
			fontFamily: skins.fontFamily.timerFamily,
			fontWeight: 700,
			lineHeight: '1.375em',
			color: skin.white,
			fontSize: '1em'
		},
		description: {
			ontFamily: skins.fontFamily.bodyFamily,
			fontSize: '1em',
			fontWeight: 350,
			lineHeight: '1.375em',
			color: skin.white
		},
		chooseOptions: {
			...myTypography.body1,
			fontWeight: 350,
			fontSize: '0.875em',
			lineHeight: '1.25em',
			color: '#333333'
		},
		popoverArrow: {
			borderLeft: `7px solid #B52560`,
			borderTop: `7px solid transparent`,
			borderBottom: `7px solid transparent`
		},
		errorMessage: {
			fontFamily: skins.fontFamily.bodyFamily,
			fontSize: '0.75em',
			fontWeight: '600',
			lineHeight: 'normal',
			color: '#D11021',
			background: '#D1102133',
			borderRadius: '8px'
		}
	});
}

export default getSkin;