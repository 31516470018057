import React from 'react';
import styles from './newActions.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const ActionImage = (props) => {
    const color = props.color;
    const secondaryColor = props.secondaryColor || '#FF5B7F';
    let svgId = props.svgId || 'teamLunch';
    svgId += Math.random();

    let containerStyles = {  };
    if (props.containerStyles) {
        containerStyles = {
            ...containerStyles,
            ...props.containerStyles
        }
    }


    return (
        <div style={containerStyles} styleName="main-component">
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2783_30239)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.29325 5.29343C7.48075 5.10616 7.73492 5.00098 7.99992 5.00098C8.26492 5.00098 8.51909 5.10616 8.70659 5.29343L12.4786 9.0641C12.5714 9.15698 12.6451 9.26725 12.6953 9.3886C12.7455 9.50995 12.7714 9.64 12.7713 9.77133C12.7713 9.90267 12.7454 10.0327 12.6951 10.154C12.6448 10.2754 12.5711 10.3856 12.4783 10.4784C12.3854 10.5713 12.2751 10.6449 12.1538 10.6951C12.0324 10.7454 11.9024 10.7712 11.771 10.7712C11.6397 10.7712 11.5096 10.7453 11.3883 10.695C11.267 10.6447 11.1568 10.571 11.0639 10.4781L7.99992 7.41476L4.93592 10.4788C4.84372 10.5743 4.73341 10.6505 4.61143 10.703C4.48945 10.7555 4.35824 10.7831 4.22547 10.7843C4.09269 10.7856 3.961 10.7603 3.83808 10.7101C3.71516 10.6599 3.60347 10.5857 3.50954 10.4918C3.4156 10.398 3.34129 10.2864 3.29095 10.1635C3.24062 10.0406 3.21525 9.90895 3.21634 9.77617C3.21743 9.64339 3.24496 9.51216 3.29731 9.39013C3.34966 9.2681 3.42579 9.15772 3.52125 9.06543L7.29325 5.29343Z" fill={color} />
                </g>
                <defs>
                    <clipPath id="clip0_2783_30239">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
}



export default applyWrappers(ActionImage, styles);
