import IMG_LOGO from 'images/default/logo.svg';
import IMG_KNOLSKAPE_LOGO from 'images/default/logo_knolskape.png';
import IMG_AGILE from 'images/default/sim logo.png'
import IMG_AGILE_1 from 'images/default/Logo.png'
import IMG_INTRO_CASE_OBJECTIVE from 'images/default/intro/caseObj.png'
import INTRO_SIDE_1 from 'images/default/intro/side1.png';
import INTRO_SIDE_2 from 'images/default/intro/side2.png';
import INTRO_INFO from 'images/default/intro/info.png'
import MUST_PRIORITY from 'images/default/intro/Must.png'
import SHOULD_PRIORITY from 'images/default/intro/Should.png'
import COULD_PRIORITY from 'images/default/intro/Could.png'
import IMP_MAP from 'images/default/map.png'
import IMG_LEADER1 from 'images/default/Brinda.png'
import IMG_LEADER2 from 'images/default/Sukra.png'
import ZOOM_MAP from 'images/default/zoom-map.png';
import IMG_AGILE_NEW from 'images/default/intro/agile_new.png'
import IMG_AGILE_BIG from 'images/default/intro/agile_big.png'
import IMG_RE_CAL from 'images/default/others/calendar-line.png'
import IMG_RE_BAG from 'images/default/others/moneybag.png'
import SHOULD_PRIORITY_REPORT from 'images/default/others/report-should.png'
import MUST_PRIORITY_REPORT from 'images/default/others/report-must.png'
import IMG_LOADING from 'images/default/Loading.png'


import IMG_CEO from 'images/default/intro/ceo.png';
import IMG_COMPANY from 'images/default/intro/company.png';
import IMG_TEAM from 'images/default/intro/team.png';
import IMG_DEFAULT_PRD from 'images/default/prds/default_prd.png';
import IMAGE_PRD_1 from 'images/default/prds/image_prd_1.png';
import IMAGE_PRD_2 from 'images/default/prds/image_prd_2.png';
import IMAGE_PRD_3 from 'images/default/prds/image_prd_3.png';
import IMG_DEFAULT_FEATURE from 'images/default/features/default_feature.png';
import SIM_BACKGROUND from 'images/default/sim_background.jpg';
import INTRO_BACKGROUND from 'images/default/intro_background.jpg'
import SIM_BACKGROUND_BLUR from 'images/default/sim_background_blur.png';



import IMG_SETTING_PRIORITY from 'images/default/others/setting_priority.png';
import IMG_TASKS_COMPLETED from 'images/default/others/tasks_completed.png';
import IMG_MVP_ACHIEVED from 'images/default/others/mvp_achieved.png';
import IMG_TIME_RAN_OUT from 'images/default/others/time_ran_out.png';
import IMG_DAYS_RAN_OUT from 'images/default/others/time_ran_out.png';
import IMG_TICK from 'images/default/others/tick.png'
import IMG_FILLED_CIRCLE from 'images/default/others/filled-circle.png'
import IMG_EMPTY_CIRCLE from 'images/default/others/empty-circle.png'
import IMG_ROTATE from 'images/default/rotate.png';
import IMG_SQ_TIC from 'images/default/others/Group.png'

import IMG_FEATURE_RP from 'images/default/features/river_pool.png';
import IMG_FEATURE_SB from 'images/default/features/swim_up_bar.png';
import IMG_FEATURE_SS from 'images/default/features/safety_station.png';
import IMG_FEATURE_LA from 'images/default/features/lounging_area.png';
import IMG_FEATURE_OF from 'images/default/features/facilities.png';
//actions
// import default_action from 'images/default/actions/default_action.png';
// import team_lunch from 'images/default/actions/team_lunch.png';
// import standup from 'images/default/actions/standup.png';
// import market_pulse_survey from 'images/default/actions/market_pulse_survey.png';
// import rebuild_feature from 'images/default/actions/rebuild_feature.png';
// import replan_sprint from 'images/default/actions/replan_sprint.png';
// import retrospect from 'images/default/actions/retrospect.png';
// import team_motivation_survey from 'images/default/actions/team_motivation_survey.png';
// import update_prd from 'images/default/actions/update_prd.png';
// import user_feedback from 'images/default/actions/user_feedback.png';

//action responses
import default_response from 'images/default/actions/actionResponses/default_response.png';
import align_team_response from 'images/default/actions/actionResponses/align_team_response.png';
import feedback_interview_response from 'images/default/actions/actionResponses/feedback_interview_response.png';
import feedback_survey_response from 'images/default/actions/actionResponses/feedback_survey_response.png';
import market_pulse_survey_response from 'images/default/actions/actionResponses/market_pulse_survey_response.png';
import meet_team_response from 'images/default/actions/actionResponses/meet_team_response.png';
import replace_sprint_response from 'images/default/actions/actionResponses/replace_sprint_response.png';
// import retrospect_response from 'images/default/actions/actionResponses/retrospect_response.png';
import team_huddle_response from 'images/default/actions/actionResponses/team_huddle_response.png';
import team_lunch_response from 'images/default/actions/actionResponses/team_lunch_response.png';
import team_morale_survey_response from 'images/default/actions/actionResponses/team_morale_survey_response.png';
import train_team_response from 'images/default/actions/actionResponses/train_team_response.png';
import update_prd_response from 'images/default/actions/actionResponses/update_prd_response.png';

//new action responses
import inspire_response from 'images/default/actions/actionResponses/newActionResponses/inspire.png'
import coach_response from 'images/default/actions/actionResponses/newActionResponses/coach.png'
import consult_response from 'images/default/actions/actionResponses/newActionResponses/consult.png'
import demonstrate_response from 'images/default/actions/actionResponses/newActionResponses/demonstrate.png'
import facilitate_response from 'images/default/actions/actionResponses/newActionResponses/facilitate.png'
import lead_response from 'images/default/actions/actionResponses/newActionResponses/lead.png'
import negotiate_response from 'images/default/actions/actionResponses/newActionResponses/negotiate.png'
import prioritize_response from 'images/default/actions/actionResponses/newActionResponses/prioritize.png'
import reschedule_response from 'images/default/actions/actionResponses/newActionResponses/reschedule.png'
import retrospect_response from 'images/default/actions/actionResponses/newActionResponses/retrospect.png'
import review_response from 'images/default/actions/actionResponses/newActionResponses/review.png'
import stakeholders_response from 'images/default/actions/actionResponses/newActionResponses/stakeholders.png'


//blocker responses
import BLOCKER_MUST from 'images/default/blockerActions/blocker_must.png';
import BLOCKER_SOL_ARCH from 'images/default/blockerActions/blocker_sol_arch.png';
import BLOCKER_CHALLENGE from 'images/default/blockerActions/blocker_challenge.png';
import BLOCKER_VISIONARY from 'images/default/blockerActions/blocker_visionary.png';
import BLOCKER_LATER from 'images/default/blockerActions/blocker_later.png';
import BLOCKER_SHOULD from 'images/default/blockerActions/blocker_should.png';
import BLOCKER_REPOSITION from 'images/default/blockerActions/blocker_reposition.png';
import BLOCKER_NOTHING from 'images/default/blockerActions/blocker_nothing.png';
import BLOCKER_BRAIN from 'images/default/blockerActions/blocker_brain.png';
import BLOCKER_VALIDATE from 'images/default/blockerActions/blocker_validate.png';
import BLOCKER_ARCH from 'images/default/blockerActions/blocker_arch.png';
import BLOCKER_SPECS from 'images/default/blockerActions/blocker_specs.png';
import BLOCKER_DETAIL from 'images/default/blockerActions/blocker_detail.png';
import BLOCKER_DISCUSS from 'images/default/blockerActions/blocker_discuss.png';
import BLOCKER_ASSIGN from 'images/default/blockerActions/blocker_assign.png';
import BLOCKER_DESIGN from 'images/default/blockerActions/blocker_design.png';
import BLOCKER_OWN from 'images/default/blockerActions/blocker_own.png';


//event responses
import negative_event_response from 'images/default/events/negative_event_response.png';
import positive_event_response from 'images/default/events/positive_event_response.png';
import neutral_event_response from 'images/default/events/neutral_event_response.png';
import IMG_LEADERBOARD_BACKGROUND from 'images/default/leaderboard_background.png';

// events
import TIGHTER_ENVIRONMENTAL from 'images/default/events/tighter_environmental.png';
import EPR_RESORT from 'images/default/events/epr_resort.png';
import MEETING_MINDS from 'images/default/events/meeting_minds.png';
import WANDERER_RETURNS from 'images/default/events/wanderer_returns.png';
import DIGGER from 'images/default/events/digger.png';
import FLU_OVER_CUCKOO_NEST from 'images/default/events/flu_over_cuckoo_nest.png';
import GREENWASHING_THREAT from 'images/default/events/greenwashing_threat.png';
import SECRETS_SHARED from 'images/default/events/secrets_shared.png';
import FESTIVAL_BOOST from 'images/default/events/festival_boost.png';
import SUPPLY_CHAIN_PROBLEM from 'images/default/events/supply_chain_problem.png';
import STORMY_WEATHER from 'images/default/events/stormy_weather.png';
import MORE_PROTECTION from 'images/default/events/more_protection.png';
import ITS_CATCHING from 'images/default/events/its_catching.png';
import IN_THE_CAN from 'images/default/events/in_the_can.png';
import BRIGHT_FUTURE from 'images/default/events/bright_future.png';

const images = {
	IMG_LOGO,
	IMG_KNOLSKAPE_LOGO,
	IMG_AGILE,
	IMG_INTRO_CASE_OBJECTIVE,
	IMG_CEO,
	IMG_COMPANY,
	IMG_TEAM,
	IMG_DEFAULT_PRD,
	IMAGE_PRD_1,
	IMAGE_PRD_2,
	IMAGE_PRD_3,
	IMG_DEFAULT_FEATURE,
	SIM_BACKGROUND,
	INTRO_BACKGROUND,
	INTRO_SIDE_1,
	INTRO_SIDE_2,
	IMG_AGILE_1,
	SIM_BACKGROUND_BLUR,
	INTRO_INFO,
	MUST_PRIORITY,
	SHOULD_PRIORITY,
	COULD_PRIORITY,
	IMG_TICK,
	IMG_FILLED_CIRCLE,
	IMG_EMPTY_CIRCLE,
	IMP_MAP,
	IMG_LEADER1,
	IMG_LEADER2,
	ZOOM_MAP,
	IMG_SQ_TIC,
	IMG_FEATURE_RP,
	IMG_FEATURE_LA,
	IMG_FEATURE_SB,
	IMG_FEATURE_SS,
	IMG_FEATURE_OF,
	IMG_AGILE_NEW,
	IMG_AGILE_BIG,
	IMG_RE_BAG,
	IMG_RE_CAL,
	SHOULD_PRIORITY_REPORT,
	MUST_PRIORITY_REPORT,
	IMG_LOADING,
	// default_action,
	// team_lunch,
	// standup,
	// market_pulse_survey,
	// rebuild_feature,
	// replan_sprint,
	// retrospect,
	// team_motivation_survey,
	// update_prd,
	// user_feedback,

	default_response,
	align_team_response,
	feedback_interview_response,
	feedback_survey_response,
	market_pulse_survey_response,
	meet_team_response,
	replace_sprint_response,
	team_huddle_response,
	team_lunch_response,
	team_morale_survey_response,
	train_team_response,
	update_prd_response,

	BLOCKER_MUST,
	BLOCKER_SOL_ARCH,
	BLOCKER_CHALLENGE,
	BLOCKER_VISIONARY,
	BLOCKER_LATER,
	BLOCKER_SHOULD,
	BLOCKER_REPOSITION,
	BLOCKER_NOTHING,
	BLOCKER_BRAIN,
	BLOCKER_VALIDATE,
	BLOCKER_ARCH,
	BLOCKER_SPECS,
	BLOCKER_DETAIL,
	BLOCKER_DISCUSS,
	BLOCKER_ASSIGN,
	BLOCKER_DESIGN,
	BLOCKER_OWN,

	inspire_response,
	coach_response,
	consult_response,
	demonstrate_response,
	facilitate_response,
	lead_response,
	negotiate_response,
	prioritize_response,
	reschedule_response,
	retrospect_response,
	review_response,
	stakeholders_response,

	positive_event_response,
	negative_event_response,
	neutral_event_response,

	IMG_SETTING_PRIORITY,
	IMG_TASKS_COMPLETED,
	IMG_MVP_ACHIEVED,
	IMG_TIME_RAN_OUT,
	IMG_DAYS_RAN_OUT,
	IMG_ROTATE,
	IMG_LEADERBOARD_BACKGROUND,

	TIGHTER_ENVIRONMENTAL,
	EPR_RESORT,
	MEETING_MINDS,
	WANDERER_RETURNS,
	DIGGER,
	FLU_OVER_CUCKOO_NEST,
	GREENWASHING_THREAT,
	SECRETS_SHARED,
	FESTIVAL_BOOST,
	SUPPLY_CHAIN_PROBLEM,
	STORMY_WEATHER,
	MORE_PROTECTION,
	ITS_CATCHING,
	IN_THE_CAN,
	BRIGHT_FUTURE,
};

export default images;
