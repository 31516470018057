import React from 'react';
import styles from './simProgress.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import HeaderSeparation from 'components/header/headerSeparation';

const SimProgress = (props) => {
	const currentDay = props.userState.currentSprintDay;
	const totalDays = props.userState.totalDays;
	const sprintTotalDays = props.userState.totalDaysInSprint;
	const totalSprints = props.userState.totalDays/sprintTotalDays;
	const sprintSections = 3;
	const currentSprint = props.userState.currentSprintNumber;


	const getSprintSection = (day, totalDays, sections) => {   // will return value from 1 to 15
		const week = (totalDays/(sections*totalSprints));
		//console.log(props)
		return 	Math.ceil(day/week);
	};

	let currentDaySection = getSprintSection((currentSprint-1)*sprintTotalDays+currentDay, totalDays, sprintSections)
	if(props.userState.isGameCompleted) currentDaySection = 16;
	currentDaySection = Math.max(currentDaySection,1);

	const renderProgress = (currentDay, totalDays, myStyles) => {
		const dayDivs = [];
		const sprintDivs = [];
		// console.log(currentDaySection)
		for(let sprint=1; sprint <= totalSprints; sprint++){
			const sprintDiv = [];
			for(let sprintSection = 1; sprintSection <= sprintSections; sprintSection++){
				//const daySection = getDaySection((sprint-1)*sprintTotalDays+day, sprintTotalDays, sprintSections)
				const daySection = (sprint-1)*sprintSections + sprintSection;
				if(daySection < currentDaySection){
					sprintDiv.push(
					<div key={`progress-${sprintSection}`} className={css(myStyles.pastDayContainer)} styleName="sprint-section-container"></div>
					);	
				}
				else if(daySection == currentDaySection){
					sprintDiv.push(
					<div key={`progress-${sprintSection}`} className={css(myStyles.currentDayContainer)} styleName="sprint-section-container"></div>
					);
				}
				else{
					sprintDiv.push(
					<div key={`progress-${sprintSection}`} className={css(myStyles.futureDayContainer)} styleName="sprint-section-container"></div>
					);	
				}
			}
			sprintDivs.push(
				<div key={`sprint-${sprint}`} className={css(myStyles.sprintContainer)} styleName="sprint-container">
				  {sprintDiv}
				</div>
			  );
		}
		// for (let day = 1; day <= totalDays; day++) {
		// 	let dayClassName = "middle-days-container"
		// 	if (day === 1 || day === totalDays) {
		// 		dayClassName = "end-days-container"
		// 	}

		// 	if (day === currentDay) {
		// 		dayClassName = "current-days-container"
		// 	}

		// 	if (day < currentDay) {
		// 		dayDivs.push(
		// 			<div key={`progress-${day}`} className={css(myStyles.completedDay)} styleName={dayClassName}></div>
		// 		);
		// 	} else if (day === currentDay) {
		// 		dayDivs.push(
		// 			<div key={`progress-${day}`} className={css(myStyles.currentDay)} styleName={dayClassName}></div>
		// 		);
		// 	} else {
		// 		dayDivs.push(
		// 			<div key={`progress-${day}`} className={css(myStyles.notCompletedDay)} styleName={dayClassName}></div>
		// 		);
		// 	}
		// }

		return sprintDivs;
	}

	const renderDayTag = (currentDay, myStyles) => {

		let leftValue = (currentDay * 3) - 23;
		if (leftValue < 2) {
			leftValue = 2;
		} else if (leftValue > ((totalDays * 3) - 45)) {
			leftValue = (totalDays * 3) - 47;
		}

		return (
			<div
				styleName="day-tag-container"
				className={css(myStyles.dayTag)}
				style={{
					top: '-12px',
					left: `${leftValue}px`
				}}
			>
				{props.getLabel('label_day_number',"", {DAY_NUMBER:currentDay > totalDays ? totalDays : currentDay}) }
			</div>
		);
	}

	let weeksToDeadline = totalSprints*sprintSections - currentDaySection + 1;

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="sim-progress-container">
			{/* <HeaderSeparation/> */}
			<div styleName="sim-progress-content" className={`${css(myStyles.progressContainer)}`}>
				<div styleName="sim-progress-child" className="sim-progress"></div>
				<div styleName="progress-value" className={css(myStyles.progressValue)}>
					{`${weeksToDeadline}/${totalSprints*sprintSections}`}
				</div>
				<div styleName="deadline-bar-container">
					<div styleName="deadline-container" className={css(myStyles.deadlineContainer)}>
						{props.getLabel('label_days_remaining')}
					</div>
					<div styleName="progress-container">
						{renderProgress(currentDay, totalDays, myStyles)}
						{/* {renderDayTag(currentDay, myStyles)} */}
					</div>
				</div>
			</div>
			{/* <HeaderSeparation/> */}
		</div>
	);
}

export default applyWrappers(SimProgress, styles);