import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		container: {
			border: `1px solid ${hexToRgbA(skin.grayColor1, 0.3)}`
		},
		radioOutline: {
			border: `1px solid ${hexToRgbA(skin.grayColor1, 0.3)}`
		},
		radioCircle: {
			background: skin.white
		},
		name: {
			fontFamily: skins.fontFamily.bodyFamily,
			fontWeight: 600,
			fontSize: '0.875em',
			color: '#333333'
		},
		optionEffortCostTitle: {
			fontFamily: skins.fontFamily.bodyFamily,
			fontWeight: 350,
			fontSize: '0.875em',
			color: skin.black,
			// borderRight: `solid 1px ${hexToRgbA(skin.grayColor1, 0.5)}`
		},
		optionEffortCostValue: {
			fontFamily: skins.fontFamily.bodyFamily,
			fontWeight: 600,
			fontSize: '0.875em',
			color: `#333333`,
		},
		containerSelected: {
			border: `1px solid ${skin.orange}`
		},
		radioOutlineSelected: {
			border: `1px solid ${skin.orange}`
		},
		radioCircleSelected: {
			background: skin.orange
		},
		nameSelected: {
			color: skin.orange
		}
	});
}

export default getSkin;