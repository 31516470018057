import React from 'react';
import styles from './bundleLoader.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';
import Loader from 'svgComponents/loader/Loader';

const BundleLoader = (props) => {
	return (
		<div styleName="container">
			<Loader />
		</div>
	);
}

export default applyWrappers(BundleLoader, styles);