import React from 'react';
import styles from './newActions.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const ActionImage = (props) => {
    const color = props.color;
    const secondaryColor = props.secondaryColor || '#FF5B7F';
    let svgId = props.svgId || 'teamLunch';
    svgId += Math.random();

    let containerStyles = { };
    if (props.containerStyles) {
        containerStyles = {
            ...containerStyles,
            ...props.containerStyles
        }
    }


    return (
        <div style={containerStyles} styleName="main-component">
            <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2783_30301)">
                    <path d="M0.597656 15.6986C0.604866 14.9872 0.79243 14.2893 1.1428 13.6701C1.5114 13.0258 2.04262 12.4896 2.68339 12.115C3.32415 11.7403 4.052 11.5404 4.79423 11.5352C6.29137 11.5386 7.70851 12.3661 8.44566 13.6701C8.79584 14.2889 8.9834 14.9865 8.9908 15.6975M11.2994 1.87464V0.9375M14.4982 3.0255L15.2651 2.48721M8.09823 3.0255L7.33023 2.48721M4.79308 9.54436C5.07631 9.55093 5.35799 9.50081 5.62157 9.39697C5.88515 9.29312 6.12532 9.13763 6.32795 8.93965C6.53058 8.74166 6.6916 8.50516 6.80153 8.24406C6.91146 7.98296 6.96808 7.70251 6.96808 7.41921C6.96808 7.13591 6.91146 6.85547 6.80153 6.59437C6.6916 6.33326 6.53058 6.09677 6.32795 5.89878C6.12532 5.70079 5.88515 5.54531 5.62157 5.44146C5.35799 5.33761 5.07631 5.2875 4.79308 5.29407C4.23799 5.30695 3.70997 5.5365 3.32194 5.93363C2.9339 6.33077 2.71666 6.86397 2.71666 7.41921C2.71666 7.97445 2.9339 8.50766 3.32194 8.90479C3.70997 9.30193 4.23799 9.53148 4.79308 9.54436ZM13.6228 6.07464C13.6209 5.76535 13.5578 5.45949 13.4374 5.17461C13.3169 4.88974 13.1414 4.63147 12.9208 4.41462C12.7002 4.19778 12.439 4.02664 12.1521 3.91103C11.8653 3.79542 11.5584 3.73761 11.2491 3.74093C10.9399 3.75052 10.6357 3.82108 10.3539 3.94857C10.0721 4.07606 9.81821 4.25796 9.60689 4.48383C9.39556 4.7097 9.23094 4.97509 9.12247 5.26476C9.014 5.55443 8.96381 5.86267 8.9748 6.17179C8.99722 6.58521 9.12791 6.98548 9.35376 7.33249C9.57962 7.6795 9.89271 7.96105 10.2617 8.14893V9.29179C10.2637 9.359 10.2918 9.42278 10.34 9.46968C10.3882 9.51659 10.4527 9.54295 10.5199 9.54321H12.0754C12.1428 9.54325 12.2076 9.51701 12.256 9.47008C12.3045 9.42314 12.3327 9.35919 12.3348 9.29179V8.12836C12.715 7.9342 13.0355 7.64079 13.2625 7.27922C13.4895 6.91766 13.6132 6.50143 13.6228 6.07464Z" stroke={color} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_2783_30301">
                        <rect width="16" height="16" fill="white" transform="translate(0 0.294189)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
}



export default applyWrappers(ActionImage, styles);
