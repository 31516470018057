import React from 'react';
import styles from './loader.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const Loader = (props) => {
  return (
		<div styleName="loader-container">
			<div styleName="svg-container">
				<div styleName="svg-wrapper">
					<img
						width="100%"
						height="100%"
						src={props.getImage("IMG_LOADING")}
						alt=""
					/>
				</div>
			</div>
		</div>
  );
}



export default applyWrappers(Loader, styles);
