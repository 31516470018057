import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins'

const getSkin = (theme) => {
	const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

	return StyleSheet.create({
		leftMetricsContainer: {
			// borderRight: `solid 1px ${hexToRgbA(skin.grayColor1, 0.1)}`
		},
		leftMetricContainer: {
			// background: `#F5F9F9`,
			// border: `1px solid ${skin.primaryColor}`,
			// boxShadow: `0px 0px 2px rgba(0, 0, 0, 0.25)`,
		},
		metricTitle: {
			...myTypography.body1,
			fontWeight: 600,
			fontSize: '0.75em',
			lineHeight: 'normal',
			color: hexToRgbA(skin.primaryColor, 0.8)
		},
		metricChange: {
			...myTypography.body1,
			fontWeight: 700,
			fontSize: '0.75em',
			lineHeight: 'normal',
		},
		greenTag: {
			background: `#E7FBEA`,
			color: '#0B7F3D'
		},
		redTag: {
			background: `#FFDEE6`,
			color: '#D11021'
		},
		mvpProgressBarContainer: {
			background: hexToRgbA(skin.primaryColor, 0.1)
		},
		mvpProgressBar: {
			background: `#36B496`
		},
		bottomMetricsWrapper: {
			background: skin.white,
			// boxShadow: `0px 0px 2px rgba(0, 0, 0, 0.25)`
		},
		gameMetricContainer: {
			// borderRight: `solid 1px ${hexToRgbA(skin.grayColor1, 0.1)}`
		},
		gameMetricContainerRightBorder: {
			borderRight: `solid 1px ${hexToRgbA(skin.grayColor1, 0.1)}`
		},
		gameMetricsContainer: {
			// boxShadow: `0px 0px 2px rgba(0, 0, 0, 0.25)`,
			// border: `1px solid ${skin.grayColor5}`,
			// borderRadius: '18px'
		},
		rightMetricLeftBorder: {
			borderLeft: `1px solid ${skin.grayColor5}`
		},
		metricContainer: {
			backgroundColor: skin.white,
			border: `1px solid ${skin.black}`,
			borderRadius: '4px'
			// boxShadow: `0 1px 1px 2px ${hexToRgbA(skin.white, 0.1)}`
		},
		metricName: {
			...myTypography.body2,
			fontFamily: skins.fontFamily.titleFamily,
			fontWeight: 500,
			fontSize: '0.625em',
			lineHeight: 1,
			color: skin.black
		},
		metricNameRight: {
			...myTypography.body2,
			fontWeight: 600,
			fontSize: '0.75em',
			lineHeight: '1em',
			color: skin.black
		},
		metricValue: {
			...myTypography.body1,
			fontSize: '1em',
			fontWeight: 600,
			color: skin.black
		},
		metricValueRight: {
			...myTypography.body1,
			fontFamily: skins.fontFamily.timerFamily,
			fontSize: '1em',
			fontWeight: 700,
			color: skin.black
		}
	});
}

export default getSkin;