import React from 'react';
import styles from './newActions.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const ActionImage = (props) => {
    const color = props.color;
    const secondaryColor = props.secondaryColor || '#FF5B7F';
    let svgId = props.svgId || 'teamLunch';
    svgId += Math.random();

    let containerStyles = {};
    if (props.containerStyles) {
        containerStyles = {
            ...containerStyles,
            ...props.containerStyles
        }
    }


    return (
        <div style={containerStyles} styleName="main-component">
            <svg  viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.04775 2.17645C5.57457 2.08092 2.72916 4.87175 2.72916 8.31763H1.50775C1.20069 8.31763 1.05057 8.6861 1.26893 8.89763L3.17269 10.8082C3.30916 10.9447 3.52069 10.9447 3.65716 10.8082L5.56093 8.89763C5.60808 8.84953 5.63994 8.78854 5.65249 8.72236C5.66504 8.65618 5.65772 8.58777 5.63145 8.52574C5.60518 8.46372 5.56113 8.41086 5.50487 8.37382C5.44861 8.33679 5.38264 8.31723 5.31528 8.31763H4.09387C4.09387 5.65645 6.26375 3.50704 8.93857 3.54116C11.4769 3.57528 13.6127 5.71104 13.6468 8.24939C13.6809 10.9174 11.5315 13.0941 8.87034 13.0941C7.77175 13.0941 6.75505 12.7188 5.94987 12.0842C5.81917 11.9813 5.65519 11.9299 5.48912 11.94C5.32305 11.9501 5.16647 12.0208 5.04916 12.1388C4.76257 12.4254 4.78305 12.9099 5.10375 13.1555C6.17581 14.0033 7.50357 14.4627 8.87034 14.4588C12.3162 14.4588 15.107 11.6134 15.0115 8.14022C14.9228 4.93998 12.248 2.26516 9.04775 2.17645ZM8.69975 5.58822C8.41999 5.58822 8.18799 5.82022 8.18799 6.09998V8.61104C8.18799 8.84987 8.31763 9.07504 8.52234 9.19787L10.6513 10.4602C10.8969 10.6035 11.2108 10.5216 11.3541 10.2828C11.4974 10.0372 11.4155 9.72328 11.1767 9.57998L9.21152 8.41316V6.09316C9.21152 5.82022 8.97952 5.58822 8.69975 5.58822Z" fill={color} />
            </svg>
        </div>
    );
}



export default applyWrappers(ActionImage, styles);
