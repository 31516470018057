import React, { useState } from 'react';
import styles from './leaderboardPopup.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import { getUserMetricsForMetricsKey } from 'util/utilFunctions';
import HollowButton from 'commonComponents/buttons/hollowButton';
import analyticsUtil from 'util/segmentUtil';
import ClosedButton from 'commonComponents/buttons/closedButton';

const LeaderboardPopup = (props) => {

	const [refreshing, setRefreshing] = useState(false)

	const closePopup = () => {
		//Segment Integratation
		analyticsUtil.track('POPUP_CLOSED', {
			metrics: props.userDetails,
			popup: 'LEADERBOARD'
		});
		props.setUiState({
			showOverlay: false,
			showOverlayOverWalkthrough: false,
			overlayComponentType: '',
			highlightDesktopHeader: false,
			highlightMetricsHeader: false,
			overlayComponent: ''
		});
	}

	let refreshButtonText = refreshing ? props.getLabel("label_refreshing"): props.getLabel("label_refresh");
	let disableButton = true;
	// if (props.leaderboard.isLeaderboardFetching) {
	// 	refreshButtonText = props.getLabel('label_refreshing');
	// 	disableButton = false;
	// }

	const myStyles = getSkin(props.skinGuide);

	const renderTableBody = () => {
		return props.leaderboard.usersList.map(user => {
			const daysRemaining = props.userState.totalDays - user.currentDay < 0 ? 0 : props.userState.totalDays - user.currentDay;
			const mvpUserMetrics = getUserMetricsForMetricsKey(props.metrics.metricsList, user.metrics, 'MVP');
			// eslint-disable-next-line
			const mvpValue = mvpUserMetrics && mvpUserMetrics.value || 0;

			let rowClass = '';
			let cellClass = css(myStyles.value);
			if (user.isSelf) {
				rowClass = css(myStyles.currentUser);
				cellClass = css(myStyles.currentUserValue);
			}

			return (
				<div key={user.uliId}>
					<div className={rowClass} styleName="table-row">
						<div className={cellClass} styleName="position position-body">{user.rank}</div>
							<div className={cellClass} styleName="username">
								{user.isSelf ? props.getLabel("label_you") : user.userName}
							</div>
						<div className={cellClass} styleName="days-remaining">{`${daysRemaining} ${props.getLabel('label_days')}`}</div>
						<div className={cellClass} styleName="mvp mvp-body">{`${(mvpValue.toFixed(1) * 10) / 10}%`}</div>
					</div>
					<div className={css(myStyles.seperationLine)} styleName="seperation-line"></div>
				</div>
			);
		});
	}

	return (
		<div styleName="leaderboard-popup-container" className={css(myStyles.leaderboardPopupContainer)}>
			<div styleName="header-container">
				<div className={css(myStyles.headerBackground)} styleName="header-background-container"></div>
				<div className={css(myStyles.title)} styleName="title">
					{props.getLabel('label_leaderboard')}
				</div>

				<div className={css(myStyles.refreshContainer)} styleName="refresh-container">
					<HollowButton
						textLabel={refreshButtonText}
						clickFunction={() => {
							setRefreshing(true);
							props.fetchLeaderboard()
							setTimeout(()=>{
								setRefreshing(false)
							}, 1000)
						}}
						disableButton={false}
						buttonStyle={{
							background: `linear-gradient(90deg, rgba(151, 32, 130, 0.5) 0%, rgba(75, 28, 64, 0.5) 100%)`,
							textTransform: 'uppercase',
							borderRadius: '4px',
							fontSize: '1em',
							height: "4.167vh",
							width: "10vw",
						}}

					/>
				</div>

				<div styleName="close-icon" onClick={closePopup}>
					<ClosedButton
						textLabel={props.getLabel('label_close_button')}
						onClick={closePopup}
						
					/>
				</div>
			</div>

			<div styleName="content">
				<div styleName="table-container">
					<div styleName="table-header-container">
						<div className={css(myStyles.headerTitle)} styleName="position position-header">{props.getLabel('label_position')}</div>
						<div className={css(myStyles.headerTitle)} styleName="username-heading">{props.getLabel('label_username')}</div>
						<div className={css(myStyles.headerTitle)} styleName="days-remaining">{props.getLabel('label_remaining_days')}</div>
						<div className={css(myStyles.headerTitle)} styleName="mvp">{props.getLabel('label_mvp_alignment')}</div>
					</div>
					<div styleName="table-body-container">
						{renderTableBody()}
					</div>
				</div>
			</div>
		</div>
	);
}

export default applyWrappers(LeaderboardPopup, styles);