import React from 'react';
import styles from './newActions.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const ActionImage = (props) => {
    const color = props.color;
    const secondaryColor = props.secondaryColor || '#FF5B7F';
    let svgId = props.svgId || 'teamLunch';
    svgId += Math.random();

    let containerStyles = {  };
    if (props.containerStyles) {
        containerStyles = {
            ...containerStyles,
            ...props.containerStyles
        }
    }


    return (
        <div style={containerStyles} styleName="main-component">
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 1.25C6.66498 1.25 5.35994 1.64588 4.2499 2.38758C3.13987 3.12928 2.27471 4.18349 1.76382 5.41689C1.25292 6.65029 1.11925 8.00749 1.3797 9.31686C1.64015 10.6262 2.28303 11.829 3.22703 12.773C4.17104 13.717 5.37377 14.3598 6.68314 14.6203C7.99252 14.8808 9.34971 14.7471 10.5831 14.2362C11.8165 13.7253 12.8707 12.8601 13.6124 11.7501C14.3541 10.6401 14.75 9.33502 14.75 8C14.748 6.2104 14.0362 4.49466 12.7708 3.22922C11.5053 1.96378 9.78961 1.25199 8 1.25ZM8 13.25C6.96165 13.25 5.94662 12.9421 5.08326 12.3652C4.2199 11.7883 3.547 10.9684 3.14964 10.0091C2.75228 9.04978 2.64831 7.99418 2.85088 6.97578C3.05345 5.95738 3.55347 5.02191 4.28769 4.28769C5.02192 3.55346 5.95738 3.05345 6.97578 2.85088C7.99418 2.6483 9.04978 2.75227 10.0091 3.14963C10.9684 3.54699 11.7883 4.2199 12.3652 5.08326C12.9421 5.94661 13.25 6.96165 13.25 8C13.2485 9.39193 12.6949 10.7264 11.7107 11.7107C10.7264 12.6949 9.39193 13.2485 8 13.25ZM6.75 6.75C6.75 6.94778 6.69135 7.14112 6.58147 7.30557C6.47159 7.47002 6.31541 7.59819 6.13269 7.67388C5.94996 7.74957 5.74889 7.76937 5.55491 7.73079C5.36093 7.6922 5.18275 7.59696 5.0429 7.45711C4.90304 7.31725 4.8078 7.13907 4.76922 6.94509C4.73063 6.75111 4.75044 6.55004 4.82612 6.36732C4.90181 6.18459 5.02998 6.02841 5.19443 5.91853C5.35888 5.80865 5.55222 5.75 5.75 5.75C6.01522 5.75 6.26957 5.85536 6.45711 6.04289C6.64465 6.23043 6.75 6.48478 6.75 6.75ZM11.25 6.75C11.25 6.94778 11.1914 7.14112 11.0815 7.30557C10.9716 7.47002 10.8154 7.59819 10.6327 7.67388C10.45 7.74957 10.2489 7.76937 10.0549 7.73079C9.86093 7.6922 9.68275 7.59696 9.5429 7.45711C9.40304 7.31725 9.3078 7.13907 9.26922 6.94509C9.23063 6.75111 9.25044 6.55004 9.32612 6.36732C9.40181 6.18459 9.52998 6.02841 9.69443 5.91853C9.85888 5.80865 10.0522 5.75 10.25 5.75C10.5152 5.75 10.7696 5.85536 10.9571 6.04289C11.1446 6.23043 11.25 6.48478 11.25 6.75Z" fill={color} />
            </svg>
        </div>
    );
}



export default applyWrappers(ActionImage, styles);
