import React from 'react';
import styles from './newActions.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const ActionImage= (props) => {
    const color = props.color;
    const secondaryColor = props.secondaryColor || '#FF5B7F';
    let svgId = props.svgId || 'teamLunch';
    svgId += Math.random();

    let containerStyles = { };
    if (props.containerStyles) {
        containerStyles = {
            ...containerStyles,
            ...props.containerStyles
        }
    }


    return (
        <div style={containerStyles} styleName="main-component">
           <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.615 20H7C6.46957 20 5.96086 19.7893 5.58579 19.4142C5.21071 19.0391 5 18.5304 5 18V6C5 5.46957 5.21071 4.96086 5.58579 4.58579C5.96086 4.21071 6.46957 4 7 4H15C15.5304 4 16.0391 4.21071 16.4142 4.58579C16.7893 4.96086 17 5.46957 17 6V14M14 19L16 21L20 17M9 8H13M9 12H11" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        </div>
    );
}



export default applyWrappers(ActionImage, styles);
