import React from 'react';
import styles from './incrementArrow.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const IncrementArrow = (props) => {
    const skin = props.skinGuide.globalProfiles.palette
    const color = props.color || skin.white;

    return (
        <div styleName="main-component">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.33337 6.66654L8.00004 3.99988M8.00004 3.99988L10.6667 6.66654M8.00004 3.99988V12.6664" stroke="#0B7F3D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        </div>
    );
}



export default applyWrappers(IncrementArrow, styles);
