import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins';

const getSkin = (theme) => {
	const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

	return StyleSheet.create({
		number: {
			...myTypography.body1,
			fontWeight: 'bold',
			lineHeight: 'normal',
			fontSize: '1em',
			color: skin.white,
			opacity: 0.3,
			border: `2px solid ${skin.white}`
		},
		inProgressNumber: {
			...myTypography.body1,
			fontWeight: 600,
			lineHeight: 'normal',
			fontSize: '1em',
			color: skin.white,
			border: `2px solid ${skin.white}`
		},
		title: {
			fontFamily: skins.fontFamily.bodyFamily,
			fontWeight: 600,
			lineHeight: 'normal',
			fontSize: '1em',
			color: skin.white
		},
		doneTitle: {
			...myTypography.body1,
			fontWeight: 600,
			lineHeight: 'normal',
			fontSize: '1em',
			color: skin.orange
		},
		otherTitle: {
			...myTypography.body1,
			fontWeight: 500,
			lineHeight: 'normal',
			fontSize: '1em',
			opacity: 0.3,
			color: skin.white
		},
	});
}

export default getSkin;