import React from 'react';
import styles from './planningWalkthrough.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import WalkthroughTabs from '../walkthroughTabs';
import WalkthroughSteps from '../walkthroughSteps';
import FilledButton from 'commonComponents/buttons/filledButton';

const PlanningWalkthrough = (props) => {

    const myStyles = getSkin(props.skinGuide);
    let buttonStyle = "button-container";
    if(props.steps[0].status !== 'completed'){
        buttonStyle = "button-next"
    }
    return (
        <div styleName="container">
            <div styleName="wrapper">
                <div styleName="walkthrough-background">
                    <img width="100%" height="100%" src={props.getImage('SIM_BACKGROUND')} alt="" style={{ filter: "blur(4px)" }}/>
                </div>
                <WalkthroughTabs walkthroughTabsList={props.walkthroughTabsList} />

				<div className={css(myStyles.descriptionStepsContainer)} styleName="description-steps-container">
					<div
						styleName="description"
						className={css(myStyles.description)}
						dangerouslySetInnerHTML={{ __html: props.getLabel(props.descriptionLabel) }}
					></div>

                    <div className={css(myStyles.seperationLine)} styleName="seperation-line2">
			        </div>

					<div styleName="steps-container">
						<WalkthroughSteps steps={props.steps} planning={props.planning}/>
					</div>
				</div>
				
                <div styleName={buttonStyle}>
                    <FilledButton
                        textLabel={props.getLabel(props.buttonText)}
                        clickFunction={props.buttonClickFunctionality}
                    />
                </div>
            </div>
        </div>
    );
}
export default applyWrappers(PlanningWalkthrough, styles);