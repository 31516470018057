import React from 'react';
import styles from './newActions.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const ActionImage= (props) => {
    const color = props.color;
    const secondaryColor = props.secondaryColor || '#FF5B7F';
    let svgId = props.svgId || 'teamLunch';
    svgId += Math.random();

    let containerStyles = { };
    if (props.containerStyles) {
        containerStyles = {
            ...containerStyles,
            ...props.containerStyles
        }
    }


    return (
        <div style={containerStyles} styleName="main-component">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 9C5 6.191 5 4.787 5.674 3.778C5.96591 3.34106 6.34106 2.96591 6.778 2.674C7.787 2 9.19 2 12 2C14.809 2 16.213 2 17.222 2.674C17.6589 2.96591 18.0341 3.34106 18.326 3.778C19 4.787 19 6.19 19 9V15C19 17.809 19 19.213 18.326 20.222C18.034 20.6589 17.6589 21.034 17.222 21.326C16.213 22 14.81 22 12 22C9.191 22 7.787 22 6.778 21.326C6.34109 21.034 5.96595 20.6589 5.674 20.222C5 19.213 5 17.81 5 15V9Z" stroke={color} stroke-width="2"/>
<path d="M15 15.5C15 16.2956 14.6839 17.0587 14.1213 17.6213C13.5587 18.1839 12.7956 18.5 12 18.5C11.2044 18.5 10.4413 18.1839 9.87868 17.6213C9.31607 17.0587 9 16.2956 9 15.5C9 14.7044 9.31607 13.9413 9.87868 13.3787C10.4413 12.8161 11.2044 12.5 12 12.5C12.7956 12.5 13.5587 12.8161 14.1213 13.3787C14.6839 13.9413 15 14.7044 15 15.5Z" stroke={color} stroke-width="2"/>
<path d="M9 5.5H15" stroke={color} stroke-width="2" stroke-linecap="round"/>
<path d="M9 10C9.55228 10 10 9.55228 10 9C10 8.44772 9.55228 8 9 8C8.44772 8 8 8.44772 8 9C8 9.55228 8.44772 10 9 10Z" fill={color}/>
<path d="M12 10C12.5523 10 13 9.55228 13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9C11 9.55228 11.4477 10 12 10Z" fill={color}/>
<path d="M15 10C15.5523 10 16 9.55228 16 9C16 8.44772 15.5523 8 15 8C14.4477 8 14 8.44772 14 9C14 9.55228 14.4477 10 15 10Z" fill={color}/>
</svg>
        </div>
    );
}



export default applyWrappers(ActionImage, styles);
