import React from 'react';
import styles from './downArrow.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';
import { checkIfPresent } from 'util/utilFunctions';

const DownArrow = (props) => {
    const skin = props.skinGuide.globalProfiles.palette
    let color = skin.grayColor4;
    if (checkIfPresent(props.svgColor)) {
        color = props.svgColor;
    }
    return (
        <div styleName="main-component">
       <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.5481 1.27045L7.99284 7.26976L2.43758 1.27045C1.87919 0.667426 0.977181 0.667426 0.418792 1.27045C-0.139597 1.87347 -0.139597 2.84759 0.418792 3.45061L6.9906 10.5477C7.54899 11.1508 8.45101 11.1508 9.0094 10.5477L15.5812 3.45061C16.1396 2.84759 16.1396 1.87347 15.5812 1.27045C15.0228 0.682888 14.1065 0.667426 13.5481 1.27045Z" fill={color}/>
</svg>
        </div>
    );
}



export default applyWrappers(DownArrow, styles);
