import React from "react";
import styles from "./featureLounging.module.sass";
// import getSkin from './skin.js';
import applyWrappers from "wrappers/ComponentWrapper";

const Skill = props => {
	// const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg
				width="100%"
				height="100%"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M14.6663 5.33337V10.002C14.6663 10.3687 14.363 10.6667 14.005 10.6667H1.99434C1.8188 10.6653 1.6509 10.5947 1.52708 10.4703C1.40327 10.3458 1.33353 10.1776 1.33301 10.002V5.33337M3.33301 10.6667V12M12.6663 10.6667V12M3.33301 3.99804C3.33301 3.63137 3.63301 3.33337 4.00434 3.33337H11.995C12.3663 3.33337 12.6663 3.63537 12.6663 3.99804V8.66671H3.33301V3.99804Z"
					stroke="black"
					stroke-width="1.5"
					stroke-linejoin="round"
				/>
			</svg>
		</div>
	);
};

export default applyWrappers(Skill, styles);
