import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins'

const getSkin = (theme) => {
	const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

	return StyleSheet.create({
		introPopupContainer: {
			background: skin.white,
			overflow: 'hidden',
			borderRadius: '8px'
		},
		headerBackground: {
			background: `linear-gradient(90deg, #972082 0%, #4B1C40 100%)`,		},
		title: {
			...myTypography.body1,
			fontSize: '1.25em',
			fontWeight: 600,
			color: skin.white,
			fontFamily: skins.fontFamily.timerFamily,
		},
		headerTitle: {
			...myTypography.body1,
			fontSize: ' 0.625em',
			fontWeight: 600,
			color: hexToRgbA(skin.grayColor1, 0.6)
		},
		transcriptText: {
			...myTypography.body1,
			color: skin.Black,
			fontSize: '1em',
			fontFamily: skins.fontFamily.bodyFamily,
			lineHeight: '2.865vh',
		},
		sprintNumber: {
			...myTypography.body1,
			backgroundColor: "#333333",
			borderRadius: "2px",
			fontWeight: 600,
			fontSize: "0.875em",
			lineHeight: "2.604vh"
		},
		sprintContainer: {
			backgroundColor: hexToRgbA(skin.black, 0.6),
			boxShadow: `0px 4px 4px 0px ${hexToRgbA(skin.black, 0.6)}`,
			borderRadius: "4px"
		},
	});
}

export default getSkin;