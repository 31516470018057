import React from 'react';
import styles from './completion.module.sass';
import getSkin from './skin';
import applyWrappers from 'wrappers/ComponentWrapper';
import CircularProgressBar from 'commonComponents/circularProgressBar';
import { css } from 'aphrodite/no-important';


const Completion = (props) => {

	const myStyles = getSkin(props.skinGuide);

	const { sprinStories, getLabel } = props;
	const total = sprinStories.length;
	const completed = sprinStories.filter((story) => {
		return story.storyStatus === 4
	}).length;
	const value = (completed/total)*100;
	const complete = `${value}%`
	// console.log(complete)
	return (
		<div styleName="wrap" className={props.fromPopup ? css(myStyles.wrapPopup) :css(myStyles.wrap)}>
			<div styleName="legend">
				<div styleName="leg-wrap">
					<div styleName="square green"></div>
					<div className={props.fromPopup ? css(myStyles.legendLabelPopup) :css(myStyles.legendLabel)}>{completed} {getLabel("label_report_tasks_done")}</div>
				</div>
				<div styleName="leg-wrap">
					<div styleName={props.fromPopup ? "square grey" :"square white"}></div>
					<div className={props.fromPopup ? css(myStyles.legendLabelPopup) :css(myStyles.legendLabel)}>{total - completed} {getLabel("label_report_tasks_unfinished")}</div>
				</div>
			</div>
			<div styleName="graph">
				{/* <CircularProgressBar value={value ? value : 0} radius={65} /> */}
				<div styleName="graph-progress-bar" className={css(myStyles.graphProgressBar)}>
					<div styleName="sim-mvp-progress-bar-container" className={props.fromPopup ? css(myStyles.mvpProgressBarContainerPopup) :css(myStyles.mvpProgressBarContainer)}>
						{total-completed}
					<div
						style={{
							width: complete
						}}
						styleName="sim-mvp-progress-bar"
						className={css(myStyles.mvpProgressBar)}
					>{completed}</div>
					</div>
				</div>
			</div>
			{/* <div styleName="legend">
				<div styleName="leg-wrap">
					<div styleName="circle blue"></div>
					<div>{completed} {getLabel("label_report_tasks_done")}</div>
				</div>
				<div styleName="leg-wrap">
					<div styleName="circle pink"></div>
					<div>{total - completed} {getLabel("label_report_tasks_unfinished")}</div>
				</div>
			</div> */}
		</div>
	);
}

export default applyWrappers(Completion, styles);