import React from 'react';
import styles from './knowYourTeam.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import analyticsUtil from 'util/segmentUtil';
import ClosedButton from 'commonComponents/buttons/closedButton';

const KnowYourTeam = (props) => {
    const closePopup = () => {
        //Segment Integratation
        analyticsUtil.track('POPUP_CLOSED', {
            metrics: props.userDetails,
            popup: "KnowYourTeam"
        });

        const { tutorial } = props;

        //Start next tutorial step
        if (tutorial.stepIndex === 1) {
            props.startTutorial()
        }

        props.setUiState({
            showOverlay: false,
            overlayComponentType: '',
            highlightDesktopHeader: false,
            highlightMetricsHeader: false,
            overlayComponent: ''
        });
    }

    const getMetricColor = (value) => {
        if (value < 45) {
            return { backgroundColor: '#D63228' };
        } else if (value < 70) {
            return { backgroundColor: '#FFD452' };
        } else {
            return { backgroundColor: '#36B496' };
        }
    }

    const renderTeamMembers = () => {
        console.log("teams", props.teamMembers)
        return props.teamMembers.teamList.map((item, i) => {
            const designation = props.getLabel(item.designation).split(":");
            return (
                <div key={i} styleName="card-style" className={css(myStyles.cardContainer)}>
                    <div styleName="card-header">
                        <div styleName="tm-member-details">
                            <div styleName='img-wrapper'>
                                <img alt="avatar" width="100%" height="100%" src={props.getImage(item.imageKey)} />
                            </div>
                            <div styleName="tm-member-text-details">
                                <div styleName="name-details">
                                    <div className={css(myStyles.tmMemberName)}>{props.getLabel(item.name)}</div>
                                    <div className={css(myStyles.tmMemberDesignation)}>
                                        {designation[0]}
                                        <div >
                                            - {designation[1]}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={css(myStyles.cardDesc)}>{props.getLabel(item.description)}</div>
                </div >
            );
        })
    }

    const myStyles = getSkin(props.skinGuide);
    const competitorName = props.getLabel(props.storylineIntro.competitorName);
    const appName = props.getLabel(props.storylineIntro.trackerName);
    const companyName = props.getLabel(props.storylineIntro.companyName);

    const kytInfo = props
        .getLabel('label_kyt_info')
        .replace(/%{COMPANY_NAME}/g, companyName)
        .replace(/%{TRACKER_NAME}/g, appName)
        .replace(/%{COMPETITOR_NAME}/g, competitorName);
    return (
        <div styleName="main-component">
            <div styleName="header-container" className={css(myStyles.headerContainer)}>
                <div className={css(myStyles.headerText)}>{props.getLabel('label_slide_pl_title')}</div>

                <div styleName="close-icon" onClick={closePopup}>
                    <ClosedButton
                        textLabel={props.getLabel('label_close_button')}
                        onClick={closePopup}
                    />
                </div>
            </div>
            <div styleName="popup-body">
                <div className={css(myStyles.teamInfoText)}>
                    {props.getLabel('label_slide_pl_desc', '', {
                        TRACKER_NAME: props.getLabel('label_tracker_name')
                    })}
                </div>
                <div styleName="team-members-container">
                    {renderTeamMembers()}
                </div>
            </div>
            <div styleName="footer-svg">
                <svg width="100%" height="100%" viewBox="0 0 745 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.1" d="M-10.4629 74.1999V0C-10.4629 0 315.847 76.3913 725 28C1134.15 -20.3913 1366 74.1999 1366 74.1999H-10.4629Z" fill="#4B1C40" />
                    <path opacity="0.1" d="M-10 73.6622V0C-10 0 118 87.5 316.5 22C515 -43.5 755.12 73.6622 755.12 73.6622H-10Z" fill="#4B1C40" />
                </svg>
            </div>

        </div>
    );
}

export default applyWrappers(KnowYourTeam, styles);