import React from 'react';
import styles from './upArrow.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';
import { checkIfPresent } from 'util/utilFunctions';

const UpArrow = (props) => {
    const skin = props.skinGuide.globalProfiles.palette
    let color = '#989898';
    if (checkIfPresent(props.svgColor)) {
        color = props.svgColor;
    }
    return (
        <div styleName="main-component">
        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.4519 10.5479L8.00716 4.5486L13.5624 10.5479C14.1208 11.1509 15.0228 11.1509 15.5812 10.5479C16.1396 9.94489 16.1396 8.97077 15.5812 8.36775L9.0094 1.27063C8.45101 0.667603 7.54899 0.667603 6.9906 1.27063L0.418792 8.36775C-0.139597 8.97077 -0.139597 9.94489 0.418792 10.5479C0.977181 11.1355 1.89351 11.1509 2.4519 10.5479Z" fill={color}/>
        </svg>
        </div>
    );
}



export default applyWrappers(UpArrow, styles);
