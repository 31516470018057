import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from '../../../../defaults/skins';

const getSkin = (theme) => {
	const skin = theme.globalProfiles.palette;
	const myTypography = typography(theme);

	return StyleSheet.create({
        headerSure: {
            fontFamily: skins.fontFamily.timerFamily,
            fontSize: '1.25em',
        },
        desc: {
            fontFamily: skins.fontFamily.bodyFamily,
            fontSize: '1.1em',
        }
	});
}

export default getSkin;