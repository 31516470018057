import { StyleSheet } from 'aphrodite';
// import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins'

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		headerBackground: {
			background: 'linear-gradient(90deg, #972082 0%, #4B1C40 100%)'
		},
		storyName: {
			...myTypography.body1,
			fontFamily : skins.fontFamily.timerFamily,
			fontSize: '1.25em',
			fontWeight: 600,
			color: skin.white
		},
	});
}

export default getSkin;