import React, { useState } from 'react';
import styles from './history.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';
import _ from "lodash"
import ClosedButton from '../../../../commonComponents/buttons/closedButton/ClosedButton.jsx';
import Skill from '../../../../svgComponents/skill';
import Morale from '../../../../svgComponents/morale';
import CustSat from '../../../../svgComponents/custSat';
import skins from '../../../../defaults/skins.js';
import UpArrowReport from '../../../../svgComponents/upArrowReport/UpArrowReport.jsx';
import DownArrowReport from '../../../../svgComponents/downArrowReport/DownArrowReport.jsx';

const metricRenderer = (metrics, allMetrics, getLabel) => {

	metrics = metrics.filter(
		(me) => [1, 2, 5].includes(me.metricsId) && me.diff != 0
	).sort((metric1, metric2) => (metric1.metricsId - metric2.metricsId));
	const met = metrics.map((metric) => {
		const metricName = allMetrics[metric.metricsId][0].key;
		const color = metric.diff < 0 ? "rgba(209, 16, 33, 1)" : "rgba(11, 127, 61, 1)";
		const backgroundColor = metric.diff < 0 ? "rgba(255, 222, 230, 1)" : "rgba(231, 251, 234, 1)";
		const arrow = metric.diff < 0 ? <DownArrowReport /> : <UpArrowReport />;

		return (
			<div styleName="met-container" key={metric.metricId}>
				<div styleName="met-icon">
					{allMetrics[metric.metricsId][0].key === "skill" && <Skill color={"black"} />}
					{allMetrics[metric.metricsId][0].key === "morale" && <Morale color={"black"} />}
					{allMetrics[metric.metricsId][0].key === "cs" && <CustSat color={"black"} />}
				</div>

				<div styleName="met-name" style={{ fontFamily: skins.fontFamily.titleFamily }}>
					{getLabel(metricName)}
					<div styleName="met-value"
						style={{ fontFamily: skins.fontFamily.bodyFamily }}>
						{metric.value}%
					</div>
				</div>

				<div styleName="metric" style={{ backgroundColor }}>
					<div styleName="arrow-cont">
						<div styleName="arrow">{arrow}</div>
						<div styleName="metric-diff" style={{ color, fontFamily: skins.fontFamily.bodyFamily }}>
							{metric.diff >= 0 ? metric.diff : -metric.diff}%
						</div>
					</div>
				</div>
			</div>
		);
	})

	return met;
}

const blockersRenderer = (blocker, allMetrics, getLabel) => {
	return (
		<div styleName="spacer">
			<div key={blocker.id} styleName="blocker" style={{ fontFamily: skins.fontFamily.bodyFamily }}>
				<div styleName="header-tag">
					<div styleName="b-hash">{getLabel("label_hash_blocker")}</div>
				</div>
				<div styleName="a-name" style={{ fontFamily: skins.fontFamily.bodyFamily }}>{getLabel(blocker.name)}</div>
			</div>

			<div key={blocker.id} styleName="blocker-msg">
				<div styleName="b-msg" style={{ fontFamily: skins.fontFamily.bodyFamily }}>{getLabel(blocker.description)}</div>
			</div>
		</div>
	)
}


const eventsRenderer = (event, allMetrics, getLabel) => {

	const met = metricRenderer(event.userMetrics, allMetrics, getLabel)
	return (
		<div styleName="spacer">
			<div key={event.id} styleName="event">
				<div styleName="header-tag" style={{ fontFamily: skins.fontFamily.bodyFamily }}>
					<div styleName="e-hash">{getLabel("label_hash_event")}</div>
				</div>
				<div styleName="a-name" style={{ fontFamily: skins.fontFamily.bodyFamily }}>{getLabel(event.name)}</div>
				<div styleName="e-msg" style={{ fontFamily: skins.fontFamily.bodyFamily }}>{getLabel(event.description)}</div>
				<div styleName="met-box met-box-eve">{met}</div>
			</div>
		</div>
	)
}

const actionsRenderer = (userAction, actions, actionOptions, allMetrics, getLabel) => {
	let selectedActionOption = actionOptions.find((actionOption) => {
		return actionOption.id == userAction.actionOptionId
	});

	if (!selectedActionOption) {			// this means that it is a blocker action
		selectedActionOption = userAction  
	}

	const selectedAction = actions.find((action) => {
		return action.id == selectedActionOption.actionId
	});
	//console.log(userAction)
	const met = metricRenderer(userAction.userMetrics, allMetrics, getLabel)
	return (
		<div styleName="spacer">
			<div key={userAction.id} styleName="action">
				<div styleName="header-tag" style={{ fontFamily: skins.fontFamily.bodyFamily }}>
					<div styleName="a-hash">{getLabel("label_hash_action")}</div>
				</div>
				<div styleName="a-name" style={{ fontFamily: skins.fontFamily.bodyFamily }}>{getLabel(selectedAction && selectedAction.name)}</div>
				<div styleName="action-option-name" style={{ fontFamily: skins.fontFamily.bodyFamily }}>{getLabel(selectedActionOption.name)}</div>
				<div styleName="e-msg" style={{ fontFamily: skins.fontFamily.bodyFamily }}>{getLabel(userAction.message)}</div>
				<div styleName="met-box">{met}</div>
			</div>
		</div>
	)
}

const dayContainer = (day, ele, getLabel) => {
	return (
		<div styleName="day" key={day} style={{ fontFamily: skins.fontFamily.bodyFamily }}>
			<div styleName="number">
				{getLabel("label_day_number")} {day}

			</div>
			<div styleName="circle"></div>
			{ele}
		</div>
	)
}

const Container = (props) => {
	const { actions, userEvents, userActions, getLabel, metrics, userBlockers, setUiState } = props
	const [action, setAction] = useState(false);
	const [event, setEvent] = useState(false);
	const [all, setAll] = useState(true);
	const [blocker, setBlocker] = useState(false);
	let allActions = userActions.userActionsList;
	const allActionOptionsStatic = actions.actionOptionsList;
	const allActionsStatic = actions.actionsList;
	const allEvents = userEvents.userEventsList;
	const allBlockets = userBlockers.blockerList;
	const allMetrics = _.groupBy(metrics.metricsList, (a) => a.metricsId);

	const sortedByday = _.groupBy([...allActions, ...allEvents, ...allBlockets], (a) => a.day);

	let allEle = [];
	for (const key of Object.keys(sortedByday).sort(function (a, b) { return b - a })) {
		const ele = sortedByday[key].map((element) => {

			if (element.actionOptionId && (action || all)) {
				return actionsRenderer(element, allActionsStatic, allActionOptionsStatic, allMetrics, getLabel)
			}

			if (element.actionId && (action || all)) {
				return actionsRenderer(element, allActionsStatic, allActionOptionsStatic, allMetrics, getLabel)
			}

			if (element.eventId && (event || all)) {
				return eventsRenderer(element, allMetrics, getLabel)
			}

			if (element.blockerId && (blocker || all)) {
				return blockersRenderer(element, allMetrics, getLabel)
			}
			if (element.actionResponse) {
				// return eventsRenderer(element, allMetrics, getLabel)
			}
			return null;
		})

		allEle.push(dayContainer(key, ele, getLabel));
	}
	allEle = _.flatten(allEle)

	console.log(props.skinGuide.globalProfiles);

	return (<div styleName="container">
		<div styleName="header">
			<div styleName="header-content" style={{ fontFamily: skins.fontFamily.titleFamily }} >{getLabel("label_history_history")}</div>
			<div styleName="close" onClick={(e) => setUiState({
				showOverlay: false,
				overlayComponentType: '',
				highlightDesktopHeader: false,
				highlightMetricsHeader: false,
				overlayComponent: ''
			})}><ClosedButton
			textLabel={props.getLabel('label_close_button')}/></div>
		</div>
		<div styleName="filter">
			<div styleName="filter-by">
				{getLabel("label_history_filter_by")}
			</div>
			<div styleName={'filter-box ' + (all ? "slected" : "")} onClick={() => { setEvent(false); setAction(false); setAll(true) }}>
				{getLabel("label_history_all")}
			</div>
			<div styleName={'filter-box ' + (!all && event ? "slected" : "")} onClick={() => { setEvent(!event); setAll(false) }}>
				<div styleName="filter-name">{getLabel("label_history_event")}</div>			
			</div>
			<div styleName={'filter-box ' + (!all && action ? "slected" : "")} onClick={() => { setAction(!action); setAll(false) }}>
				<div styleName="filter-name">{getLabel('label_history_actions')}</div>			
			</div>
			<div styleName={'filter-box ' + (!all && blocker ? "slected" : "")} onClick={() => { setBlocker(!blocker); setAll(false) }}>
				<div styleName="filter-name">{getLabel('label_history_blockers')}</div>
			</div>
		</div>
		<div styleName="line-separator"></div>
		<div styleName="box">
			<div styleName="list">{allEle}</div>
			</div>
			<div styleName="footer-svg">
				<svg width="100%" height="" viewBox="0 0 745 74" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path opacity="0.1" d="M-10.4629 74.1999V0C-10.4629 0 315.847 76.3913 725 28C1134.15 -20.3913 1366 74.1999 1366 74.1999H-10.4629Z" fill="#4B1C40" />
					<path opacity="0.1" d="M-10 73.6622V0C-10 0 118 87.5 316.5 22C515 -43.5 755.12 73.6622 755.12 73.6622H-10Z" fill="#4B1C40" />
				</svg>
			</div>
	</div>
	);
}

export default applyWrappers(Container, styles);