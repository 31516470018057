import React from 'react';
import styles from './newActions.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const ActionImage = (props) => {
    const color = props.color;
    const secondaryColor = props.secondaryColor || '#FF5B7F';
    let svgId = props.svgId || 'teamLunch';
    svgId += Math.random();

    let containerStyles = {  };
    if (props.containerStyles) {
        containerStyles = {
            ...containerStyles,
            ...props.containerStyles
        }
    }


    return (
        <div style={containerStyles} styleName="main-component">
            <svg  viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.2002 6.10591C3.2002 5.89373 3.28448 5.69025 3.43451 5.54022C3.58454 5.39019 3.78802 5.30591 4.0002 5.30591H12.0002C12.2124 5.30591 12.4159 5.39019 12.5659 5.54022C12.7159 5.69025 12.8002 5.89373 12.8002 6.10591C12.8002 6.31808 12.7159 6.52156 12.5659 6.67159C12.4159 6.82162 12.2124 6.90591 12.0002 6.90591H4.0002C3.78802 6.90591 3.58454 6.82162 3.43451 6.67159C3.28448 6.52156 3.2002 6.31808 3.2002 6.10591ZM3.2002 10.9059C3.2002 10.6937 3.28448 10.4903 3.43451 10.3402C3.58454 10.1902 3.78802 10.1059 4.0002 10.1059H12.0002C12.2124 10.1059 12.4159 10.1902 12.5659 10.3402C12.7159 10.4903 12.8002 10.6937 12.8002 10.9059C12.8002 11.1181 12.7159 11.3216 12.5659 11.4716C12.4159 11.6216 12.2124 11.7059 12.0002 11.7059H4.0002C3.78802 11.7059 3.58454 11.6216 3.43451 11.4716C3.28448 11.3216 3.2002 11.1181 3.2002 10.9059Z" fill={color} />
            </svg>
        </div>
    );
}



export default applyWrappers(ActionImage, styles);
