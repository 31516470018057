import React from 'react';
import styles from './infoIcon.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';
import { checkIfPresent } from 'util/utilFunctions';

const InfoIcon = (props) => {
    const skin = props.skinGuide.globalProfiles.palette
    let color = '#DADADA';
    if (checkIfPresent(props.svgColor)) {
        color = props.svgColor;
    }
    return (
        <div styleName="main-component">
            <svg width="100%" height="100%" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.6665 3.66009C3.6665 3.57168 3.70162 3.4869 3.76414 3.42439C3.82665 3.36188 3.91143 3.32676 3.99984 3.32676C4.08824 3.32676 4.17303 3.36188 4.23554 3.42439C4.29805 3.4869 4.33317 3.57168 4.33317 3.66009V5.66009C4.33317 5.74849 4.29805 5.83328 4.23554 5.89579C4.17303 5.9583 4.08824 5.99342 3.99984 5.99342C3.91143 5.99342 3.82665 5.9583 3.76414 5.89579C3.70162 5.83328 3.6665 5.74849 3.6665 5.66009V3.66009ZM3.99984 2.01709C3.91143 2.01709 3.82665 2.05221 3.76414 2.11472C3.70162 2.17723 3.6665 2.26202 3.6665 2.35042C3.6665 2.43883 3.70162 2.52361 3.76414 2.58613C3.82665 2.64864 3.91143 2.68376 3.99984 2.68376C4.08824 2.68376 4.17303 2.64864 4.23554 2.58613C4.29805 2.52361 4.33317 2.43883 4.33317 2.35042C4.33317 2.26202 4.29805 2.17723 4.23554 2.11472C4.17303 2.05221 4.08824 2.01709 3.99984 2.01709Z" fill={color} />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.99984 0.666748C2.15884 0.666748 0.666504 2.15908 0.666504 4.00008C0.666504 5.84108 2.15884 7.33341 3.99984 7.33341C5.84084 7.33341 7.33317 5.84108 7.33317 4.00008C7.33317 2.15908 5.84084 0.666748 3.99984 0.666748ZM1.33317 4.00008C1.33317 4.70732 1.61412 5.3856 2.11422 5.8857C2.61432 6.3858 3.29259 6.66675 3.99984 6.66675C4.70708 6.66675 5.38536 6.3858 5.88546 5.8857C6.38555 5.3856 6.6665 4.70732 6.6665 4.00008C6.6665 3.29284 6.38555 2.61456 5.88546 2.11446C5.38536 1.61437 4.70708 1.33341 3.99984 1.33341C3.29259 1.33341 2.61432 1.61437 2.11422 2.11446C1.61412 2.61456 1.33317 3.29284 1.33317 4.00008Z" fill={color} />
            </svg>
        </div>
    );
}



export default applyWrappers(InfoIcon, styles);
