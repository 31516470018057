import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins';

const getSkin = (theme) => {
	const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

	return StyleSheet.create({
		container: {
			background: `#FBFBFB`,
			boxShadow: `0px -14px 36px rgba(0, 0, 0, 0.12)`,
			backdropFilter: `blur(11px)`,
			borderTop: `solid 8px #972082`
		},
		responseContainer: {
			background: `#FBFBFB`
		},
		title: {
			fontFamily: theme.fontFamily.titleFamily,
			fontWeight: 600,
			lineHeight: "normal",
			fontSize: '1.5em',
			color: skin.black,
			marginBottom: '1vh'
		},
		responseMessage: {
			...myTypography.body1,
			fontWeight: 350,
			fontSize: "0.875em",
			// lineHeight: "23px",
			color: skin.black,
		},
		leftMetricContainer: {
			// background: `#F5F9F9`,
			boxShadow: `0px 0px 2px rgba(0, 0, 0, 0.25)`,
		},
		metricTitle: {
			...myTypography.body1,
			fontWeight: 600,
			fontSize: '0.75em',
			lineHeight: 'normal',
			color: hexToRgbA(skin.primaryColor, 0.8)
		},
		metricValue: {
			...myTypography.body1,
			fontWeight: 'bold',
			fontSize: '1.25em',
			lineHeight: 'normal',
			color: hexToRgbA(skin.primaryColor, 0.8)
		},
		metricChange: {
			...myTypography.body1,
			fontWeight: 'bold',
			fontSize: '0.75em',
			lineHeight: 'normal',
			color: skin.white,
		},
		greenTag: {
			background: `#E7FBEA`,
			color: '#0B7F3D'
		},
		redTag: {
			background: `#FFDEE6`,
			color: '#D11021'
		},
		negativeContainer: {
			borderTop: `solid 8px #D11021`
		},
		positiveContainer: {
			borderTop: `solid 8px #FBBD0E`
		},
		personName: {
			...myTypography.body1,
			fontWeight: 350,
			fontSize: '14px',
			color: skin.grayColor4
		},
		metricContainer: {
			backgroundColor: skin.white,
			border: `1px solid ${skin.black}`,
			borderRadius: '4px'
			// boxShadow: `0 1px 1px 2px ${hexToRgbA(skin.white, 0.1)}`
		},
		metricName: {
			...myTypography.body2,
			fontFamily: skins.fontFamily.titleFamily,
			fontWeight: 500,
			fontSize: '0.625em',
			lineHeight: 1,
			color: skin.black
		},
		metricNameRight: {
			...myTypography.body2,
			fontWeight: 600,
			fontSize: '0.75em',
			lineHeight: '1em',
			color: skin.black
		},
		metricValue: {
			...myTypography.body1,
			fontSize: '1em',
			fontWeight: 600,
			color: skin.black
		},
		metricValueRight: {
			...myTypography.body1,
			fontFamily: skins.fontFamily.timerFamily,
			fontSize: '1em',
			fontWeight: 700,
			color: skin.black
		}
	});
}

export default getSkin;