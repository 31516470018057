import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins'

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
        tabContainer: {
            fontFamily: skins.fontFamily.bodyFamily,
        }
    });
}

export default getSkin;