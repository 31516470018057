import React from 'react';
import styles from './areaMapPopup.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import { getUserMetricsForMetricsKey } from 'util/utilFunctions';
import analyticsUtil from 'util/segmentUtil';
import ClosedButton from 'commonComponents/buttons/closedButton';

const ArearMapPopup = (props) => {

  const closePopup = () => {
    //Segment Integratation
    analyticsUtil.track('POPUP_CLOSED', {
      metrics: props.userDetails,
      popup: 'AREA_MAP'
    });
    props.setUiState({
      showOverlay: false,
      showOverlayOverWalkthrough: false,
      overlayComponentType: 'CENTER',
      highlightDesktopHeader: false,
      highlightMetricsHeader: false,
      overlayComponent: ''
    });
  }



  const myStyles = getSkin(props.skinGuide);



  return (
    <div styleName="leaderboard-popup-container" className={css(myStyles.leaderboardPopupContainer)}>
      <div styleName="header-container" className={css(myStyles.headerContainer)}>
        <div className={css(myStyles.headerBackground)} styleName="header-background-container"></div>
        <div className={css(myStyles.title)} styleName="title">
          <div styleName='area-name'>
            <svg width="1.756vw" height="3.125vh" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 18L6 15.9L1.35 17.7C1.01667 17.8333 0.708333 17.796 0.425 17.588C0.141667 17.38 0 17.1007 0 16.75V2.75C0 2.53333 0.0626666 2.34167 0.188 2.175C0.313333 2.00833 0.484 1.88333 0.7 1.8L6 0L12 2.1L16.65 0.3C16.9833 0.166667 17.2917 0.204333 17.575 0.413C17.8583 0.621667 18 0.900667 18 1.25V15.25C18 15.4667 17.9377 15.6583 17.813 15.825C17.6883 15.9917 17.5173 16.1167 17.3 16.2L12 18ZM11 15.55V3.85L7 2.45V14.15L11 15.55ZM13 15.55L16 14.55V2.7L13 3.85V15.55ZM2 15.3L5 14.15V2.45L2 3.45V15.3Z" fill="white" />
            </svg>

            <div>  {props.getLabel('label_area_map')} </div>
          </div>
        </div>

        <div styleName="close-icon" onClick={closePopup}>
          <ClosedButton
            textLabel={props.getLabel('label_close_button')}
            onClick={closePopup}
          />
        </div>
      </div>
      <div styleName="map-container">
        <div styleName='map-image'>
          <img
            alt="map"
            style={{ width:'100%', overflow: 'scroll'}
            }
            src={props.getImage("ZOOM_MAP")}
          />
        </div>

      </div>
    </div>
  );
}

export default applyWrappers(ArearMapPopup, styles);