const actions = {
  FETCH_INIT_API: 'FETCH_INIT_API',

  //uiState
  SET_UI_STATE: 'SET_UI_STATE',

  //user
  SET_USER_STATE: 'SET_USER_STATE',

  //theme
  SET_THEME: 'SET_THEME',

  //init data
  INIT_GAME_DATA: 'INIT_GAME_DATA',

  //labels
  INIT_LABELS_DATA: 'INIT_LABELS_DATA',

  //customers
  INIT_CUSTOMERS: 'INIT_CUSTOMERS',

  //teamMembers
  INIT_TEAM_MEMBERS: 'INIT_TEAM_MEMBERS',

  //features
  INIT_FEATURES: 'INIT_FEATURES',

  //prds
  INIT_PRDS: 'INIT_PRDS',
  SET_USER_PRD: 'SET_USER_PRD',
  // STORE_PRD_RESPONSE: 'STORE_PRD_RESPONSE',

  //intro data
  SET_INTRO_DATA: 'SET_INTRO_DATA',
  SET_INTRO_PAGE: 'SET_INTRO_PAGE',
  SHOW_WELCOME_PAGE: 'SHOW_WELCOME_PAGE',

  STORE_ACTION_RESPONSE: 'STORE_ACTION_RESPONSE',

  //user stories
  INIT_USER_STORIES: 'INIT_USER_STORIES',

  //stories
  INIT_STORIES: 'INIT_STORIES',

  //user state
  UPDATE_USER_STATE: 'UPDATE_USER_STATE',

  //sprint actions
  UPDATE_USER_TASKS: 'UPDATE_USER_TASKS',
  UPDATE_USER_STORIES: 'UPDATE_USER_STORIES',
  UPDATE_DAY_DETAILS: 'UPDATE_DAY_DETAILS',

  UPDATE_USER_SPRINT_REPORTS: 'UPDATE_USER_SPRINT_REPORTS',

  UPDATE_EVENTS_TO_BE_SHOWN_COUNT: 'UPDATE_EVENTS_TO_BE_SHOWN_COUNT',

  SET_WALKTHROUGH_STATE: 'SET_WALKTHROUGH_STATE',

  UPDATE_LEADERBOARD_DATA: 'UPDATE_LEADERBOARD_DATA',
  UPDATE_LEADERBOARD_STATUS: 'UPDATE_LEADERBOARD_STATUS',

  // Tutorial actions
  START_TUTORIAL: 'START_TUTORIAL',
  PAUSE_TUTORIAL: 'PAUSE_TUTORIAL',
  END_TUTORIAL: 'END_TUTORIAL',
  ADD_TUTORIAL_STEPS: 'ADD_TUTORIAL_STEPS',
  UPDATE_TUTORIAL_STEP: 'UPDATE_TUTORIAL_STEP',
  SET_END_TUTORIAL_POPUP_FLAG: 'SET_END_TUTORIAL_POPUP_FLAG',
  DISABLE_BLOCKER_TUTORIAL: 'DISABLE_BLOCKER_TUTORIAL',

  GET_REPORT_LINK: 'GET_REPORT_LINK',
  SET_IS_DOWNLOADING_REPORT: 'SET_IS_DOWNLOADING_REPORT',

  TOGGLE_TRANSCRIPT: 'TOGGLE_TRANSCRIPT',
  ENABLE_CONTINUE: 'ENABLE_CONTINUE'
};

export default actions;
