import React from 'react';
import styles from './storyHistoryItem.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import { checkIfPresent } from 'util/utilFunctions';
import Blocker from 'svgComponents/blocker';

const labelMapping = {
	label_must: {
		image: 'MUST_PRIORITY',
		text: 'label_must',
		backgroundColor: 'mustColor',
		textColor: '#FFFFFF'
	},
	label_should: {
		image: 'SHOULD_PRIORITY',
		text: 'label_should',
		backgroundColor: 'shouldColor',
		textColor: '#FFFFFF'
	},
	label_could: {
		image: 'COULD_PRIORITY',
		text: 'label_could',
		backgroundColor: 'couldColor',
		textColor: '#000000'
	}
}


const StoryHistoryItem = (props) => {
	console.log(props)
	const getHistoryItemClass = () => {
		if(
			checkIfPresent(props.type)
			&& props.type === 'BLOCKER'
		) {
			return css(myStyles.historyItemContainer, myStyles.blockerClass)
		}
		return css(myStyles.historyItemContainer);
	}

	const myStyles = getSkin(props.skinGuide);
	return (
		<>
		<div styleName="card-bottom-container">
				<div styleName="story-point-container" className={css(myStyles.storyPointContainer)}>
					<div styleName="story-point-text">
						Story Points | 
					</div>
					<div styleName="story-point-number">
						{`  ${props.storyPoint}`}
					</div>
				</div>
				<div 
				styleName="priority-container" className={css(myStyles.priorityContainer1)}
				style={{backgroundColor: props.priorityBackgroundColor,
					color: props.priorityInfo.textColor}}
				>
					<img src={props.getImage(props.priorityInfo.image)}></img>
					<div styleName="priority-text" className={css(myStyles.priorityText)}>
						{props.getLabel(props.priorityInfo.text)}
					</div>
				</div>
			</div>
		<div
			className={getHistoryItemClass()}
			styleName="history-item-container"
		>
			<div className={css(myStyles.historyItemHeader)} styleName="history-item-header">
				<div className={css(myStyles.headerText)} styleName="history-item-header-text">
					{`${props.getLabel('label_sprint_number')} ${props.sprintNumber}`}
				</div>
				<div className={css(myStyles.headerText)} styleName="history-item-header-text">
				{`${props.getLabel('label_day_number')} ${props.sprintDay}`}
				</div>
			</div>
			<div styleName="history-item-name-container">
				<div className={css(myStyles.historyItemName)} styleName="history-item-name">
					{props.name}
				</div>
				{
					checkIfPresent(props.type) && props.type === 'BLOCKER'
						? <div styleName="history-item-image">
							<Blocker />
						</div>
						: null
				}
			</div>
			{
				checkIfPresent(props.desc)
					? <div className={css(myStyles.historyItemDesc)} styleName="history-item-desc">
						{props.desc}
					</div>
					: null
			}
		</div>
		</>
	);
}

export default applyWrappers(StoryHistoryItem, styles);