import React from 'react';
import styles from './newActions.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const ActionImage = (props) => {
    const color = props.color;
    const secondaryColor = props.secondaryColor || '#FF5B7F';
    let svgId = props.svgId || 'teamLunch';
    svgId += Math.random();

    let containerStyles = {  };
    if (props.containerStyles) {
        containerStyles = {
            ...containerStyles,
            ...props.containerStyles
        }
    }


    return (
        <div style={containerStyles} styleName="main-component">
            <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.00016 1.70972C11.6822 1.70972 14.6668 4.69438 14.6668 8.37638C14.6668 12.0584 11.6822 15.043 8.00016 15.043C4.31816 15.043 1.3335 12.0584 1.3335 8.37638C1.3335 4.69438 4.31816 1.70972 8.00016 1.70972ZM5.3335 9.04305L2.70816 9.04372C2.85669 10.2185 3.39178 11.3106 4.22917 12.1478C5.06656 12.9851 6.15867 13.52 7.3335 13.6684V11.043C6.80306 11.043 6.29435 10.8323 5.91928 10.4573C5.54421 10.0822 5.3335 9.57348 5.3335 9.04305ZM13.2922 9.04372L10.6668 9.04305C10.6668 9.57348 10.4561 10.0822 10.081 10.4573C9.70597 10.8323 9.19726 11.043 8.66683 11.043L8.6675 13.6684C9.84221 13.5199 10.9342 12.9849 11.7714 12.1476C12.6087 11.3104 13.1437 10.2184 13.2922 9.04372ZM9.3335 8.37638H6.66683V9.04305C6.66683 9.21986 6.73707 9.38943 6.86209 9.51445C6.98712 9.63948 7.15668 9.70972 7.3335 9.70972H8.66683C8.84364 9.70972 9.01321 9.63948 9.13823 9.51445C9.26326 9.38943 9.3335 9.21986 9.3335 9.04305V8.37638ZM8.00016 3.04305C6.70105 3.04293 5.44656 3.51698 4.47218 4.37622C3.49781 5.23546 2.87055 6.42079 2.70816 7.70972H5.3335C5.3335 7.53291 5.40373 7.36334 5.52876 7.23831C5.65378 7.11329 5.82335 7.04305 6.00016 7.04305H10.0002C10.177 7.04305 10.3465 7.11329 10.4716 7.23831C10.5966 7.36334 10.6668 7.53291 10.6668 7.70972H13.2922C13.1298 6.42079 12.5025 5.23546 11.5281 4.37622C10.5538 3.51698 9.29928 3.04293 8.00016 3.04305Z" fill={color} />
            </svg>
        </div>
    );
}



export default applyWrappers(ActionImage, styles);
