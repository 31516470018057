import React, { useEffect, useState } from 'react';
import styles from './taskCard.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import InfoComponent from 'commonComponents/infoComponent';
import { checkIfPresent, addItemToArray, removeItemFromArray } from 'util/utilFunctions';
import AddTaskButton from 'svgComponents/addTaskButton';
import SelectedTaskButton from 'svgComponents/selectedTaskButton';
import cardState from 'constants/cardState/cardState';
import LowPriority from 'svgComponents/lowPriority';
import MediumPriority from 'svgComponents/mediumPriority';
import HighPriority from 'svgComponents/highPriority';
import Blocker from 'svgComponents/blocker';
import BlockerResolved from 'svgComponents/blockerResolved';
import AddTaskCheckBox from 'svgComponents/addTaskCheckBox';
import SelectedTaskCheckBox from 'svgComponents/selectedTaskCheckBox';
import CustomerLike from 'svgComponents/customerLike';
import Must from '../../svgComponents/must/Must.jsx';
import Should from '../../svgComponents/should/Should.jsx';
import Could from '../../svgComponents/could/Could.jsx';
import FeatureRiver from "../../svgComponents/featureRiver/featureRiver.jsx";
import FeatureSwim from "../../svgComponents/featureSwim/featureSwim.jsx";
import FeatureSafety from "../../svgComponents/featureSafety/featureSafety.jsx";
import FeatureOther from "../../svgComponents/featureOther/featureOther.jsx";
import FeatureLounging from "../../svgComponents/featureLounging/featureLounging.jsx";

const priorityMappings = {
	label_must: {
		image: <Must />,
		text: 'label_must',
		backgroundColor: 'mustColor',
		textColor: '#FFFFFF'
	},
	label_should: {
		image: <Should />,
		text: 'label_should',
		backgroundColor: 'shouldColor',
		textColor: '#FFFFFF'
	},
	label_could: {
		image: <Could />,
		text: 'label_could',
		backgroundColor: 'couldColor',
		textColor: '#000000'
	}
};

const TaskCard = (props) => {


	// useEffect(() => {
	// 	if (props.isSelected) {
	// 		// props.setTaskCount(prevTaskCount => prevTaskCount + 1);
	// 		// props.setStoryPointCount(prevStoryPointCount => prevStoryPointCount + props.storyDetails.storyPoint);
	// 		// props.setTaskArray(prevTaskArray => addItemToArray(prevTaskArray, props.storyDetails.id));
	// 		// props.updateUserState(prevUserState => ({
	// 		// 	...prevUserState,
	// 		// 	taskCount: prevUserState.taskCount + 1,
	// 		// 	storyPointCount: prevUserState.storyPointCount + props.storyDetails.storyPoint,
	// 		// 	taskArray: addItemToArray(prevUserState.taskArray, props.storyDetails.id)
	// 		// }));
	// 		// props.setTaskCount(props.taskCount + 1);
	// 		// props.setStoryPointCount(props.storyPointCount + props.storyDetails.storyPoint);
	// 		// props.setTaskArray(addItemToArray(props.taskArray, props.storyDetails.id));
	// 		// props.updateUserState({
	// 		// 	taskCount: props.taskCount + 1,
	// 		// 	storyPointCount: props.storyPointCount + props.storyDetails.storyPoint,
	// 		// 	taskArray: addItemToArray(props.taskArray, props.storyDetails.id)
	// 		// });
	// 	}
	// }, [])

	useEffect(() => {
		if (props.userStoryDetails.showBlockerResolved) {
			setTimeout(() => {
				props.updateUserTasksAction({
					storyId: props.storyDetails.id,
					showBlockerResolved: false
				});
			}, 10000);
		}
		// showPreSelectedCards();
	});
	// if(props.isSelected) console.log(props)
	// const showPreSelectedCards = () => {
	// 	if (props.storyDetails.sprintNumber === props.userState.currentSprintNumber ) {
	// 		return props.taskArray.indexOf(props.userStoryDetails.storyId) >= 0;
	// 	}
	// 	return false;
	// }

	const isCardSelected = () => {
		// if (props.isSelected) return true;
		if (props.cardState === cardState.SELECTING_TASKS && props.cardState !== cardState.POPUP_STATE) {
			const isPresentInTaskArray = props.taskArray.indexOf(props.userStoryDetails.storyId) >= 0;
			return isPresentInTaskArray;
		}
		return false;
	}

	const getCardStyleName = () => {
		if (props.cardState === cardState.EXECUTION_STATE) {
			return "card-container card-cursor";
		}
		if (props.cardState === cardState.SELECTING_TASKS) {
			return "card-container card-width";
		}
		if(props.cardState === cardState.POPUP_STATE){
			return "card-container card-width card-popup"
		}
		return "card-container";
	}

	const onTaskCardClick = () => {
		//Go to next tutorial step, if tutorial is in progress
		const { tutorial } = props
		// if (tutorial.isRunning && tutorial.stepIndex === 3 && props.cardState === cardState.SELECTING_TASKS) {
		// 	props.updateStepNumber(tutorial.stepIndex + 1)
		// }
		if (
			(props.cardState === cardState.EXECUTION_STATE
				|| props.cardState === cardState.SELECTING_TASKS
				|| props.cardState === cardState.SETTING_PRIORITY
			)
			// ) && (tutorial.type !== 'sprintPlanningScreen')
		) {
			if (tutorial.type === 'blockerAction') {
				props.endTutorial()
				props.stopBlockerTutorial()
			}
			props.updateUserState({
				selectedStoryId: props.userStoryDetails.storyId,
				taskArray: props.taskArray
			});
			props.setUiState({
				showOverlay: true,
				overlayComponentType: 'CENTER',
				highlightDesktopHeader: true,
				highlightMetricsHeader: false,
				overlayComponent: 'STORY_POPUP'
			});
		}
	}

	const getPriorityImageComponent = (priorityId) => {
		switch (priorityId) {
			case 1:
				return <HighPriority />;
			case 2:
				return <MediumPriority />;
			case 3:
				return <LowPriority />;
			default:
				return null;
		}
	}

	const setUserTaskPriority = (priorityId) => {
		props.updateUserTasksAction({
			storyId: props.storyDetails.id,
			storyPriority: priorityId
		});
	}

	const renderBlockerTag = () => {
		return (
			<div className={css(myStyles.blockerTag, myStyles.redTag)} styleName="blocker-tag-component">
				<div styleName="blocker-image">
					<Blocker />
				</div>
				<div className={css(myStyles.blockerText, myStyles.redText)} styleName="blocker-text">
					{props.getLabel('label_task_attention')}
				</div>
			</div>
		);
	}

	const renderPriorityContainer = () => {
		return (
			<div
				styleName="priority-container-up-right" className={css(myStyles.priorityContainer1)}
				style={{
					backgroundColor: priorityBackgroundColor,
					color: priorityInfo.textColor
				}}
			>
				<div styleName="priority-image-container">
					{/* <img styleName="priority-image" src={props.getImage(priorityInfo.image)}></img> */}
					{priorityInfo.image}
				</div>
				<div styleName="priority-text">
					{props.getLabel(priorityInfo.text)}
				</div>
			</div>
		);
	}

	const renderBlockerClearedTag = () => {
		return (
			<div className={css(myStyles.blockerTag, myStyles.greenTag)} styleName="blocker-tag-component">
				<div styleName="blocker-image">
					<BlockerResolved />
				</div>
				<div className={css(myStyles.blockerText, myStyles.greenText)} styleName="blocker-text">
					{props.getLabel('label_task_blocker_cleared')}
				</div>
			</div>
		);
	}

	const renderCustomerLike = () => {
		return (
			<div styleName='customer-like-icon'>
				<CustomerLike />
			</div>
		);
	}

	const renderCardStatusBox = () => {
		// console.log(props.cardState)
		switch (props.cardState) {
			case cardState.SELECTING_TASKS:
				return renderTaskAddButton();
			case cardState.SETTING_PRIORITY:
				return renderPriorityContainer();
			case cardState.EXECUTION_STATE:
				return;
			case cardState.BLOCKED_STATE:
				return;
			case cardState.RESOLVED_STATE:
				return;
			case cardState.POPUP_STATE:
				if (props.storyDetails.isPA)
					return renderCustomerLike();
			default:
				return;
		}
	}

	const renderDescPriorityContainer = () => {
		switch (props.cardState) {
			case cardState.SELECTING_TASKS:
				return;
			case cardState.SETTING_PRIORITY:
				return renderPriorityButtons();
			case cardState.EXECUTION_STATE:
				return renderTaskPriority();
			case cardState.BLOCKED_STATE:
				return renderTaskPriority();
			case cardState.RESOLVED_STATE:
				return renderTaskPriority();
			case cardState.POPUP_STATE:
				return renderTaskPriority();
			default:
				return;

		}
	}

	const onClickAddTaskButton = () => {
		const { tutorial } = props
		// if tutorial is running and needs click on card to zoom, disable the function
		if (tutorial.isRunning && tutorial.stepIndex === 1 && props.cardState === cardState.SELECTING_TASKS) {
			props.updateStepNumber(tutorial.stepIndex + 1)
			return
		}

		if (isCardSelected()) {
			props.setTaskCount(props.taskCount - 1);
			props.setStoryPointCount(props.storyPointCount - props.storyDetails.storyPoint);
			props.setTaskArray(removeItemFromArray(props.taskArray, props.storyDetails.id));
			props.updateUserState({
				taskCount: props.taskCount - 1,
				storyPointCount: props.storyPointCount - props.storyDetails.storyPoint,
				taskArray: removeItemFromArray(props.taskArray, props.storyDetails.id)
			});
		} else {
			props.setTaskCount(props.taskCount + 1);
			props.setStoryPointCount(props.storyPointCount + props.storyDetails.storyPoint);
			props.setTaskArray(addItemToArray(props.taskArray, props.storyDetails.id));
			props.updateUserState({
				taskCount: props.taskCount + 1,
				storyPointCount: props.storyPointCount + props.storyDetails.storyPoint,
				taskArray: addItemToArray(props.taskArray, props.storyDetails.id)
			});
			// If tutorial is running, stop the tutorial after click on the + button
			// if (tutorial.isRunning && tutorial.stepIndex == 2) {
			// 	setTimeout(() => { props.pauseTutorial() }, 1000)
			// }
		}
	}

	const renderTaskAddButton = () => {
		if (
			props.userStoryDetails.storyStatus === 4
			|| props.userStoryDetails.progress === props.storyDetails.storyPoint
		) {
			return null;
		}
		return (
			<div styleName="add-task-button-container">
				<div
					styleName="add-task-button"
					// className={isCardSelected() ? css(myStyles.selectedShadow) : null}
					onClick={(e) => {
						onClickAddTaskButton();
						e.stopPropagation();
					}}
				>
					{
						isCardSelected()
							? <SelectedTaskCheckBox />
							: <AddTaskCheckBox />
					}

				</div>
			</div>
		);
	}

	const renderPriorityButtons = () => {
		const priorityList = props.priorities.prioritiesList;
		let isPrioritySet = false
		if (!props.userStoryDetails.storyPriority) {
			setUserTaskPriority(props.storyDetails.sprintPriority);
		}
		// console.log(priorityList);
		const priorityButtonList = priorityList.map((eachPriority) => {
			if (eachPriority.priorityId === props.userStoryDetails.storyPriority) {
				isPrioritySet = true;
			}
			return (
				<div
					styleName="each-priority"
					className={
						eachPriority.priorityId === props.userStoryDetails.storyPriority
							? css(myStyles.selectedPriority)
							: css(myStyles.eachPriority)
					}
					onClick={(e) => {
						setUserTaskPriority(eachPriority.priorityId);
						e.stopPropagation();
					}}
				>
					{props.getLabel(eachPriority.name)}
				</div>
			);
		});
		return (
			<div
				styleName="priorities-button-container"
				className={isPrioritySet ? css(myStyles.prioritiesContainerSelected) : css(myStyles.prioritiesContainer)}
			>
				<div
					styleName="set-priority-heading"
					className={css(myStyles.setPriorityHeading)}
				>
					{props.getLabel('label_set_priority')} :
				</div>
				<div styleName="priority-buttons">
					{priorityButtonList}
				</div>
			</div>
		);
	}

	// console.log('Task priority')
	const renderTaskPriority = () => {
		if (!checkIfPresent(props.userStoryDetails.storyPriority)) {
			return null;
		}
		const priorityList = props.priorities.prioritiesList;
		const storyPriorityDetails = priorityList.find((eachPriority) => {
			return (eachPriority.priorityId === props.userStoryDetails.storyPriority)
		});
		return (
			<div
				styleName="task-priority-container"
				className={css(myStyles.priorityContainer)}
			>
				{/* <div styleName="task-priority-image">
					{getPriorityImageComponent(props.userStoryDetails.storyPriority)}
				</div> */}
				<div
					styleName="task-priority-letter"
					className={css(myStyles.priorityLetter)}
				>
					{props.getLabel(storyPriorityDetails.key)}
				</div>
			</div>
		);
	}

	const renderBlocker = () => {
		if (props.userStoryDetails.showBlocker && (props.userStoryDetails.progress < props.storyDetails.storyPoint)) {
			return renderBlockerTag();
		} else if (props.userStoryDetails.showBlockerResolved) {
			return renderBlockerClearedTag();
		}
		else return;
	}

	const renderFeatureImage = (feature) => {
		switch (feature.imageKey) {
            case 'IMG_FEATURE_RP':
                return <FeatureRiver />;
            case 'IMG_FEATURE_SB':
                return <FeatureSwim />;
            case 'IMG_FEATURE_SS':
                return <FeatureSafety />;
            case 'IMG_FEATURE_LA':
                return <FeatureLounging />;
            case 'IMG_FEATURE_OF':
                return <FeatureOther />;
            default:
                return <img alt="default" src={props.getImage('IMG_DEFAULT_FEATURE')} />;;
        }
	}

	const isArabic = props.userState.lang === "arabic";
	// console.log(props.featureDetails)
	const renderCardFeatureDetails = () => {
		const featureDetails = props.featureDetails;

		if (props.userStoryDetails.showBlocker) {
			return renderBlockerTag();
		} else if (props.userStoryDetails.showBlockerResolved) {
			return renderBlockerClearedTag();
		}

		return (
			<div styleName="feature-details-container">
				<span
					styleName="feature-container"
					className={css(myStyles.featureContainer)}
				>
					{!isArabic && (
						<span styleName="feature-image">
							{renderFeatureImage(featureDetails)}
						</span>
					)}
					<span
						styleName="feature-name"
						className={css(myStyles.featureName)}
					>
						{props.getLabel(featureDetails.name)}
					</span>
					{isArabic && (
						<span styleName="feature-image">
							{renderFeatureImage(featureDetails)}
						</span>
					)}
				</span>
				<span
					styleName="feature-desc"
					className={css(myStyles.featureDesc)}
				>
					{props.getLabel(featureDetails.name).length +
						props.getLabel(featureDetails.description).length >
						70
						? `${props
							.getLabel(featureDetails.description)
							.substring(
								0,
								70 -
								props.getLabel(featureDetails.name)
									.length
							)}...`
						: props.getLabel(featureDetails.description)}
					{/* {props.getLabel(featureDetails.description)} */}
				</span>
			</div>
		);
	}

	let idName = `task-card-` + props.index;

	const getCardClassName = () => {
		if (isCardSelected()) {
			return css(myStyles.cardContainer, myStyles.cardSelectedContainer);
		}
		else if (props.cardState === cardState.POPUP_STATE && props.storyDetails.isPA) {
			return css(myStyles.cardContainer, myStyles.borderPopUp);
		}
		else if (props.cardState === cardState.POPUP_STATE && !props.storyDetails.isPA) {
			return css(myStyles.cardContainer, myStyles.noCursor);
		}
		else if (props.userStoryDetails.showBlocker) {
			idName = 'task-card-blocker';
			return css(myStyles.cardContainer, myStyles.borderBlocker);
		}
		return css(myStyles.cardContainer);
	}

	const { priorityTag } = props.storyDetails;
	const priorityInfo = priorityMappings[priorityTag] || {};

	const priorityBackgroundColor = props.skinGuide.globalProfiles.palette[priorityInfo.backgroundColor];

	const myStyles = getSkin(props.skinGuide);
	let priorityStyle = 'priority-container';
	if (props.cardState === 'SELECTING_TASKS') {
		priorityStyle += '-down-right'
	}
	else if (props.cardState === 'EXECUTION_STATE' || props.cardState === 'POPUP_STATE') {
		priorityStyle += '-down-left'
	}

	let zero = '0';
	if (props.storyDetails.storyPoint >= 10) {
		zero = '';
	}


	//Added specific style for zoomed card, when tutorial is ongoing
	return (
		<div
			className={getCardClassName()}
			styleName={getCardStyleName()}
			// style={
			// 	props.isZoomed
			// 		? {
			// 			margin: '0',
			// 			zoom: 1.5,
			// 			cursor: 'auto'
			// 		}
			// 		: {}
			// }
			key={`user-story-${props.userStoryDetails.storyId}`}
			onClick={onTaskCardClick}
		>
			<div styleName='card-child' id={idName}></div>
			<div styleName="card-tag" className={css(myStyles.cardTag)}>
				<div styleName="card-tag-image">
					{renderFeatureImage(props.featureDetails)}
				</div>
				<div styleName="card-tag-text" className={css(myStyles.cardTagText)}>
					{props.getLabel(props.featureDetails.name)}
				</div>
			</div>
			<div styleName="card-content">
				<div styleName="card-header">
					<div className={css(myStyles.cardHeading)} styleName="card-heading-content">
						{
							props.getLabel(props.storyDetails.name).length >= 55 && false
								? `${props.getLabel(props.storyDetails.name).substring(0, 52)}...`
								: props.getLabel(props.storyDetails.name)
						}
					</div>
					<div styleName="card-status-box">
						{renderCardStatusBox()}
					</div>
				</div>
				<div styleName="progress-container">
					<div className={css(myStyles.progressbackground)} styleName="progress-bar-background">
						<div
							style={{
								width: `${Math.round((props.userStoryDetails.progress / props.storyDetails.storyPoint) * 100, 2)}%`
							}}
							className={css(myStyles.progressNoBlocker)}
							styleName="progress-bar"
						></div>
					</div>
				</div>
				<div>
					{
						renderBlocker()
					}
				</div>
				<div styleName="card-description" className={css(myStyles.cardDescription)}>
					{props.getLabel(props.storyDetails.description)}
				</div>
			</div>
			{/* <div styleName="progress-container">
				<div className={css(myStyles.progressbackground)} styleName="progress-bar-background">
					<div
						style={{
							width: `${Math.round((props.userStoryDetails.progress / props.storyDetails.storyPoint) * 100, 2)}%`
						}}
						className={css(myStyles.progressNoBlocker)}
						styleName="progress-bar"
					></div>
				</div>
			</div> */}
			{/* {
				props.cardState === cardState.POPUP_STATE
					? props.storyDetails.isPA
						? <div styleName="story-point-container-like-customer">
							<div styleName="story-point">
								<div className={css(myStyles.storyPoints)} styleName="storypoints-text">
									{props.getLabel('label_story_points')} | {props.storyDetails.storyPoint}
								</div>
								<InfoComponent
									tooltipText={props.getLabel(props.storyDetails.storyPointDescription)}
									alignTooltipKey="TOP"
									tooltipStyles={{
										width: '220px'
									}}
								/>
							</div>
							<div styleName="like-customer-img">
								<CustomerLike />
							</div>
						</div>
						: <div styleName="story-point-container">
							<div className={css(myStyles.storyPoints)} styleName="storypoints-text">
								{props.getLabel('label_story_points')} | {props.storyDetails.storyPoint}
							</div>
							<InfoComponent
								tooltipText={props.getLabel(props.storyDetails.storyPointDescription)}
								alignTooltipKey="TOP"
								tooltipStyles={{
									width: '220px'
								}}
							/>
						</div>
					: <div styleName="story-point-container">
						<div className={css(myStyles.storyPoints)} styleName="storypoints-text">
							{props.getLabel('label_story_points')} | {props.storyDetails.storyPoint}
						</div>
						<InfoComponent
							tooltipText={props.getLabel(props.storyDetails.storyPointDescription)}
							alignTooltipKey="TOP"
							tooltipStyles={{
								width: '220px'
							}}
						/>
					</div>
			} */}
			<div styleName="card-bottom-container">
				<div styleName="story-point-container" className={css(myStyles.storyPointContainer)}>
					<div styleName="story-point-text">
						Story Points |
					</div>
					<div styleName="story-point-number">
						{`${zero}${props.storyDetails.storyPoint}`}
					</div>
					<div styleName="story-point-icon">
						<InfoComponent
							tooltipText={props.getLabel('label_tooltip_storypoints')}
							alignTooltipKey="TOP"
							svgColor="#333333"
						/>
					</div>
				</div>
				{props.cardState !== cardState.SETTING_PRIORITY
					? <div
						styleName={priorityStyle} className={css(myStyles.priorityContainer1)}
						style={{
							backgroundColor: priorityBackgroundColor,
							color: priorityInfo.textColor
						}}
					>
						<div styleName="priority-image-container">
							{/* <img styleName="priority-image" src={props.getImage(priorityInfo.image)}></img> */}
							{priorityInfo.image}
						</div>
						<div styleName="priority-text">
							{props.getLabel(priorityInfo.text)}
						</div>
					</div>
					: null
				}
				<div styleName="desc-priority-container">
					{renderDescPriorityContainer()}
				</div>
			</div>
		</div>
	);
}

export default applyWrappers(TaskCard, styles);