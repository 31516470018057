import React from 'react';
import styles from './addTaskCheckBox.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const AddTaskCheckBox = (props) => {

	const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path="url(#clip0_1467_2115)">
				<path d="M4.29289 4.29289C4.48043 4.10536 4.73478 4 5 4H19C19.2652 4 19.5196 4.10536 19.7071 4.29289C19.8946 4.48043 20 4.73478 20 5V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V5C4 4.73478 4.10536 4.48043 4.29289 4.29289Z" stroke="#972082" stroke-width="2" stroke-linejoin="round"/>
				</g>
				<defs>
				<clipPath id="clip0_1467_2115">
				<rect width="24" height="24" fill="white"/>
				</clipPath>
				</defs>
			</svg>
		</div>
	);
}

export default applyWrappers(AddTaskCheckBox, styles);