import React from 'react';
import styles from './customerLike.module.sass';
import getSkin from './skin.js';
// import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import { checkIfPresent } from 'util/utilFunctions';

const CustomerLike = (props) => {

	const myStyles = getSkin(props.skinGuide);
	const skin = props.skinGuide.globalProfiles.palette
	let color = skin.green;
	if (checkIfPresent(props.svgColor)) {
		color = props.svgColor;
	}
	return (
		<div styleName="main-component">
			<svg  viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1.59961 8.4001C1.59961 8.24251 1.63065 8.08647 1.69095 7.94088C1.75126 7.79529 1.83965 7.663 1.95108 7.55157C2.06251 7.44014 2.1948 7.35175 2.34039 7.29144C2.48598 7.23114 2.64202 7.2001 2.79961 7.2001C2.9572 7.2001 3.11324 7.23114 3.25883 7.29144C3.40442 7.35175 3.53671 7.44014 3.64814 7.55157C3.75957 7.663 3.84796 7.79529 3.90826 7.94088C3.96857 8.08647 3.99961 8.24251 3.99961 8.4001V13.2001C3.99961 13.5184 3.87318 13.8236 3.64814 14.0486C3.42309 14.2737 3.11787 14.4001 2.79961 14.4001C2.48135 14.4001 2.17612 14.2737 1.95108 14.0486C1.72604 13.8236 1.59961 13.5184 1.59961 13.2001V8.4001ZM4.79961 8.2665V12.6105C4.79947 12.9078 4.88219 13.1993 5.03847 13.4523C5.19476 13.7052 5.41844 13.9096 5.68441 14.0425L5.72441 14.0625C6.16832 14.2844 6.65774 14.3999 7.15401 14.4001H11.4868C11.8568 14.4002 12.2155 14.2721 12.5017 14.0376C12.7878 13.803 12.9839 13.4765 13.0564 13.1137L14.0164 8.3137C14.0628 8.0816 14.0571 7.84209 13.9998 7.61245C13.9424 7.38281 13.8348 7.16875 13.6848 6.98571C13.5347 6.80266 13.3459 6.65519 13.132 6.55393C12.918 6.45266 12.6843 6.40012 12.4476 6.4001H9.59961V3.2001C9.59961 2.77575 9.43104 2.36878 9.13098 2.06873C8.83092 1.76867 8.42396 1.6001 7.99961 1.6001C7.78744 1.6001 7.58395 1.68438 7.43392 1.83441C7.2839 1.98444 7.19961 2.18792 7.19961 2.4001V2.9337C7.19961 3.62608 6.97504 4.29979 6.55961 4.8537L5.43961 6.3465C5.02418 6.90041 4.79961 7.57411 4.79961 8.2665Z" fill="#0B7F3D" />
			</svg>
		</div>
	);
}

export default applyWrappers(CustomerLike, styles);