import React, { Component } from 'react';
import styles from './objectives.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';
import { checkIfPresent } from 'util/utilFunctions';

class Objectives extends Component {

    render() {
        const skin = this.props.skinGuide.globalProfiles.palette
        let color = skin.white;
        if (checkIfPresent(this.props.svgColor)) {
            color = this.props.svgColor;
        }
        return (
            <div styleName="main-component">
                <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 9C1.5 5.4645 1.5 3.69675 2.598 2.598C3.6975 1.5 5.4645 1.5 9 1.5C12.5355 1.5 14.3032 1.5 15.4012 2.598C16.5 3.6975 16.5 5.4645 16.5 9C16.5 12.5355 16.5 14.3032 15.4012 15.4012C14.304 16.5 12.5355 16.5 9 16.5C5.4645 16.5 3.69675 16.5 2.598 15.4012C1.5 14.304 1.5 12.5355 1.5 9Z" stroke={color}/>
                <path d="M4.5 11.85L5.35725 12.75L7.5 10.5M4.5 6.6L5.35725 7.5L7.5 5.25" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.75 6.75H13.5M9.75 12H13.5" stroke={color} stroke-linecap="round"/>
                </svg>
            </div>
        );
    }
}



export default applyWrappers(Objectives, styles);
