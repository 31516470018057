import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'util/styleUtil';
import skins from 'defaults/skins'

const getSkin = (theme) => {
	const skin = theme.globalProfiles.palette;
	const myTypography = typography(theme);

	return StyleSheet.create({
		timerContainer: {
			background: "#EE774833",
			borderRadius: "4px"
		},
		timerContent: {
			fontFamily: skins.fontFamily.titleFamily,
			fontWeight: 600,
			fontSize: '1.5em',
			color: skin.white
		},
		timerContent_ar: {
			fontFamily: skins.fontFamily.titleFamily,
			fontWeight: 600,
			fontSize: '1.5em',
			color: skin.white
		}
	});
}

export default getSkin;