import React from 'react';
import styles from './filledButton.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import { checkIfPresent } from 'util/utilFunctions';

class FilledButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovered: false,
            isPressed: false,
        };
    }

    handleMouseEnter = () => {
        this.setState({ isHovered: true });
    };

    handleMouseLeave = () => {
        this.setState({ isHovered: false });
    };

    handleMouseDown = () => {
        this.setState({ isPressed: true });
    };

    handleMouseUp = () => {
        this.setState({ isPressed: false });
    };


    onClickOfFilledButton = () => {
        if (checkIfPresent(this.props.disableButton)) {
            if (this.props.disableButton === true) {
                return;
            } else {
                if (checkIfPresent(this.props.clickFunction)) {
                    this.props.clickFunction();
                }
            }
        } else {
            if (checkIfPresent(this.props.clickFunction)) {
                this.props.clickFunction();
            }
        }
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        let buttonShadowStyle = css(myStyles.buttonStyle);
        const isDisabled = checkIfPresent(this.props.disableButton)
            ? this.props.disableButton ? 1 : 0
            : 0;
        let backGroundColor = "#EE7748";
        let hoverColor = '#CF6D46';
        let border = '0px solid #F9C784';
        let pressedColor = '#B35632'
        // if(checkIfPresent(this.props.backGroundColor)){
        //     backGroundColor = this.props.backGroundColor
        //     hoverColor = '#D99067'
        //     border ='1px solid #F9C784'
        //     pressedColor = '#DA7C48'
        // }
        

        if (isDisabled) {
            buttonShadowStyle = css(myStyles.buttonStyleDisabled);
        } else {
            buttonShadowStyle = css(myStyles.buttonStyle);
        }

        const { isHovered, isPressed } = this.state;

        return (
			<div
				className={buttonShadowStyle}
				styleName="button-style"
				onClick={this.onClickOfFilledButton}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onMouseDown={this.handleMouseDown}
                onMouseUp={this.handleMouseUp}
				style={{
					opacity: isDisabled ? 0.5 : 1,
					cursor: isDisabled ? "not-allowed" : "pointer",
                    backgroundColor: isPressed ? pressedColor : isHovered ? hoverColor : backGroundColor,
                    border: border,
					...this.props.customStyle
				}}
			>
				<div className={css(myStyles.buttonText)}
                    style={this.props.textCustomStyle}
                >
					{this.props.textLabel}
				</div>
			</div>
		);
    }
}

export default applyWrappers(FilledButton, styles);