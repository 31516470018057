import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		leaderboardPopupContainer: {
			background: skin.white,
			overflow: 'hidden',
			borderRadius: '8px',
		},
		headerBackground: {
            background: `linear-gradient(90deg, #972082 0%, #4B1C40 100%)`,
		},
		title: {
			...myTypography.body1,
			fontSize: '1.25em',
			fontWeight: 600,
			color: skin.white,
			fontFamily: skins.fontFamily.timerFamily,
		},
		headerTitle: {
			...myTypography.body1,
			fontSize: '0.80em',
			fontWeight: 600,
			color: skin.black,
			fontFamily: skins.fontFamily.bodyFamily,
		},
		value: {
			...myTypography.body1,
			fontSize: '0.875em',
			fontWeight: 600,
			color: hexToRgbA(skin.black, 1),
		},
		currentUserValue: {
			...myTypography.body1,
			fontSize: '0.875em',
			fontWeight: 600,
			color: skin.black,
			fontFamily: skins.fontFamily.bodyFamily,
		},
		currentUserStatus: {
			...myTypography.body1,
			fontSize: '12px',
			color: skin.black,
			fontFamily: skins.fontFamily.bodyFamily,
		},
		seperationLine: {
			background: hexToRgbA('#407EA6', 0.1)
		},
		currentUser: {
			background: hexToRgbA(skin.orange, 0.2)
		},
		refreshContainer: {
			color: skin.black,
			fontWeight: 600,
		}
	});
}

export default getSkin;