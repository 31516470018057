import { StyleSheet } from 'aphrodite';
// import { hexToRgbA } from 'util/styleUtil';
// import typography from 'defaults/typography';

const getSkin = (theme) => {
	const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	// const myTypography = typography(theme);

	return StyleSheet.create({
		white: {
			background: skin.white
		},
		fadedWhite: {
			background: skin.white,
			opacity: 0.3
		},
		orange: {
			background: skin.orange
		}
	});
}

export default getSkin;