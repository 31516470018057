import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins'

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
        cardName : {
            fontFamily: skins.fontFamily.bodyFamily,
            fontWeight: 600,
            fontSize: '0.75em'
        },
        cardDesc:{
            fontFamily: skins.fontFamily.bodyFamily,
            fontWeight: 350,
            fontSize: '0.75em'
        },
        info: {
            fontFamily: skins.fontFamily.titleFamily,
            fontWeight: 400,
            fontSize: '0.625em',
            color: skin.black
        },
        priorityText: {
            fontFamily: skins.fontFamily.bodyFamily,
            fontWeight: 400,
            fontSize: '0.625em'
        },
        cardTag: {
			background: skin.grayColor8
		},
		cardTagText: {
			...myTypography.card_text
		},
        priorityContainer: {
            borderRadius: '2px',
            fontFamily: skins.fontFamily.bodyFamily
        }
    });
}

export default getSkin;