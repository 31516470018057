import React from 'react';
import styles from './selectedTaskButtonHollow.module.sass';
// import getSkin from './skin.js';
// import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import { checkIfPresent } from 'util/utilFunctions';

const SelectedTaskButtonHollow = (props) => {

	// const skin = props.skinGuide.globalProfiles.palette
	let color = "#42BAFF";
	if (checkIfPresent(props.svgColor)) {
		color = props.svgColor;
	}
	return (
		<div styleName="main-component">
			<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle cx="11.9831" cy="11.7741" r="10.7741" stroke="#EE7748" stroke-width="2"/>
				<path d="M6.20898 13L9.20898 16L17.209 8" stroke="#EE7748" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
		</div>
	);
}

export default applyWrappers(SelectedTaskButtonHollow, styles);