import React from 'react';
import styles from './filledCircle.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const Close = (props) => {
    const skin = props.skinGuide.globalProfiles.palette
    const color = props.backgroundColor || skin.white;

    return (
        <div styleName="main-component">
            <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="8.5" cy="8" r="7" stroke="#EE7748" stroke-width="2" />
                <circle cx="8.5" cy="8" r="4" fill="#EE7748" />
            </svg>
        </div>
    );
}



export default applyWrappers(Close, styles);
