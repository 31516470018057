import React from 'react';
import styles from './cross.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const Cross = (props) => {
    const skin = props.skinGuide.globalProfiles.palette
    const color = props.backgroundColor || skin.white;

    return (
        <div styleName="main-component">
            <svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_648_102002)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0001 14.1221L17.3031 19.4251C17.5845 19.7065 17.9661 19.8646 18.3641 19.8646C18.762 19.8646 19.1437 19.7065 19.4251 19.4251C19.7065 19.1437 19.8646 18.7621 19.8646 18.3641C19.8646 17.9662 19.7065 17.5845 19.4251 17.3031L14.1201 12.0001L19.4241 6.69711C19.5634 6.55778 19.6738 6.39238 19.7492 6.21036C19.8245 6.02834 19.8633 5.83326 19.8632 5.63626C19.8632 5.43926 19.8243 5.2442 19.7489 5.06221C19.6735 4.88022 19.5629 4.71488 19.4236 4.57561C19.2843 4.43634 19.1189 4.32588 18.9368 4.25054C18.7548 4.17519 18.5597 4.13644 18.3627 4.13648C18.1657 4.13653 17.9707 4.17538 17.7887 4.25081C17.6067 4.32624 17.4414 4.43678 17.3021 4.57611L12.0001 9.87911L6.69709 4.57611C6.55879 4.43278 6.39333 4.31843 6.21036 4.23973C6.02739 4.16103 5.83058 4.11956 5.63141 4.11774C5.43224 4.11591 5.23471 4.15377 5.05033 4.22911C4.86595 4.30444 4.69842 4.41574 4.55752 4.55652C4.41661 4.69729 4.30515 4.86471 4.22964 5.04902C4.15414 5.23333 4.11609 5.43083 4.11773 5.63C4.11936 5.82917 4.16065 6.02602 4.23917 6.20906C4.3177 6.3921 4.43189 6.55767 4.57509 6.69611L9.88009 12.0001L4.57609 17.3041C4.43289 17.4425 4.3187 17.6081 4.24017 17.7912C4.16165 17.9742 4.12036 18.1711 4.11873 18.3702C4.11709 18.5694 4.15514 18.7669 4.23064 18.9512C4.30615 19.1355 4.41761 19.3029 4.55852 19.4437C4.69942 19.5845 4.86695 19.6958 5.05133 19.7711C5.23571 19.8464 5.43324 19.8843 5.63241 19.8825C5.83158 19.8807 6.02839 19.8392 6.21136 19.7605C6.39433 19.6818 6.55979 19.5674 6.69809 19.4241L12.0001 14.1221Z" fill={color} />
                </g>
                <defs>
                    <clipPath id="clip0_648_102002">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
}



export default applyWrappers(Cross, styles);
