const formatActionOptionPayload = (actionOptionId, payload, selectedActionOption, isBlockerAction, getState) => {
	switch (selectedActionOption.type) {
		case 'default':
			if(isBlockerAction){
				return {
					actionOptionId,
					blockerId: payload.blockerId
				}
			}
			return {
				actionOptionId
			};

		case 'updatePrd':
			return {
				actionOptionId,
				...payload
			};

		case 'replanSprint':
			return {
				actionOptionId,
				...payload
			};

		default:
			if(isBlockerAction){
				return {
					actionOptionId,
					blockerId: payload.blockerId
				}
			}
			return {
				actionOptionId
			};
	}
}

const formatActionResponse = (response, body) => {
	return {
		...response,
		actionOptionId: body.actionOptionId
	};
}

export {
	formatActionOptionPayload,
	formatActionResponse
}