import React from "react";
import styles from "./featureSafety.module.sass";
// import getSkin from './skin.js';
import applyWrappers from "wrappers/ComponentWrapper";

const Skill = props => {
	// const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg
				width="100%"
				height="100%"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_726_92697)">
					<path
						d="M7.532 1.46333C7.79508 1.3649 8.08253 1.35207 8.35333 1.42666L8.468 1.46333L13.1347 3.21333C13.3713 3.30206 13.5778 3.4562 13.7302 3.6578C13.8826 3.8594 13.9745 4.10015 13.9953 4.352L14 4.462V8.03733C14 9.11761 13.7083 10.1778 13.1557 11.1061C12.6032 12.0344 11.8103 12.7963 10.8607 13.3113L10.6833 13.404L8.44733 14.522C8.32422 14.5835 8.18974 14.6189 8.05231 14.626C7.91489 14.6331 7.77747 14.6118 7.64867 14.5633L7.55267 14.522L5.31667 13.404C4.35041 12.9209 3.53252 12.1858 2.94934 11.2764C2.36616 10.367 2.03932 9.31705 2.00333 8.23733L2 8.03733V4.462C2 4.20942 2.07175 3.96203 2.20689 3.74864C2.34203 3.53526 2.535 3.36464 2.76333 3.25666L2.86533 3.21333L7.532 1.46333ZM8 2.712L3.33333 4.462V8.03733C3.33335 8.874 3.5583 9.69528 3.98464 10.4152C4.41097 11.1351 5.02301 11.7271 5.75667 12.1293L5.91333 12.2113L8 13.2547L10.0867 12.2113C10.8352 11.8372 11.4693 11.2686 11.9226 10.5652C12.3759 9.86186 12.6317 9.04953 12.6633 8.21333L12.6667 8.03733V4.462L8 2.712ZM10.2887 5.75266C10.4086 5.6331 10.5696 5.56368 10.7389 5.55851C10.9082 5.55334 11.0731 5.61281 11.2002 5.72483C11.3272 5.83685 11.4069 5.99303 11.4229 6.16165C11.439 6.33026 11.3903 6.49867 11.2867 6.63266L11.2313 6.69533L7.742 10.1853C7.6149 10.3124 7.44555 10.3884 7.26611 10.3988C7.08668 10.4093 6.90966 10.3535 6.76867 10.242L6.70467 10.1853L5.102 8.58266C4.98116 8.46297 4.91065 8.30162 4.90488 8.13163C4.89911 7.96165 4.95853 7.79589 5.07098 7.66828C5.18342 7.54067 5.34039 7.46086 5.50975 7.44519C5.67911 7.42953 5.84805 7.47918 5.982 7.584L6.04467 7.63933L7.22333 8.818L10.2887 5.75266Z"
						fill="black"
					/>
				</g>
				<defs>
					<clipPath id="clip0_726_92697">
						<rect width="100%" height="100%" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};

export default applyWrappers(Skill, styles);
