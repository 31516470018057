import React from 'react';
import styles from './calendar.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const Calendar = (props) => {

	// const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 16 16" fill="none">
				<g opacity="0.5">
					<path d="M4.66671 7H4.00004C3.82323 7 3.65366 7.07024 3.52864 7.19526C3.40361 7.32029 3.33337 7.48986 3.33337 7.66667C3.33337 7.84348 3.40361 8.01305 3.52864 8.13807C3.65366 8.2631 3.82323 8.33333 4.00004 8.33333H4.66671C4.84352 8.33333 5.01309 8.2631 5.13811 8.13807C5.26314 8.01305 5.33337 7.84348 5.33337 7.66667C5.33337 7.48986 5.26314 7.32029 5.13811 7.19526C5.01309 7.07024 4.84352 7 4.66671 7Z" fill="#407078" />
					<path d="M7.99996 7H7.33329C7.15648 7 6.98691 7.07024 6.86189 7.19526C6.73686 7.32029 6.66663 7.48986 6.66663 7.66667C6.66663 7.84348 6.73686 8.01305 6.86189 8.13807C6.98691 8.2631 7.15648 8.33333 7.33329 8.33333H7.99996C8.17677 8.33333 8.34634 8.2631 8.47136 8.13807C8.59639 8.01305 8.66663 7.84348 8.66663 7.66667C8.66663 7.48986 8.59639 7.32029 8.47136 7.19526C8.34634 7.07024 8.17677 7 7.99996 7Z" fill="#407078" />
					<path d="M11.3333 7H10.6667C10.4899 7 10.3203 7.07024 10.1953 7.19526C10.0702 7.32029 10 7.48986 10 7.66667C10 7.84348 10.0702 8.01305 10.1953 8.13807C10.3203 8.2631 10.4899 8.33333 10.6667 8.33333H11.3333C11.5101 8.33333 11.6797 8.2631 11.8047 8.13807C11.9298 8.01305 12 7.84348 12 7.66667C12 7.48986 11.9298 7.32029 11.8047 7.19526C11.6797 7.07024 11.5101 7 11.3333 7Z" fill="#407078" />
					<path d="M4.66671 9.66602H4.00004C3.82323 9.66602 3.65366 9.73625 3.52864 9.86128C3.40361 9.9863 3.33337 10.1559 3.33337 10.3327C3.33337 10.5095 3.40361 10.6791 3.52864 10.8041C3.65366 10.9291 3.82323 10.9993 4.00004 10.9993H4.66671C4.84352 10.9993 5.01309 10.9291 5.13811 10.8041C5.26314 10.6791 5.33337 10.5095 5.33337 10.3327C5.33337 10.1559 5.26314 9.9863 5.13811 9.86128C5.01309 9.73625 4.84352 9.66602 4.66671 9.66602Z" fill="#407078" />
					<path d="M7.99996 9.66602H7.33329C7.15648 9.66602 6.98691 9.73625 6.86189 9.86128C6.73686 9.9863 6.66663 10.1559 6.66663 10.3327C6.66663 10.5095 6.73686 10.6791 6.86189 10.8041C6.98691 10.9291 7.15648 10.9993 7.33329 10.9993H7.99996C8.17677 10.9993 8.34634 10.9291 8.47136 10.8041C8.59639 10.6791 8.66663 10.5095 8.66663 10.3327C8.66663 10.1559 8.59639 9.9863 8.47136 9.86128C8.34634 9.73625 8.17677 9.66602 7.99996 9.66602Z" fill="#407078" />
					<path d="M11.3333 9.66602H10.6667C10.4899 9.66602 10.3203 9.73625 10.1953 9.86128C10.0702 9.9863 10 10.1559 10 10.3327C10 10.5095 10.0702 10.6791 10.1953 10.8041C10.3203 10.9291 10.4899 10.9993 10.6667 10.9993H11.3333C11.5101 10.9993 11.6797 10.9291 11.8047 10.8041C11.9298 10.6791 12 10.5095 12 10.3327C12 10.1559 11.9298 9.9863 11.8047 9.86128C11.6797 9.73625 11.5101 9.66602 11.3333 9.66602Z" fill="#407078" />
					<path d="M4.66671 12.334H4.00004C3.82323 12.334 3.65366 12.4042 3.52864 12.5292C3.40361 12.6543 3.33337 12.8238 3.33337 13.0007C3.33337 13.1775 3.40361 13.347 3.52864 13.4721C3.65366 13.5971 3.82323 13.6673 4.00004 13.6673H4.66671C4.84352 13.6673 5.01309 13.5971 5.13811 13.4721C5.26314 13.347 5.33337 13.1775 5.33337 13.0007C5.33337 12.8238 5.26314 12.6543 5.13811 12.5292C5.01309 12.4042 4.84352 12.334 4.66671 12.334Z" fill="#407078" />
					<path d="M7.99996 12.334H7.33329C7.15648 12.334 6.98691 12.4042 6.86189 12.5292C6.73686 12.6543 6.66663 12.8238 6.66663 13.0007C6.66663 13.1775 6.73686 13.347 6.86189 13.4721C6.98691 13.5971 7.15648 13.6673 7.33329 13.6673H7.99996C8.17677 13.6673 8.34634 13.5971 8.47136 13.4721C8.59639 13.347 8.66663 13.1775 8.66663 13.0007C8.66663 12.8238 8.59639 12.6543 8.47136 12.5292C8.34634 12.4042 8.17677 12.334 7.99996 12.334Z" fill="#407078" />
					<path d="M11.3333 12.334H10.6667C10.4899 12.334 10.3203 12.4042 10.1953 12.5292C10.0702 12.6543 10 12.8238 10 13.0007C10 13.1775 10.0702 13.347 10.1953 13.4721C10.3203 13.5971 10.4899 13.6673 10.6667 13.6673H11.3333C11.5101 13.6673 11.6797 13.5971 11.8047 13.4721C11.9298 13.347 12 13.1775 12 13.0007C12 12.8238 11.9298 12.6543 11.8047 12.5292C11.6797 12.4042 11.5101 12.334 11.3333 12.334Z" fill="#407078" />
					<path d="M14 2H12.1667C12.1225 2 12.0801 1.98244 12.0488 1.95118C12.0176 1.91993 12 1.87754 12 1.83333V0.666667C12 0.489856 11.9298 0.320286 11.8047 0.195262C11.6797 0.0702379 11.5101 0 11.3333 0C11.1565 0 10.987 0.0702379 10.8619 0.195262C10.7369 0.320286 10.6667 0.489856 10.6667 0.666667V3.83333C10.6667 3.96594 10.614 4.09312 10.5202 4.18689C10.4265 4.28065 10.2993 4.33333 10.1667 4.33333C10.0341 4.33333 9.90688 4.28065 9.81311 4.18689C9.71935 4.09312 9.66667 3.96594 9.66667 3.83333V2.33333C9.66667 2.24493 9.63155 2.16014 9.56904 2.09763C9.50652 2.03512 9.42174 2 9.33333 2H5.16667C5.12258 2 5.08029 1.98253 5.04905 1.95142C5.01781 1.92031 5.00018 1.87809 5 1.834V0.666667C5 0.489856 4.92976 0.320286 4.80474 0.195262C4.67971 0.0702379 4.51014 0 4.33333 0C4.15652 0 3.98695 0.0702379 3.86193 0.195262C3.7369 0.320286 3.66667 0.489856 3.66667 0.666667V3.83333C3.66667 3.96594 3.61399 4.09312 3.52022 4.18689C3.42645 4.28065 3.29927 4.33333 3.16667 4.33333C3.03406 4.33333 2.90688 4.28065 2.81311 4.18689C2.71934 4.09312 2.66667 3.96594 2.66667 3.83333V2.33333C2.66667 2.24493 2.63155 2.16014 2.56904 2.09763C2.50652 2.03512 2.42174 2 2.33333 2H1.33333C0.979711 2 0.640573 2.14048 0.390524 2.39052C0.140476 2.64057 0 2.97971 0 3.33333V14.6667C0 15.0203 0.140476 15.3594 0.390524 15.6095C0.640573 15.8595 0.979711 16 1.33333 16H14C14.3536 16 14.6928 15.8595 14.9428 15.6095C15.1929 15.3594 15.3333 15.0203 15.3333 14.6667V3.33333C15.3333 2.97971 15.1929 2.64057 14.9428 2.39052C14.6928 2.14048 14.3536 2 14 2ZM14 14.3333C14 14.4217 13.9649 14.5065 13.9024 14.569C13.8399 14.6315 13.7551 14.6667 13.6667 14.6667H1.66667C1.57826 14.6667 1.49348 14.6315 1.43096 14.569C1.36845 14.5065 1.33333 14.4217 1.33333 14.3333V6.33333C1.33333 6.24493 1.36845 6.16014 1.43096 6.09763C1.49348 6.03512 1.57826 6 1.66667 6H13.6667C13.7551 6 13.8399 6.03512 13.9024 6.09763C13.9649 6.16014 14 6.24493 14 6.33333V14.3333Z" fill="#407078" />
				</g>
			</svg>
		</div>
	);
}

export default applyWrappers(Calendar, styles);