import React from 'react';
import styles from './logoutArrow.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';
import { checkIfPresent } from 'util/utilFunctions';

const UpArrow = (props) => {
    const skin = props.skinGuide.globalProfiles.palette
    let color = skin.black;
    if (checkIfPresent(props.svgColor)) {
        color = props.svgColor;
    }
    return (
        <div styleName="main-component">
        <svg width="100%" height="100%" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M45.3333 42.667L56 32.0003M56 32.0003L45.3333 21.3337M56 32.0003L18.6667 32.0003M34.6667 42.667V45.3337C34.6667 49.7519 31.0849 53.3337 26.6667 53.3337H16C11.5817 53.3337 8 49.7519 8 45.3337V18.667C8 14.2487 11.5817 10.667 16 10.667H26.6667C31.0849 10.667 34.6667 14.2487 34.6667 18.667V21.3337" stroke="white" strokeWidth="5.33333" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
        </div>
    );
}



export default applyWrappers(UpArrow, styles);
