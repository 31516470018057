import React from 'react';
import styles from './newActions.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const ActionImage = (props) => {
    const color = props.color;
    const secondaryColor = props.secondaryColor || '#FF5B7F';
    let svgId = props.svgId || 'teamLunch';
    svgId += Math.random();

    let containerStyles = { };
    if (props.containerStyles) {
        containerStyles = {
            ...containerStyles,
            ...props.containerStyles
        }
    }


    return (
        <div style={containerStyles} styleName="main-component">
            <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.81818 8.5L6.72727 10.2727L11.1818 6.13636M13.0909 10.2727C12.4545 10.8636 13.8864 12.4886 13.0909 13.2273C12.2955 13.9659 10.5455 12.6364 9.90909 13.2273C9.27273 13.8182 8.95455 15 8 15C7.04545 15 6.72727 13.8182 6.09091 13.2273C5.45455 12.6364 3.70455 13.9659 2.90909 13.2273C2.11364 12.4886 3.54545 10.8636 2.90909 10.2727C2.27273 9.68182 1 9.38636 1 8.5C1 7.61364 2.27273 7.31818 2.90909 6.72727C3.54545 6.13636 2.11364 4.51136 2.90909 3.77273C3.70455 3.03409 5.45455 4.36364 6.09091 3.77273C6.72727 3.18182 7.04545 2 8 2C8.95455 2 9.27273 3.18182 9.90909 3.77273C10.5455 4.36364 12.2955 3.03409 13.0909 3.77273C13.8864 4.51136 12.4545 6.13636 13.0909 6.72727C13.7273 7.31818 15 7.61364 15 8.5C15 9.38636 13.7273 9.68182 13.0909 10.2727Z" stroke={color} stroke-width="1.2" stroke-linecap="round" />
            </svg>
        </div>
    );
}



export default applyWrappers(ActionImage, styles);
