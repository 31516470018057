import React from 'react';
import styles from './newActions.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const ActionImage = (props) => {
    const color = props.color;
    const secondaryColor = props.secondaryColor || '#FF5B7F';
    let svgId = props.svgId || 'teamLunch';
    svgId += Math.random();

    let containerStyles = { };
    if (props.containerStyles) {
        containerStyles = {
            ...containerStyles,
            ...props.containerStyles
        }
    }


    return (
        <div style={containerStyles} styleName="main-component">
            <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.34247 8.63012L5.36986 14.1801C5.35769 14.2173 5.32724 14.2608 5.27854 14.3104L4.69406 14.8878C4.58447 14.9995 4.45979 15.0276 4.32 14.9719C4.18021 14.9163 4.0979 14.8138 4.07306 14.6643L4 13.8634L4.01826 13.6958L6.06393 7.92241C6.24658 8.09623 6.44457 8.24225 6.6579 8.36045C6.87123 8.47865 7.09942 8.56854 7.34247 8.63012ZM8 7.95966C7.39117 7.95966 6.87367 7.74238 6.44749 7.30782C6.02131 6.87326 5.80822 6.34559 5.80822 5.72479C5.80822 5.24057 5.94533 4.80924 6.21954 4.4308C6.49376 4.05236 6.84371 3.7884 7.26941 3.63891V2.74496C7.26941 2.53389 7.33954 2.35709 7.47982 2.21455C7.62009 2.07202 7.79349 2.0005 8 2C8.20651 1.99951 8.38015 2.07102 8.52091 2.21455C8.66167 2.35808 8.73157 2.53488 8.73059 2.74496V3.63891C9.15677 3.7879 9.50697 4.05186 9.78119 4.4308C10.0554 4.80973 10.1923 5.24106 10.1918 5.72479C10.1918 6.34559 9.97869 6.87326 9.55251 7.30782C9.12633 7.74238 8.60883 7.95966 8 7.95966ZM8 6.46975C8.207 6.46975 8.38064 6.39823 8.52091 6.2552C8.66119 6.11217 8.73108 5.93536 8.73059 5.72479C8.73011 5.51421 8.65997 5.33741 8.52018 5.19438C8.3804 5.05135 8.207 4.97983 8 4.97983C7.793 4.97983 7.6196 5.05135 7.47982 5.19438C7.34003 5.33741 7.26989 5.51421 7.26941 5.72479C7.26892 5.93536 7.33906 6.11241 7.47982 6.25594C7.62058 6.39947 7.79397 6.47074 8 6.46975ZM8.65753 8.63012C8.90106 8.56804 9.1295 8.4779 9.34283 8.3597C9.55616 8.2415 9.75391 8.09574 9.93607 7.92241L11.9817 13.6958C11.9939 13.7331 12 13.7889 12 13.8634L11.9269 14.6643C11.9026 14.8133 11.8203 14.9158 11.68 14.9719C11.5397 15.0281 11.415 15 11.3059 14.8878L10.7215 14.3104L10.6301 14.1801L8.65753 8.63012Z" fill={color} />
            </svg>
        </div>
    );
}



export default applyWrappers(ActionImage, styles);
