import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins'

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		leftMetricContainer: {
			// background: `#F5F9F9`,
			boxShadow: `0px 0px 2px rgba(0, 0, 0, 0.25)`,
		},
		previousDay: {
			...myTypography.body1,
			fontWeight: 600,
			fontSize: '0.75em',
			lineHeight: 'normal',
			color: hexToRgbA(skin.primaryColor, 0.4)
		},
		currentDay: {
			...myTypography.body1,
			fontWeight: 600,
			fontSize: '0.75em',
			lineHeight: 'normal',
			color: hexToRgbA(skin.primaryColor, 0.8)
		},
		sprintBlock: {
			background: `#FF1E50`
		},
		upcomingSprintBlock: {
			background: `${hexToRgbA('#020202', 0.2)}`
		},
		sprintContent: {
			// backgroundColor: skin.white,
			border: `1px solid ${skin.black}`
		},
		sprintName: {
			...myTypography.body2,
			fontFamily: skins.fontFamily.titleFamily,
			color: skin.black,
			lineHeight: 1,
			fontSize: '0.625em',
			fontWeight: 500
		},
		dayClass: {
			...myTypography.body1,
			fontSize: '1em',
			fontWeight: 600,
			color: skin.black
		},
		completedDay: {
			background: skin.orange
		},
		notCompletedDay: {
			background: skin.grayColor8
		},
		currentDay: {
			background: skin.orange,
			opacity: 0.5
		},
	});
}

export default getSkin;