import React from "react";
import styles from "./featureOther.module.sass";
// import getSkin from './skin.js';
import applyWrappers from "wrappers/ComponentWrapper";

const Skill = props => {
	// const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg
				width="100%"
				height="100%"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_726_92711)">
					<path
						d="M7.59989 1.40036L1.47156 7.52869C1.34658 7.65371 1.27637 7.82325 1.27637 8.00002C1.27637 8.1768 1.34658 8.34634 1.47156 8.47136L7.60023 14.6C7.72524 14.725 7.89478 14.7952 8.07156 14.7952C8.24833 14.7952 8.41787 14.725 8.54289 14.6L14.6712 8.47169C14.7962 8.34667 14.8664 8.17713 14.8664 8.00036C14.8664 7.82358 14.7962 7.65404 14.6712 7.52902L8.54256 1.40002C8.41754 1.27504 8.248 1.20483 8.07123 1.20483C7.89445 1.20483 7.72491 1.27504 7.59989 1.40002V1.40036Z"
						stroke="black"
						stroke-width="1.5"
						stroke-linejoin="round"
					/>
					<path
						d="M6 8H10M8 6V10"
						stroke="black"
						stroke-width="1.5"
						stroke-linecap="round"
					/>
				</g>
				<defs>
					<clipPath id="clip0_726_92711">
						<rect width="100%" height="100%" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};

export default applyWrappers(Skill, styles);
