import { StyleSheet } from 'aphrodite';
// import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		actionContainer: {
			background: `#F7D3C5`,
			boxShadow: `0px 4px 12px rgba(213, 173, 196, 0.5)`,
			border: '1.5px solid #EE7748',
			cursor: 'pointer'
		},
		actionName: {
			...myTypography.body2,
			fontSize: '1em',
			fontWeight: 600,
			lineHeight: 1.2,
		},
		actionNameStory: {
			...myTypography.body2,
			fontSize: '0.75em',
			fontWeight: 600,
			lineHeight: 1.2,
			color: skin.black,
		},
		hoverActionContainer: {
			background: `#FD9065`,
			cursor: 'pointer'
		},
		selectedActionContainer: {
			background: skin.orange,
			color: skin.white
		}
	});
}

export default getSkin;