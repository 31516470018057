import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		completeContainer: {
			boxShadow: `0px 0px 15px 5px ${hexToRgbA(skin.black, 0.2)}`,
			background: skin.white
		},
		headerContainer: {
			background: 'linear-gradient(90deg, #972082 0%, #4B1C40 100%)'
		},
		headerText: {
			...myTypography.body1,
			fontFamily: skins.fontFamily.timerFamily,
			fontSize: '1.25em',
			color: skin.white,
			fontWeight: 600
		},
		headerDesc: {
			...myTypography.body1,
			fontWeight: 350,
			fontSize: '0.875em',
			color: skin.white
		},
		tasksContainer: {
			...myTypography.body1,
			fontSize: '1em',
			fontWeight: 600,
			color: skin.black,
		},
		storyPointsContainer: {
			...myTypography.body1,
			fontFamily: skins.fontFamily.timerFamily,
			fontSize: '0.875em',
			fontWeight: 350,
			color: skin.black
		}
	});
}

export default getSkin;