import React from 'react';
import styles from './newActions.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const ActionImage = (props) => {
    const color = props.color;
    const secondaryColor = props.secondaryColor || '#FF5B7F';
    let svgId = props.svgId || 'teamLunch';
    svgId += Math.random();

    let containerStyles = { };
    if (props.containerStyles) {
        containerStyles = {
            ...containerStyles,
            ...props.containerStyles
        }
    }


    return (
        <div style={containerStyles} styleName="main-component">
            <svg  viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.34247 7.74755L5.36986 13.2975C5.35769 13.3347 5.32724 13.3782 5.27854 13.4278L4.69406 14.0052C4.58447 14.1169 4.45979 14.145 4.32 14.0894C4.18021 14.0337 4.0979 13.9312 4.07306 13.7817L4 12.9809L4.01826 12.8133L6.06393 7.03984C6.24658 7.21367 6.44457 7.35968 6.6579 7.47788C6.87123 7.59608 7.09942 7.68597 7.34247 7.74755ZM8 7.07709C7.39117 7.07709 6.87367 6.85981 6.44749 6.42525C6.02131 5.99069 5.80822 5.46302 5.80822 4.84222C5.80822 4.358 5.94533 3.92667 6.21954 3.54823C6.49376 3.16979 6.84371 2.90583 7.26941 2.75634V1.86239C7.26941 1.65132 7.33954 1.47452 7.47982 1.33198C7.62009 1.18945 7.79349 1.11793 8 1.11743C8.20651 1.11694 8.38015 1.18845 8.52091 1.33198C8.66167 1.47551 8.73157 1.65231 8.73059 1.86239V2.75634C9.15677 2.90533 9.50697 3.16929 9.78119 3.54823C10.0554 3.92716 10.1923 4.35849 10.1918 4.84222C10.1918 5.46302 9.97869 5.99069 9.55251 6.42525C9.12633 6.85981 8.60883 7.07709 8 7.07709ZM8 5.58718C8.207 5.58718 8.38064 5.51566 8.52091 5.37263C8.66119 5.2296 8.73108 5.05279 8.73059 4.84222C8.73011 4.63164 8.65997 4.45484 8.52018 4.31181C8.3804 4.16878 8.207 4.09726 8 4.09726C7.793 4.09726 7.6196 4.16878 7.47982 4.31181C7.34003 4.45484 7.26989 4.63164 7.26941 4.84222C7.26892 5.05279 7.33906 5.22985 7.47982 5.37337C7.62058 5.5169 7.79397 5.58817 8 5.58718ZM8.65753 7.74755C8.90106 7.68547 9.1295 7.59533 9.34283 7.47713C9.55616 7.35893 9.75391 7.21317 9.93607 7.03984L11.9817 12.8133C11.9939 12.8505 12 12.9064 12 12.9809L11.9269 13.7817C11.9026 13.9307 11.8203 14.0333 11.68 14.0894C11.5397 14.1455 11.415 14.1174 11.3059 14.0052L10.7215 13.4278L10.6301 13.2975L8.65753 7.74755Z" fill={color} />
            </svg>
        </div>
    );
}



export default applyWrappers(ActionImage, styles);
