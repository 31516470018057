import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		mvpPopupContainer: {
			background: skin.white,
			overflow: 'hidden',
			borderRadius: '8px'
		},
		headerBackground: {
			backgroundI: skin.greyColor8
		},
		headingText: {
			fontFamily : skins.fontFamily.timerFamily,
			color: skin.black,
			fontSize: '1.25em',
			fontWeight: 600
		},
		descText: {
			...myTypography.body1,
			color: skin.darkGrey,
			fontWeight: 350,
			fontSize: '0.875em',
			lineHeight: '1em'
		},
		helpText: {
			...myTypography.body1,
			color: skin.darkGrey,
			fontWeight: 600,
			fontSize: '0.75em'
		},
		helpText2: {
			...myTypography.body1,
			color: '#989898',
			fontWeight: 600,
			fontSize: '0.75em'
		},
		contentContainer: {
			background: 'rgba(238, 119, 72, 0.1)'
		}
	});
}

export default getSkin;