import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		emailHeading: {
			...myTypography.body2,
			color: skin.black
		},
		emailContainer: {
			border: `2px solid ${skin.grayColor8}`,
			borderRadius: '4px',
			color: skin.black,
			background: skin.white
		},
		emailInputElement: {
			fontFamily: skins.fontFamily.bodyFamily,
			color: skin.grayColor9,
			fontSize: '0.75em',
			lineHeight: '1em',
			fontWeight: 350
			// '::placeholder': {
			// 	color: hexToRgbA(skin.white, 0.8),
			// 	...myTypography.body2
			// },
			// '::-ms-input-placeholder': {
			// 	color: hexToRgbA(skin.white, 0.8),
			// 	...myTypography.body2
			// },
			// ':-ms-input-placeholder': {
			// 	color: hexToRgbA(skin.white, 0.8),
			// 	...myTypography.body2
			// }
		},
		emailSendEnable: {
			cursor: 'pointer',
			opacity: 1
		},
		emailSendDisable: {
			cursor: 'not-allowed',
			opacity: 0.5
		},
		emailStatusText: {
			...myTypography.body2,
			color: skin.white,
			fontStyle: 'italic'
		}
	});
}

export default getSkin;