import React from 'react';
import styles from './custSat.module.sass';
// import getSkin from './skin.js';
import applyWrappers from 'wrappers/ComponentWrapper';

const CustSat = (props) => {

	// const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M19.071 13.142L13.414 18.8C13.0389 19.175 12.5303 19.3856 12 19.3856C11.4697 19.3856 10.961 19.175 10.586 18.8L4.92899 13.143C4.46136 12.6795 4.08989 12.1281 3.83592 11.5206C3.58194 10.9131 3.45046 10.2614 3.44902 9.60299C3.44758 8.94454 3.5762 8.29229 3.82752 7.68368C4.07883 7.07508 4.44788 6.5221 4.91347 6.05651C5.37907 5.59091 5.93204 5.22186 6.54065 4.97055C7.14925 4.71924 7.8015 4.59061 8.45995 4.59205C9.1184 4.59349 9.77009 4.72498 10.3776 4.97895C10.9851 5.23293 11.5364 5.60439 12 6.07202C12.9416 5.1539 14.2069 4.64369 15.522 4.65193C16.8371 4.66017 18.096 5.18619 19.026 6.11605C19.956 7.0459 20.4822 8.30472 20.4906 9.6198C20.499 10.9349 19.989 12.2003 19.071 13.142Z" stroke={props.color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
		</div>
	);
}

export default applyWrappers(CustSat, styles);