import React from 'react';
import styles from './newActions.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const ActionImage = (props) => {
    const color = props.color;
    const secondaryColor = props.secondaryColor || '#FF5B7F';
    let svgId = props.svgId || 'teamLunch';
    svgId += Math.random();

    let containerStyles = { };
    if (props.containerStyles) {
        containerStyles = {
            ...containerStyles,
            ...props.containerStyles
        }
    }


    return (
        <div style={containerStyles} styleName="main-component">
            <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.00021 5.41968C8.76244 5.41968 9.49021 5.44279 10.1835 5.48901C10.8618 5.5339 11.4835 5.62723 12.0489 5.76901C12.5911 5.90412 13.062 6.10612 13.4615 6.37501C13.8414 6.63127 14.1469 6.98338 14.3469 7.39568C14.5642 7.83368 14.6669 8.38234 14.6669 9.03968C14.6678 9.71079 14.5402 10.3123 14.2842 10.8443C14.0344 11.3643 13.6929 11.775 13.2595 12.0763C12.8371 12.3695 12.3337 12.5233 11.8195 12.5163C11.48 12.5172 11.1729 12.4706 10.8982 12.3763C10.6258 12.283 10.3643 12.1605 10.1182 12.011C9.88095 11.8643 9.6486 11.7098 9.42155 11.5477C9.20855 11.3932 8.98563 11.2529 8.75421 11.1277C8.52349 10.9997 8.2647 10.9308 8.00088 10.927C7.75221 10.927 7.50088 10.9917 7.24621 11.127C7.0351 11.2403 6.81288 11.3803 6.57955 11.547C6.36532 11.7003 6.13266 11.8548 5.88155 12.0103C5.63639 12.1607 5.37521 12.2832 5.10288 12.3757C4.82732 12.4699 4.5211 12.5166 4.18421 12.5157C3.66903 12.5236 3.1643 12.3699 2.74088 12.0763C2.3071 11.7741 1.96555 11.3635 1.71621 10.8443C1.46021 10.3128 1.33266 9.71079 1.33355 9.03834C1.33088 8.38501 1.43355 7.83701 1.65155 7.39834C1.85192 6.98589 2.15782 6.63376 2.53821 6.37768C2.93821 6.10923 3.40932 5.90768 3.95155 5.77301C4.56421 5.62403 5.18888 5.52966 5.81821 5.49101C6.51155 5.44346 7.23888 5.41968 8.00021 5.41968Z" stroke={color} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
    );
}



export default applyWrappers(ActionImage, styles);
