import React from 'react';
import styles from './desktopFooter.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const DesktopFooter = (props) => {

	const openKnolskapeLink = () => {
		window.open("https://www.knolskape.com", "_blank");
	}

	const myStyles = getSkin(props.skinGuide);
	return (
		<div className={css(myStyles.footerContainer)} styleName="footer-container">
			<div onClick={() => { openKnolskapeLink(); }} className={css(myStyles.textClass, myStyles.linkKnolskape)}>{props.getLabel('label_footer_knolskape_name').toUpperCase()  }</div>
			<div className={css(myStyles.textClass)}>&copy;{new Date().getFullYear()}</div>
		</div>
	);
}

export default applyWrappers(DesktopFooter, styles);