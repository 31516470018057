import { StyleSheet } from 'aphrodite';
// import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins'

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		helpTextReport: {
			...myTypography.body2,
			color: skin.white
		},
		tabs: {
			background : 'rgba(0,0,0,0.7)'
		},
		tabsPopup: {
			background : '#DADADA'
		},
		text: {
			background : 'linear-gradient(90deg, #972082 0%, #4B1C40 100%)',
			color: 'white'
		},
		textHeading: {
			fontFamily: skins.fontFamily.titleFamily,
			fontWeight: 600,
			fontSize: '1.5em'
		},
		textPara: {
			fontFamily: skins.fontFamily.bodyFamily,
			fontWeight: 350,
			fontSize: '1em'
		},
		metName :{
			color: skin.white,
			fontFamily: skins.fontFamily.timerFamily,
			fontWeight: 600,
			fontSize: '1.25em',
			background : 'rgba(0,0,0,0.6)'
		},
		metNamePopup : {
			color: skin.black,
			fontFamily: skins.fontFamily.timerFamily,
			fontWeight: 600,
			fontSize: '1.25em',
			background : '#DADADA'
		},
		heading: {
			color: skin.white,
			fontFamily: skins.fontFamily.timerFamily,
			fontWeight: 600,
			fontSize: '1.25em',
			background : 'rgba(0,0,0,0.6)'
		},
		verticalBar: {
			background: '#989898'
		},
		title : {
			fontFamily : skins.fontFamily.titleFamily,
			fontWeight: 600,
			fontSize: '1.5em',
			color: skin.black
		},
		ms : {
			fontFamily : skins.fontFamily.bodyFamily,
			fontWeight: 350,
			fontSize: '1em',
			color: skin.black
		}
	});
}

export default getSkin;