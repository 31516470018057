import React from 'react';
import styles from './blocker.module.sass';
// import getSkin from './skin.js';
import applyWrappers from 'wrappers/ComponentWrapper';

const Blocker = (props) => {

	// const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2108_18545)">
<path d="M6 11C6.65674 11.0009 7.30717 10.8719 7.91391 10.6206C8.52065 10.3693 9.07174 10.0005 9.5355 9.5355C10.0005 9.07174 10.3693 8.52065 10.6206 7.91391C10.8719 7.30717 11.0009 6.65674 11 6C11.0008 5.34327 10.8719 4.69285 10.6206 4.08611C10.3692 3.47937 10.0005 2.92828 9.5355 2.4645C9.07174 1.99951 8.52065 1.63075 7.91391 1.37943C7.30717 1.1281 6.65674 0.999153 6 1C5.34327 0.999167 4.69285 1.12812 4.08611 1.37944C3.47937 1.63077 2.92828 1.99952 2.4645 2.4645C1.99952 2.92828 1.63077 3.47937 1.37944 4.08611C1.12812 4.69285 0.999167 5.34327 1 6C0.999153 6.65674 1.1281 7.30717 1.37943 7.91391C1.63075 8.52065 1.99951 9.07174 2.4645 9.5355C2.92828 10.0005 3.47937 10.3692 4.08611 10.6206C4.69285 10.8719 5.34327 11.0008 6 11Z" stroke="#D11021" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6 9.25C6.16576 9.25 6.32473 9.18415 6.44194 9.06694C6.55915 8.94973 6.625 8.79076 6.625 8.625C6.625 8.45924 6.55915 8.30027 6.44194 8.18306C6.32473 8.06585 6.16576 8 6 8C5.83424 8 5.67527 8.06585 5.55806 8.18306C5.44085 8.30027 5.375 8.45924 5.375 8.625C5.375 8.79076 5.44085 8.94973 5.55806 9.06694C5.67527 9.18415 5.83424 9.25 6 9.25Z" fill="#D11021"/>
<path d="M6 3V7" stroke="#D11021" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_2108_18545">
<rect width="12" height="12" fill="white"/>
</clipPath>
</defs>
</svg>
		</div>
	);
}

export default applyWrappers(Blocker, styles);