import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';

const getSkin = (theme) => {
	const skin = theme.globalProfiles.palette;
	const myTypography = typography(theme);

	return StyleSheet.create({
		objectivesContainer: {
			background: '#EE774833',
			border: '1px solid #EE7748'
		},
		objectivesText: {
			color: skin.white,
			...myTypography.body1,
			fontWeight: 600,
			fontSize: '0.65em'
		},
	});
}

export default getSkin;