const walkthroughTabsList = [
	{
		key: 'planning',
		name: 'label_transition_foundations',
		isSelected: true,
		isComplted: false
	},
	{
		key: 'execution',
		name: 'label_transition_development',
		isSelected: false,
		isComplted: false
		
	},
	{
		key: 'launch',
		name: 'label_transition_deploy',
		isSelected: false,
		isComplted: false
	}
];

const planningSteps = [
	{
		key: 'kyt',
		number: 1,
		status: 'todo',	//allowed values: todo, inprogress, completed
		title: 'label_slide_pl_title'
	},
	// {
	// 	key: 'kyc',
	// 	number: 2,
	// 	status: 'todo',
	// 	title: 'label_transition_kyc'
	// },
	{
		key: 'viewFeatures',
		number: 2,
		status: 'todo',
		title: 'label_slide_4_title'
	},
	// {
	// 	key: 'chosePrd',
	// 	number: 4,
	// 	status: 'todo',
	// 	title: 'label_transition_choose_prd'
	// }
];

const sprintSteps = [
	{
		key: 'sprintPlanning',
		number: 1,
		status: 'todo',
		title: 'label_transition_sprint_planning'
	},
	{
		key: 'sprintExecution',
		number: 2,
		status: 'todo',
		title: 'label_transition_sprint_execution'
	},
	{
		key: 'sprintLaunch',
		number: 3,
		status: 'todo',
		title: 'label_transition_sprint_launch'
	}
];

const sprintsSteps = [
	{
		key: "sprint1",
		sprintNumber: 1,
		title: "label_transition_sprint_label",
		isDisabled: true,
		steps: sprintSteps
	},
	{
		key: "sprint2",
		sprintNumber: 2,
		title: "label_transition_sprint_label",
		isDisabled: true,
		steps: sprintSteps
	},
	{
		key: "sprint3",
		sprintNumber: 3,
		title: "label_transition_sprint_label",
		isDisabled: true,
		steps: sprintSteps
	},
	{
		key: "sprint4",
		sprintNumber: 4,
		title: "label_transition_sprint_label",
		isDisabled: true,
		steps: sprintSteps
	},
	{
		key: "sprint5",
		sprintNumber: 5,
		title: "label_transition_sprint_label",
		isDisabled: true,
		steps: sprintSteps
	}
];

export {
	walkthroughTabsList,
	planningSteps,
	sprintsSteps
};