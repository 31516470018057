import React, { useState } from 'react';
import styles from './sprintReport.module.sass';
import getSkin from './skin.js';
import applyWrappers from 'wrappers/ComponentWrapper';
import Tabs from './tabs/index';
import Metrics from './metric/Metric';
import Stories from './stories/'
import Comments from "./cust/";
import Completion from "./completion/";
import { css } from 'aphrodite/no-important';
import GameComplete from 'svgComponents/gameComplete';
import SprintFooter from '../sprintFooter/SprintFooter.jsx';
import WalkthroughTabs from 'commonComponents/walkthrough/walkthroughTabs';
import { walkthroughTabsList } from 'config/walkthrough';
import {
	showFeedbackFormWithoutPopup,
} from 'util/feedback';
import HollowButton from 'commonComponents/buttons/hollowButton';
import FilledButton from 'commonComponents/buttons/filledButton';
import { getUserMetricsForMetricsKey } from 'util/utilFunctions';
import History from 'components/metricsHeader/history';
import InfoComponent from 'commonComponents/infoComponent';
import SendEmailReport from 'commonComponents/sendEmailReport';
import analyticsUtil from 'util/segmentUtil';
import GameIncomplete from '../../../../svgComponents/gameIncomplete/GameIncomplete.jsx';

const downloadReport = (props) => {
	//Segment integration
	analyticsUtil.track('Report_Download', props.userDetails);
	props.setIsDownloading(true);
	props.fetchReportLink();
}

const SprintReport = (props) => {
	console.log("tutorial", props.tutorial);

	const myStyles = getSkin(props.skinGuide);
	const {
		userState,
		metrics,
		userSprintReports,
		getLabel,
		stories,
		report,
		fromPopup,
		customers
	} = props;
	const { isGameCompleted } = userState;

	// console.log(isGameCompleted)

	const [selected, setSelected] = useState(!fromPopup ? props.userState.currentSprintNumber : 1);

	const renderWalkthroughTabs = () => {
		if (isGameCompleted) {
			return (
				<div styleName="walkthrough-tabs-container">
					<WalkthroughTabs walkthroughTabsList={updatedWalkthroughTabsList}/>
				</div>
			);
		}
		return null;
	}

	const disableFooterCondition = () => {
        return (
            props.userState.isGameCompleted === true
            || props.userState.daysCompleted === true
            || props.userState.timeCompleted === true
			|| fromPopup === true
        );
    }

	const setFeedbackCompleted = () => {
		props.setUserState({
			isFeedbackSubmitInProgress: false,
			isKfeedbackSubmitted: true
		});
	}

	const onClickFeedback = () => {
		props.setUserState({
			isFeedbackSubmitInProgress: true
		});
		showFeedbackFormWithoutPopup(setFeedbackCompleted);
	}

	let sprintReport = userSprintReports.sprintReportList.find((eachReport) => {
		return eachReport.sprintNumber === selected;
	});

	const mvpUserMetrics = getUserMetricsForMetricsKey(
		props.metrics.metricsList,
		props.userMetrics.userMetricsList,
		'MVP'
	);
	const skillUserMetrics = getUserMetricsForMetricsKey(
		props.metrics.metricsList,
		sprintReport.reportMetrics,
		'SKILL'
	);
	const moraleUserMetrics = getUserMetricsForMetricsKey(
		props.metrics.metricsList,
		sprintReport.reportMetrics,
		'MORALE'
	);
	const csUserMetrics = getUserMetricsForMetricsKey(
		props.metrics.metricsList,
		sprintReport.reportMetrics,
		'CS'
	);

	const skillChangeInMetric = Math.round(skillUserMetrics.changeInMetricValue);
	const moraleChangeInMetric = Math.round(moraleUserMetrics.changeInMetricValue);
	const csChangeInMetric = Math.round(csUserMetrics.changeInMetricValue);

	const skillChange = skillChangeInMetric >=0 ? skillChangeInMetric == 0 ? 'neutral' :'positive' : 'negative';
	const moraleChange = moraleChangeInMetric >=0 ? moraleChangeInMetric == 0 ? 'neutral' :'positive' : 'negative';
	const csChange = csChangeInMetric >=0 ? csChangeInMetric == 0 ? 'neutral' :'positive' : 'negative';

	const skillChangeLabel = `label_report_speak_sprint_${selected}_ts_change_${skillChange}`;
	const moraleChangeLabel = `label_report_speak_sprint_${selected}_tm_change_${moraleChange}`;
	const csChangeLabel = `label_report_speak_sprint_${selected}_cs_change_${csChange}`;



	const updatedWalkthroughTabsList = walkthroughTabsList.map(walkthroughTab => {
		// if (walkthroughTab.key === 'launch') {
		// 	return {
		// 		...walkthroughTab,
		// 		isSelected: true,
		// 		isCompleted: false
		// 	};
		// }
		return {
			...walkthroughTab,
			isSelected: false,
			isCompleted: true
		};
	});


	if (userSprintReports.sprintReportList.length === 0) {
		return (
			<div styleName="master" 
			// style={
			// 	{
			// 		'height': `calc(100% - ${isGameCompleted || fromPopup ? 0 : 80}px)`
			// 	}
			// }
			>
				{ !fromPopup ?
				<div styleName="walkthrough-background">
					<img width="100%" height="100%" src={props.getImage('SIM_BACKGROUND')} alt="" 
					style={isGameCompleted ? null : { filter: "blur(4px)" }}/>
				</div>:
				null
				}
				<div styleName="sprint-report-container">
					<div styleName={fromPopup ? "sprint-report-popup-content-container" : "sprint-report-content-container"}>
						{renderWalkthroughTabs()}
						{
							isGameCompleted
								? <div styleName="sprint-report-component game-completed">
									<div styleName="img">
										{
											mvpUserMetrics.value == 100
												? <GameComplete/>
												: <GameIncomplete/>
										}
									</div>
									<div styleName="content">
										<div styleName="title" className={css(myStyles.title)}>
											{
												mvpUserMetrics.value === 100
													? getLabel("label_report_congrats_mvp_achieved")
													: getLabel("label_report_congrats_days_over")
											}
										</div>
										<div styleName="ms" className={css(myStyles.ms)}>
											{
												mvpUserMetrics.value === 100
													? getLabel("label_report_productcustfit_achieved")
													: getLabel("label_report_days_over")
											}
										</div>

										{!props.userState.isVacEnabled ?
											<div styleName="buttons-container">
												{/* {
													props.user.isKfeedbackInitialized === true && props.user.isKfeedbackSubmitted === false
														? <div styleName="single-button-container">
															<FilledButton
																textLabel={props.user.isFeedbackSubmitInProgress ? props.getLabel('label_give_feedback_loading') : props.getLabel('label_give_feedback')}
																clickFunction={props.user.isFeedbackSubmitInProgress ? null : onClickFeedback}
																disableButton={props.user.isFeedbackSubmitInProgress}
																removeShadow={true}
															// buttonStyle={{

															// }}
															// buttonTextStyle={{

															// }}
															/>
														</div>
														: null
												} */}
												<div styleName="single-button-container">
													<FilledButton
														textLabel={report.isReportDownloading ? getLabel("label_report_downloading") : getLabel("label_report_download")}
														clickFunction={report.isReportDownloading ? null : () => { downloadReport(props) }}
														disableButton={props.user.isKfeedbackSubmitted === false || report.isReportDownloading}
														removeShadow={true}
													/>
													{/* {
														props.user.isKfeedbackSubmitted === true
															? null
															: <div className={css(myStyles.helpTextReport)} styleName="help-text-report">{props.getLabel('label_provide_feedback_for_report')}</div>
													} */}
												</div>
												{
													props.user.isKfeedbackSubmitted === true
														? <SendEmailReport {...props} />
														: null
												}
											</div>
											: null}

									</div>
									<div styleName="footer-svg1">
										<svg  viewBox="0 0 1206 74" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path opacity="0.1" d="M0 74.1999V0C0 0 326.31 76.3913 735.462 28C1144.61 -20.3913 1376.46 74.1999 1376.46 74.1999H0Z" fill="#972082" />
										</svg>
									</div>
									<div styleName="footer-svg2">
										<svg viewBox="0 0 766 74" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path opacity="0.1" d="M0.462891 73.6622V0C0.462891 0 128.463 87.5 326.963 22C525.463 -43.5 765.582 73.6622 765.582 73.6622H0.462891Z" fill="#972082" />
										</svg>
									</div>
									<div styleName="footer-svg3">
										<svg width="17.716vw" height="9.635vh"  viewBox="0 0 242 74" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path opacity="0.1" d="M765.582 73.6622V0C765.582 0 637.582 87.5 439.082 22C240.582 -43.5 0.462524 73.6622 0.462524 73.6622H765.582Z" fill="#972082" />
										</svg>
									</div>
								</div>
								: null
						}
					</div>
					{disableFooterCondition() ? null : <SprintFooter {...props} />}
				</div>
			</div>
		);
	}

	var totalSprints
	if (fromPopup) {
		totalSprints = props.userState.currentSprintNumber - 1
	} else {
		totalSprints = props.userState.currentSprintNumber
	}


	if (!sprintReport) {
		let len = userSprintReports.sprintReportList.length
		sprintReport = userSprintReports.sprintReportList[len - 1]
		setSelected(sprintReport.sprintNumber)
	}

	// const messagesReport = sprintReport.reportSpeak.reportSpeakMessageArray.map((block) => getLabel(block.metricMessage));

	const reportStories = sprintReport.reportStories;

	const sprinStories = reportStories.map((us) => {
		const st = stories.storyList.find((str) => str.id === us.storyId);
		return { ...st, ...us }
	});

	const notCompletedSprintStories = sprinStories.filter((story) => story.storyStatus != 4);

	return (
		<div styleName="master" 
		// style={
		// 	{
		// 		'height': `calc(100% - ${isGameCompleted || fromPopup ? 0 : 80}px)`
		// 	}
		// }
		>
			{ !fromPopup ?
			<div styleName="walkthrough-background">
				<img width="100%" height="100%" src={props.getImage('SIM_BACKGROUND')} alt="" 
				style={isGameCompleted ? null : { filter: "blur(4px)" }}/>
			</div>:
			null
			}
			<div styleName="sprint-report-container"
			style={fromPopup ? {background:"white"}:null}>
				<div styleName={fromPopup ? "sprint-report-popup-content-container" : "sprint-report-content-container"}>
					{renderWalkthroughTabs()}
					{
						isGameCompleted
							? <div styleName="sprint-report-component game-completed">
								<div styleName="img">
									{
										mvpUserMetrics.value == 100
											? <GameComplete/>
											: <GameIncomplete/>
									}
								</div>
								<div styleName="content">
									<div styleName="title" className={css(myStyles.title)}>
										{
											mvpUserMetrics.value == 100
												? getLabel("label_report_congrats_mvp_achieved")
												: getLabel("label_report_congrats_days_over")
										}
									</div>
									<div styleName="ms" className={css(myStyles.ms)}>
										{
											mvpUserMetrics.value == 100
												? getLabel("label_report_productcustfit_achieved")
												: getLabel("label_report_days_over")
										}
									</div>

									<div styleName="buttons-container">
										{/* {
											props.userState.isFeedbackEnabled == true && props.user.isKfeedbackInitialized == true && props.user.isKfeedbackSubmitted == false
												? <div styleName="single-button-container">
													<FilledButton
														textLabel={props.user.isFeedbackSubmitInProgress ? props.getLabel('label_give_feedback_loading') : props.getLabel('label_give_feedback')}
														clickFunction={props.user.isFeedbackSubmitInProgress ? null : onClickFeedback}
														disableButton={props.user.isFeedbackSubmitInProgress}
														removeShadow={true}
														buttonStyle={{
															background: 'transparent',
															borderColor: 'white'
														}}
														buttonTextStyle={{
															fontWeight: 600,
															color: 'white'
														}}
													/>
												</div>
												: null
										} */}
										{
											props.userState.isFinalReportEnabled
												? <div styleName="single-button-container">
													<FilledButton
														textLabel={report.isReportDownloading ? getLabel("label_report_downloading") : getLabel("label_report_download")}
														clickFunction={report.isReportDownloading ? null : () => { downloadReport(props) }}
														disableButton={(props.userState.isFeedbackEnabled == true && props.user.isKfeedbackSubmitted == false) || report.isReportDownloading}
														removeShadow={true}
													/>
													{/* {
														props.user.isKfeedbackSubmitted == true || props.userState.isFeedbackEnabled == false
															? null
															: <div className={css(myStyles.helpTextReport)} styleName="help-text-report">{props.getLabel('label_provide_feedback_for_report')}</div>
													} */}
												</div>
												: null
										}

										{
											props.userState.isFinalReportEnabled && (props.user.isKfeedbackSubmitted === true || props.userState.isFeedbackEnabled == false)
												? <SendEmailReport {...props} />
												: null
										}
									</div>
								</div>
								<div styleName="footer-svg1">
									<svg viewBox="0 0 1206 74" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path opacity="0.1" d="M0 74.1999V0C0 0 326.31 76.3913 735.462 28C1144.61 -20.3913 1376.46 74.1999 1376.46 74.1999H0Z" fill="#972082" />
									</svg>
								</div>
								<div styleName="footer-svg2">
									<svg viewBox="0 0 766 74" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path opacity="0.1" d="M0.462891 73.6622V0C0.462891 0 128.463 87.5 326.963 22C525.463 -43.5 765.582 73.6622 765.582 73.6622H0.462891Z" fill="#972082" />
									</svg>
								</div>
								<div styleName="footer-svg3">
									<svg width="17.716vw" height="9.635vh" viewBox="0 0 242 74" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path opacity="0.1" d="M765.582 73.6622V0C765.582 0 637.582 87.5 439.082 22C240.582 -43.5 0.462524 73.6622 0.462524 73.6622H765.582Z" fill="#972082" />
									</svg>
								</div>
							</div>
							: null
					}
					<div styleName={isGameCompleted ? "game-completion-wrappper" : null}>
						{isGameCompleted ?
							<div styleName="walkthrough-background">
								<img width="100%" height="100%" src={props.getImage('SIM_BACKGROUND_BLUR')} alt="" />
							</div>
							: null}
						<div styleName={fromPopup ? "tabs-popup" : "tabs"} className={fromPopup ? css(myStyles.tabsPopup) : css(myStyles.tabs)}
						style={isGameCompleted ? {borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}: null}
						>
							<Tabs sprintCount={totalSprints} selected={selected} setSelected={setSelected} getLabel={getLabel} fromPopup={fromPopup}/>
							{
								!fromPopup
									? <History {...props} height="40px" />
									: null
							}
						</div>
						<div styleName="text" className={css(myStyles.text)}>
							<div className={css(myStyles.textHeading)}>{`${getLabel('label_sprint_report_main_heading')} Sprint ${selected}!`}</div>
							<div className={css(myStyles.textPara)}>
								{`Sprint ${selected} ${getLabel('label_report_speak_heading')} ${props.getLabel(csChangeLabel)} ${props.getLabel(skillChangeLabel)} ${props.getLabel(moraleChangeLabel)}`}
							</div>
						</div>

						<div styleName="info">
							<div styleName="metrics"
							style={fromPopup ? null : {borderRight: '1px solid #989898'}}>
								<div styleName="met-name" className={fromPopup?css(myStyles.metNamePopup):css(myStyles.metName)}>{getLabel("label_report_metrics")}</div>
								<Metrics sprintReport={sprintReport} userMetrics={sprintReport.reportMetrics} userState={userState} metrics={metrics} selected={selected} getLabel={getLabel} fromPopup={fromPopup}/>
							</div>
							{/* {fromPopup ? null :<div className={css(myStyles.verticalBar)} styleName='vertical-bar'></div>} */}
							<div styleName="task"
							style={fromPopup ? null : {borderLeft: '1px solid #989898'}}>
								<div styleName="task-row">
									<div styleName="task-graph unfinshed-task oa">
										<div styleName="heading" className={fromPopup?css(myStyles.metNamePopup):css(myStyles.metName)}>
											{getLabel("label_report_task_completion")}
										</div>
										<Completion sprinStories={sprinStories} getLabel={getLabel} fromPopup={fromPopup}/>
									</div>
									{
										notCompletedSprintStories.length > 0
											? <div styleName="unfinshed-task">
												<div styleName="heading" className={fromPopup?css(myStyles.metNamePopup):css(myStyles.metName)}>
													<div styleName="heading-text">
														{getLabel("label_report_unfinished_tasks")}
													</div>
													{/* <InfoComponent
														tooltipText={props.getLabel('label_unfinished_tasks_help_text')}
														alignTooltipKey="BOTTOM"
														svgColor={props.skinGuide.globalProfiles.palette.grayColor4}
													/> */}
												</div>

												<Stories sprinStories={notCompletedSprintStories} getLabel={getLabel} {...props} />
											</div>
											: null
									}
								</div>

								{/* <div styleName="customers-speak">
									<Comments sprintReport={sprintReport}
										selected={selected} getLabel={getLabel} customers={customers} />
								</div> */}
							</div>
						</div>
					</div>
				</div>
				{disableFooterCondition() ? null : <SprintFooter {...props} />}
			</div>
		</div>
	);
}

export default applyWrappers(SprintReport, styles);