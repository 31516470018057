import React from "react";
import styles from "./sprintWisePlan.module.sass";
import applyWrappers from "wrappers/ComponentWrapper";
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import {checkIfPresent} from 'util/utilFunctions'
import Should from "../../../svgComponents/should/Should.jsx";
import Must from "../../../svgComponents/must/Must.jsx";
import Could from "../../../svgComponents/could/Could.jsx";
import InfoComponent from 'commonComponents/infoComponent';
import FeatureRiver from "../../../svgComponents/featureRiver/featureRiver.jsx";
import FeatureSwim from "../../../svgComponents/featureSwim/featureSwim.jsx";
import FeatureSafety from "../../../svgComponents/featureSafety/featureSafety.jsx";
import FeatureOther from "../../../svgComponents/featureOther/featureOther.jsx";
import FeatureLounging from "../../../svgComponents/featureLounging/featureLounging.jsx";


const priorityMappings = {
    label_must: {
        image: <Must/>,
        text: 'label_must',
        backgroundColor: 'mustColor',
        textColor: '#FFFFFF'
    },
    label_should: {
        image: <Should/>,
        text: 'label_should',
        backgroundColor: 'shouldColor',
        textColor: '#FFFFFF'
    },
    label_could: {
        image: <Could/>,
        text: 'label_could',
        backgroundColor: 'couldColor',
        textColor: '#000000'
    }
};
const renderStoryCard = props => {
    const { priorityTag } = props.storyDetails;
    const priorityInfo = priorityMappings[priorityTag] || {};
    const renderFeatureImage = (feature) => {
        switch (feature.imageKey) {
            case 'IMG_FEATURE_RP':
                return <FeatureRiver />;
            case 'IMG_FEATURE_SB':
                return <FeatureSwim />;
            case 'IMG_FEATURE_SS':
                return <FeatureSafety />;
            case 'IMG_FEATURE_LA':
                return <FeatureLounging />;
            case 'IMG_FEATURE_OF':
                return <FeatureOther />;
            default:
                return <img alt="default" src={props.getImage('IMG_DEFAULT_FEATURE')} />;;
        }
    } 
    const priorityBackgroundColor = props.skinGuide.globalProfiles.palette[priorityInfo.backgroundColor];
    const featureId = props.storyDetails.featureId;
    const feature = props.features.featuresList.find(feature => feature.featureId === featureId);
    // const feature = props.features.featuresList[featureId-10];  //hardcoded this. might have to change later.
    const myStyles = getSkin(props.skinGuide);
    return (
    <div styleName="card-container" className={css(myStyles.cardContainer)}>
        <div styleName="card-tag" className={css(myStyles.cardTag)}>
            <div styleName="card-tag-image">
                {renderFeatureImage(feature)}
            </div>
            <div styleName="card-tag-text" className={css(myStyles.cardTagText)}>
                {props.getLabel(feature.name)}
            </div>
        </div>
        <div styleName="card-content" className={css(myStyles.cardContent)}>
            <div styleName="card-header">
                {props.getLabel(props.storyDetails.name)}
            </div>
            <div styleName="card-description" className={css(myStyles.cardDescription)}>
                {props.getLabel(props.storyDetails.description)}
            </div>
        </div>
        <div styleName="card-bottom-container">
            <div styleName="story-point-container" className={css(myStyles.storyPointContainer)}>
                <div styleName="story-point-text">
                    Story Points |
                </div>
                <div styleName="story-point-number">
                    {props.storyDetails.storyPoint}
                </div>
                <div styleName="story-point-icon">
                <InfoComponent
                    tooltipText={props.getLabel('label_tooltip_storypoints')}
                    alignTooltipKey="TOP"
                    svgColor="#333333"
                />
                </div>
            </div>
            <div
            styleName="priority-container" className={css(myStyles.priorityContainer)}
            style={{backgroundColor: priorityBackgroundColor ,
                    color: priorityInfo.textColor
                    }}
            >
                <div styleName="priority-image-container">
                    {/* <img styleName="priority-image" src={props.getImage(priorityInfo.image)}></img> */}
                    {priorityInfo.image}
                </div>
                <div styleName="priority-text">
                    {props.getLabel(priorityInfo.text)}
                </div>
            </div>
        </div>
    </div>
);
};
export default applyWrappers(renderStoryCard, styles);