import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from '../../../../defaults/skins';

const getSkin = (theme) => {
	const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

	return StyleSheet.create({
		tabContainer: {
			...myTypography.title,
			lineHeight: 'normal',
			fontWeight: 'bold',
			fontSize: '1em',
			color: skin.black,
			background: skin.white,
			fontFamily: skins.fontFamily.bodyFamily,
		},
		selectedTabContainer: {
			...myTypography.title,
			fontWeight: 'bold',
			lineHeight: 'normal',
			fontWeight: '700',
			fontSize: '1em',
			color: skin.orange,
			background: skin.white,
			border: `2px solid ${skin.orange}`,
			fontFamily: skins.fontFamily.bodyFamily,
		},
		tabContainerNotVisited: {
			...myTypography.title,
			fontWeight: 'bold',
			lineHeight: 'normal',
			fontSize: '1em',
			color: skin.black,
			background: skin.white,
			fontFamily: skins.fontFamily.bodyFamily,
		},
		symbolContainer: {
			border: `2px solid ${skin.black}`,
			borderRadius: '50%'
		},
		selectedSymbolContainer: {
			border: `2px solid ${skin.orange}`,
			borderRadius: '50%',
		}
	});
}

export default getSkin;