import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
// import { hexToRgbA } from 'util/styleUtil';

const getSkin = (theme) => {
	const skin = theme.globalProfiles.palette;
	const myTypography = typography(theme);

	return StyleSheet.create({
		historyContent: {
			border: `1.5px solid ${skin.orange}`,
			borderRadius: '4px',
			background: '#EE774866'

		},
		historyName: {
			...myTypography.body1,
			fontSize: '16px',
			fontWeight: 700,
			textTransform: 'uppercase',
			color: skin.white
		},
	});
}

export default getSkin;