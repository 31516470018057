import React from 'react';
import styles from './close.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const Close = (props) => {
    const skin = props.skinGuide.globalProfiles.palette
    const color = props.backgroundColor || skin.white;

    return (
        <div styleName="main-component">
            <svg viewBox="0 0 88 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_1076_260881)">
                <rect x="4" width="80" height="32" rx="4" fill="#EE7748" fill-opacity="0.5" shape-rendering="crispEdges"/>
                <rect x="5" y="1" width="78" height="30" rx="3" stroke="#EE7748" stroke-width="2" shape-rendering="crispEdges"/>
                <path d="M29.328 12.512L28.56 14.64C27.808 14.192 26.928 13.92 26.032 13.92C24.304 13.92 23.184 15.088 23.184 16.976C23.184 18.88 24.24 20.08 26.048 20.08C27.024 20.08 27.936 19.76 28.688 19.264L29.216 21.52C28.432 21.952 27.104 22.24 25.936 22.24C22.736 22.24 20.624 20.4 20.624 17.04C20.624 13.808 22.72 11.76 26.016 11.76C27.232 11.76 28.416 12.064 29.328 12.512ZM37.5885 22H30.8525V12H33.3965V19.872H37.5885V22ZM48.5728 16.96C48.5728 20.128 46.2847 22.24 43.3088 22.24C40.3488 22.24 38.0928 20.4 38.0928 17.024C38.0928 13.856 40.3648 11.76 43.3408 11.76C46.3168 11.76 48.5728 13.584 48.5728 16.96ZM46.0128 17.024C46.0128 15.104 45.0048 13.808 43.3088 13.808C41.6128 13.808 40.6528 15.088 40.6528 16.96C40.6528 18.88 41.6448 20.192 43.3568 20.192C45.0368 20.192 46.0128 18.896 46.0128 17.024ZM53.5711 20.144C54.4671 20.144 55.0911 19.808 55.0911 19.2C55.0911 18.528 54.3711 18.304 53.0431 17.936C51.5871 17.536 49.8271 16.864 49.8271 14.864C49.8271 13.008 51.1871 11.776 53.6671 11.776C55.2351 11.776 56.4511 12.128 57.4111 12.656L56.9151 14.752C55.9231 14.224 54.6751 13.872 53.6831 13.872C52.8031 13.872 52.3711 14.176 52.3711 14.72C52.3711 15.376 53.2831 15.632 54.4831 15.968C56.0191 16.384 57.6191 17.088 57.6191 18.992C57.6191 20.896 56.1471 22.24 53.5231 22.24C52.0351 22.24 50.5951 21.856 49.6671 21.328L50.1471 19.216C51.1391 19.744 52.3391 20.144 53.5711 20.144ZM66.5864 22H59.2744V12H66.5864V14.048H61.7704V15.968H65.6424V18H61.7704V19.952H66.5864V22Z" fill="white"/>
                </g>
                <defs>
                <filter id="filter0_d_1076_260881" x="0" y="0" width="88" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1076_260881"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1076_260881" result="shape"/>
                </filter>
                </defs>
            </svg>
        </div>
    );
}



export default applyWrappers(Close, styles);
