import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography'

const getSkin = (theme) => {
	const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

	return StyleSheet.create({
		buttonStyle: {
			backgroundColor: `${hexToRgbA(skin.orange, 0.5)}`,
			border: `2px solid ${hexToRgbA(skin.orange, 1)}`,
			...myTypography.body1,
			fontWeight: 600,
			fontSize: '1em',
			height: "4.167vh",
			width: "5.857vw"
		},
		buttonStyleDisabled: {
			backgroundImage: `linear-gradient(to right, ${skin.grayColor4}, ${skin.grayColor4})`,
			boxShadow: `0 4px 4px 0 ${skin.grayColor5}`,
			':hover': {
				backgroundImage: skin.primaryVariantColor
			},
			...myTypography.body1,
			fontWeight: 600,
			fontSize: '1.25em'
		},
		buttonStyleWithoutShadow: {
			backgroundImage: `linear-gradient(to right, ${skin.primaryColor}, ${skin.secondaryColor})`,
			...myTypography.body1,
			fontWeight: 600,
			fontSize: '1.25em'
		},
		buttonStyleWithoutShadowDisabled: {
			backgroundImage: `linear-gradient(to right, ${skin.grayColor4}, ${skin.grayColor4})`,
			...myTypography.body1,
			fontWeight: 600,
			fontSize: '1.25em'
		},
		buttonText: {
			...myTypography.button,
			color: skin.white,
			fontSize: '1em'
		}
	});
}

export default getSkin;