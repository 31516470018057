import React, { useState, useRef } from 'react';
import styles from './introPopup.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import ClosedButton from '../../../commonComponents/buttons/closedButton/ClosedButton.jsx';
// import PlayingConditionsBox from 'components/intro/playingConditions/playingConditionsBox';
import analyticsUtil from 'util/segmentUtil';
import FilledButton from 'commonComponents/buttons/filledButton';
import StoryCard from 'components/intro/sprintWisePlan/StoryCard';

const IntroPopup = (props) => {
	const [currentPage, setCurrentPage] = useState(1);
	const totalSprints = props.userState.totalSprints;

	const closePopup = () => {
		//Segment integration
		analyticsUtil.track('POPUP_CLOSED', {
			metrics: props.userDetails,
			popup: "INTRO"
		});
		props.setUiState({
			showOverlay: false,
			showOverlayOverWalkthrough: false,
			overlayComponentType: '',
			highlightDesktopHeader: false,
			highlightMetricsHeader: false,
			overlayComponent: ''
		});
	}

	const goToNextPage = () => {
		console.log("currentPage", currentPage);
		setCurrentPage((prevPage) => prevPage + 1);
	};

	const goToPreviousPage = () => {
		console.log("currentPage", currentPage);
		setCurrentPage((prevPage) => prevPage - 1);
	};

	const renderPageContent = () => {
		switch (currentPage) {
			case 1:
				return (
					<div styleName='slide-intro'>
						<div
							className={css(myStyles.transcriptText)}
							dangerouslySetInnerHTML={{
								__html: props.getLabel("label_slide_1_desc", "", {
									num_of_sprints: props.userState.totalSprints,
									num_of_stories: props.userStories.totalStories
								})
							}}
						></div>
						<div styleName='button-container'>
							<FilledButton
								textLabel={props.getLabel("label_next")}
								clickFunction={goToNextPage}
							/>
						</div>

					</div>
				);
			case 2:
				return (
					<div>
						<div styleName="intro-content-container">
							<div styleName="sprint-story-container">
								{Array(totalSprints)
									.fill()
									.map((val, index) => {
										return (
											<div
												styleName="sprint-plan-sprint-story-container"
												className={css(myStyles.sprintContainer)}
											>
												<div styleName="sprint-number" className={css(myStyles.sprintNumber)}>
													Sprint 0{index + 1}
												</div>
												{Object.values(props.userStories.userStoriesSprintWise).map(
													(sprintStories, ind) => {
														if (ind == index) {
															return (
																<div key={index} styleName='sprint-stories-container'>
																	{sprintStories.map((eachUserStory, storyIndex) => {

																		const storyDetails = props.stories.storyList.find(
																			eachStory => {
																				return (
																					eachUserStory.id ===
																					eachStory.id
																				);
																			}
																		);
																		return (																			<div key={eachUserStory.sprintNumber} styleName='sprint-story'>
																				<StoryCard
																					storyDetails={
																						eachUserStory
																					}
																					index={index}
																					{...props}
																				/>
																			</div>
																		);

																	})}
																</div>
															);
															const storyDetails = [];
														}
													}
												)}
											</div>
										);
									})}
							</div>

						</div>
						<div styleName='button-container2'>
							<FilledButton
								textLabel={props.getLabel("label_back")}
								clickFunction={goToPreviousPage}
							/>
						</div>

					</div>
				);
			default:
				return null;
		}
	};


	const myStyles = getSkin(props.skinGuide);
	const skin = props.skinGuide.globalProfiles.palette;
	const containerClass = currentPage === 1 ? 'intro-popup-container2' : 'intro-popup-container';
	const headerLabel = currentPage === 1 ? props.getLabel('label_slide_1_title') : props.getLabel('label_slide_2_title');

	return (
		<div styleName={containerClass} className={css(myStyles.introPopupContainer)}>
			<div styleName="header-container">
				<div className={css(myStyles.headerBackground)} styleName="header-background-container">
					<div styleName='header-content'>
						<div className={css(myStyles.title)} styleName="title">
							{headerLabel}
						</div>
						<div styleName="close-icon" onClick={closePopup}>
							<ClosedButton
								textLabel={props.getLabel('label_close_button')} />
						</div>
					</div>
				</div>
			</div>
			<div styleName="content">
				<div styleName="intro-content-container"  >
					{renderPageContent()}
				</div>
			</div>
		</div>
	);
}

export default applyWrappers(IntroPopup, styles);