import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		leaderboardPopupContainer: {
			background: skin.white,
			overflow: 'hidden',
			borderRadius: '8px',
			fontFamily: 'freight-sans-pro',
		},
		headerContainer: {
			borderTopLeftRadius: '8px',
			borderTopRightRadius: '8px',
		},
		headerBackground: {
            background: `linear-gradient(90deg, #972082 0%, #4B1C40 100%)`,
		},
		title: {
			fontFamily: skins.fontFamily.titleFamily,
			lineHeight: '2em',
			fontWeight: 600,
			color: skin.white
		},
		headerTitle: {
			...myTypography.body1,
			fontSize: '12px',
			fontWeight: 600,
			color: skin.black
		},
		value: {
			...myTypography.body1,
			fontSize: '14px',
			fontWeight: 600,
			color: hexToRgbA(skin.grayColor1, 0.8)
		},
		currentUserValue: {
			...myTypography.body1,
			fontSize: '16px',
			fontWeight: 'bold',
			color: skin.primaryColor
		},
		seperationLine: {
			background: hexToRgbA('#407EA6', 0.1)
		},
		currentUser: {
			background: `linear-gradient(90deg, rgba(66, 186, 255, 0.5) 0%, rgba(66, 186, 255, 0) 100%)`
		},
		refreshContainer: {
			color: skin.black,
		}
	});
}

export default getSkin;