import React from 'react';
import styles from './confirmLogout.module.sass';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import FilledButton from '../../../../commonComponents/buttons/filledButton';
import getSkin from './skin.js';
import LogoutArrow from '../../../../svgComponents/logout/LogoutArrow.jsx';
import { getUserName } from 'util/utilFunctions';

const ConfirmLogout = ({ onConfirm, onCancel, getLabel, skinGuide, ...props }) => {
	const myStyles = getSkin(skinGuide);
	const skin = skinGuide.globalProfiles.palette;
	return (
		<div styleName="modalOverlay">
			<div styleName="modalContent">
				<div styleName="header">
					<div styleName="iconContainer">
						<LogoutArrow />
					</div>
					<div className={css(myStyles.headerSure)}
						dangerouslySetInnerHTML={{
							__html: getLabel("label_confirm", "", {
								user_name: (getUserName(props.userDetails))
							})
						}}
					></div>
				</div>
				<div styleName="body">
					<p className={css(myStyles.desc)}>
						{getLabel("label_logout_desc")}
					</p>
					<div styleName="modalActions">
						<FilledButton
							textLabel={getLabel("label_cancel")}
							clickFunction={onCancel}
							customStyle={{
								textTransform: "uppercase",
								borderRadius: "4px",
								fontSize: "1em",
								height: "5vh",
								width: "10vw"
							}}
						/>
						<FilledButton
							textLabel={
								props.userState.returnType.toLowerCase() === "normal"
									? getLabel("label_sign_out")
									: getLabel("label_return")
							}
							clickFunction={onConfirm}
							customStyle={{
								textTransform: "uppercase",
								borderRadius: "4px",
								fontSize: "1em",
								height: "5vh",
								width: "10vw"
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default applyWrappers(ConfirmLogout, styles);
