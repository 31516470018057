import React from 'react';
import styles from './userProfile.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';
import { checkIfPresent } from 'util/utilFunctions';

const UserProfile = (props) => {
    const skin = props.skinGuide.globalProfiles.palette
    let color = skin.orange;
    if (checkIfPresent(props.svgColor)) {
        color = props.svgColor;
    }
    return (
        <div styleName="main-component">
            <svg width="38" height="38" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="16" fill="#EE7748" />
                <mask id="mask0_774_6812" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
                    <circle cx="16" cy="16" r="16" fill="white" />
                </mask>
                <g mask="url(#mask0_774_6812)">
                    <ellipse cx="15.9999" cy="11.6004" rx="5.6" ry="5.2" fill="white" />
                    <circle cx="16.3998" cy="30.8002" r="11.6" fill="white" />
                </g>
            </svg>
        </div>
    );
}



export default applyWrappers(UserProfile, styles);
