import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins';

const getSkin = (theme) => {
	const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
        descriptionStepsContainer: {
            boxShadow: `0px 4px 4px 0px ${hexToRgbA(skin.black, 0.1)}`,
            backgroundColor: 'rgba(0,0,0,0.6)',
            borderRadius: "8px",
			border: skin.primaryColor,
        
        },
        description: {
            ...myTypography.body1,
            fontWeight: 'normal',
            fontSize: '1em',
            lineHeight: '1.25em',
            color: skin.white,
            fontFamily: skins.fontFamily.bodyFamily,
        },
        seperationLine: {
			background: skin.white
		}
    });
}
export default getSkin;