import React from 'react';
import styles from './upArrow.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';
import { checkIfPresent } from 'util/utilFunctions';

const UpArrow = (props) => {
    const skin = props.skinGuide.globalProfiles.palette
    let color = skin.black;
    if (checkIfPresent(props.svgColor)) {
        color = props.svgColor;
    }
    return (
        <div styleName="main-component">
       <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.33301 6.66642L7.99967 3.99976M7.99967 3.99976L10.6663 6.66642M7.99967 3.99976V12.6663" stroke="#0B7F3D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        </div>
    );
}



export default applyWrappers(UpArrow, styles);
