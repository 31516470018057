import { StyleSheet } from 'aphrodite';
// import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins'

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		historyItemContainer: {
			background: skin.white,
			border: `1px solid ${skin.grayColor5}`,
			borderRadius: '5px'
		},
		blockerClass: {
			border: `1px solid #D11021`,
			borderRadius: '8px',
			boxShadow: `0px 0px 14px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(219, 0, 0, 0.13)`
		},
		historyItemHeader: {
			borderBottom: `1px solid ${skin.grayColor4}`	
		},
		headerText: {
			fontFamily: skins.fontFamily.titleFamily,
			fontSize: '0.625em',
			fontWeigth: 600,
			lineHeight: 14
		},
		historyItemName: {
			...myTypography.body1,
			fontWeight: 600,
			fontSize: '0.875em'
		},
		historyItemDesc: {
			...myTypography.body2,
			fontSize: '0.75em',
			fontWeight: 350,
		},
		storyPointContainer: {
			fontFamily: skins.fontFamily.bodyFamily,
			fontWeight: 350,
			fontSize: '0.875em'
		},
		priorityContainer1: {
			fontFamily: skins.fontFamily.bodyFamily,
			fontWeight: 400,
			fontSize: '0.625em'
		}
	});
}

export default getSkin;