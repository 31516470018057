import React from 'react';
import styles from './newActions.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const ActionImage= (props) => {
    const color = props.color;
    const secondaryColor = props.secondaryColor || '#FF5B7F';
    let svgId = props.svgId || 'teamLunch';
    svgId += Math.random();

    let containerStyles = { };
    if (props.containerStyles) {
        containerStyles = {
            ...containerStyles,
            ...props.containerStyles
        }
    }


    return (
        <div style={containerStyles} styleName="main-component">
           <svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 14H8.625C8.75833 14 8.88767 13.975 9.013 13.925C9.13833 13.875 9.25067 13.8 9.35 13.7L14.05 9C14.2 8.85 14.3127 8.679 14.388 8.487C14.4633 8.295 14.5007 8.10767 14.5 7.925C14.4993 7.74233 14.4577 7.56333 14.375 7.388C14.2923 7.21267 14.184 7.05 14.05 6.9L13.15 5.95C13 5.8 12.8333 5.68767 12.65 5.613C12.4667 5.53833 12.275 5.50067 12.075 5.5C11.8917 5.5 11.7043 5.53767 11.513 5.613C11.3217 5.68833 11.1507 5.80067 11 5.95L6.3 10.65C6.2 10.75 6.125 10.8627 6.075 10.988C6.025 11.1133 6 11.2423 6 11.375V13C6 13.2833 6.096 13.521 6.288 13.713C6.48 13.905 6.71733 14.0007 7 14ZM7.5 12.5V11.55L10.025 9.025L10.525 9.475L10.975 9.975L8.45 12.5H7.5ZM10.525 9.475L10.975 9.975L10.025 9.025L10.525 9.475ZM11.175 14H17C17.2833 14 17.521 13.904 17.713 13.712C17.905 13.52 18.0007 13.2827 18 13C17.9993 12.7173 17.9033 12.48 17.712 12.288C17.5207 12.096 17.2833 12 17 12H13.175L11.175 14ZM6 18L3.7 20.3C3.38334 20.6167 3.02067 20.6877 2.612 20.513C2.20333 20.3383 1.99933 20.0257 2 19.575V4C2 3.45 2.196 2.97933 2.588 2.588C2.98 2.19667 3.45067 2.00067 4 2H20C20.55 2 21.021 2.196 21.413 2.588C21.805 2.98 22.0007 3.45067 22 4V16C22 16.55 21.8043 17.021 21.413 17.413C21.0217 17.805 20.5507 18.0007 20 18H6ZM5.15 16H20V4H4V17.125L5.15 16Z" fill={color}/>
</svg>
        </div>
    );
}



export default applyWrappers(ActionImage, styles);
