import { StyleSheet } from 'aphrodite';
// import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins'

const getSkin = (theme) => {
	const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

	return StyleSheet.create({
		currentContainer: {
			border: `2px solid ${skin.orange}`,
			backgroundColor: 'rgba(0, 0, 0, 0.7)'
		},
		beforeContainer: {
			backgroundColor: 'rgba(0, 0, 0, 0.7)'
		},
		afterContainer: {
			background: '#484747B2'
		},
		title: {
			fontFamily: skins.fontFamily.timerFamily,
			fontWeight: 600,
			lineHeight: '26px',
			fontSize: '1.25em',
			color: skin.black,
			backgroundColor: skin.grayColor8,
			borderRadius: '2px'
		}
	});
}

export default getSkin;