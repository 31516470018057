import React from 'react';
import styles from './walkthroughTab.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import CircleTick from '../../../../svgComponents/circleTick/CircleTick.jsx';
import HollowCircle from '../../../../svgComponents/hollowCircle/HollowCircle.jsx';
import FilledCircle from '../../../../svgComponents/filledCircle/FilledCircle.jsx';

const WalkthroughTab = (props) => {

	const myStyles = getSkin(props.skinGuide);
	let containerClass = myStyles.tabContainer;
	let symbolClass = myStyles.symbolContainer;
	let containerStyle = 'tab-container';

	if (props.isSelected || props.isCompleted) {
		containerClass = myStyles.selectedTabContainer;
		symbolClass = myStyles.selectedSymbolContainer;
		// containerStyle += ' selected-tab-container';
	} 
	// if (props.isSelected == false && props.showDottedLine == true) {
	// 	containerClass = myStyles.tabContainerNotVisited;
	// 	//symbolClass = myStyles.selectedSymbolContainer;
	// 	// containerStyle += ' selected-tab-container';
	// }


	return (
		<div
			className={css(containerClass)}
			styleName={containerStyle}
		>
			<div styleName="symbol-container"> 
				{
					props.isCompleted 
					? <CircleTick/>
					: props.isSelected 
					  ? <FilledCircle /> 
					  : <HollowCircle/> 
				}
			</div>
			<div styleName="tab-name">
				{props.getLabel(props.name)}
			</div>
		</div>
	);
}

export default applyWrappers(WalkthroughTab, styles);