import React from 'react';
import styles from './should.module.sass';
// import getSkin from './skin.js';
import applyWrappers from 'wrappers/ComponentWrapper';

const Skill = (props) => {

	// const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg  width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M3.2002 5.5998C3.2002 5.38763 3.28448 5.18415 3.43451 5.03412C3.58454 4.88409 3.78802 4.7998 4.0002 4.7998H12.0002C12.2124 4.7998 12.4159 4.88409 12.5659 5.03412C12.7159 5.18415 12.8002 5.38763 12.8002 5.5998C12.8002 5.81198 12.7159 6.01546 12.5659 6.16549C12.4159 6.31552 12.2124 6.3998 12.0002 6.3998H4.0002C3.78802 6.3998 3.58454 6.31552 3.43451 6.16549C3.28448 6.01546 3.2002 5.81198 3.2002 5.5998ZM3.2002 10.3998C3.2002 10.1876 3.28448 9.98415 3.43451 9.83412C3.58454 9.68409 3.78802 9.5998 4.0002 9.5998H12.0002C12.2124 9.5998 12.4159 9.68409 12.5659 9.83412C12.7159 9.98415 12.8002 10.1876 12.8002 10.3998C12.8002 10.612 12.7159 10.8155 12.5659 10.9655C12.4159 11.1155 12.2124 11.1998 12.0002 11.1998H4.0002C3.78802 11.1998 3.58454 11.1155 3.43451 10.9655C3.28448 10.8155 3.2002 10.612 3.2002 10.3998Z" fill="white" />
			</svg>
		</div>
	);
}

export default applyWrappers(Skill, styles);