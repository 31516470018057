import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
		storyPopupContainer: {
			background: skin.white,
			overflow: 'hidden',
			borderRadius: '8px'
		},
		headerBackground: {
			backgroundImage: `linear-gradient(90deg, #972082 0%, #4B1C40 100%);`,
		},
		storyName: {
			...myTypography.body1,
			fontFamily: skins.fontFamily.timerFamily,
			fontSize: '1.25em',
			fontWeight: 600,
			color: skin.white,
			lineHeight: '1.625em'
		},
		detailName: {
			color: hexToRgbA(skin.white, 1),
			fontFamily: skins.fontFamily.bodyFamily,
			fontSize: '0.875em',
			fontWeight: 350
		},
		detailValue: {
			color: hexToRgbA(skin.white, 1),
			fontFamily: skins.fontFamily.timerFamily,
			fontSize: '1em',
			fontWeight: 400
		},
		storyHistoryContainer: {
			
		},
		descHeading: {
			...myTypography.body1,
			fontWeight: 600,
			fontSize: '0.875em',
			color: skin.black,
			textTransform: 'uppercase'
		},
		descBlocker: {
			...myTypography.body1,
			fontWeight: 600,
			fontSize: '0.875em',
			color: 'rgba(0,0,0,0.8)',
			textTransform: 'uppercase'
		},
		descContent: {
			...myTypography.body1,
			fontWeight: 350,
			fontSize: '0.875em',
			color: skin.black,
		},
		featureDescHeading: {
			...myTypography.body1,
			fontSize: '1em',
			color: hexToRgbA(skin.primaryColor, 1),
			textTransform: 'uppercase'
		},
		featureDescContent: {
			...myTypography.body1,
			color: skin.grayColor2,
		},
		selectionContainer: {
			border: `1px solid ${skin.black}`,
			borderRadius: '2px'
		},
		selectContainer: {
			border: `1px solid #972082`,
			color: '#972082'
		},
		selectionBoxShadow: {
			// boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.09), 0px 3px 4px #309BE8`
		},
		selectionText: {
			...myTypography.body1,
			fontWeight: 600,
			fontSize: '0.75em',
		},
		storySelect: {
			border: `1px solid ${skin.black}`
		},
		storySelectText: {
			fontFamily: skins.fontFamily.bodyFamily,
			fontWeight: 600
		},
		cardTag :{
			backgroundColor: skin.grayColor8
		},
		cardTagText: {
			...myTypography.card_text
		},
		cardContent: {
			...myTypography.card_text
		},
		priorityText: {
			fontFamily : skins.fontFamily.bodyFamily,
			fontWeight: 400,
			fontSize: '0.625em'
		},
	});
}

export default getSkin;