const fontFamilyObj = {
	titleFamily: "freight-display-pro",
	bodyFamily: "freight-sans-pro",
	timerFamily: "freight-text-pro",
};

const globalProfiles = {
	'palette': {
		"primaryColor": "#232882",
		"primaryVariantColor": "#572676",
		"secondaryColor": "#FF1E50",
		"secondaryVariantColor": "#FF1E50",
		"grayColor1": "#031A20",
		"grayColor2": "#03313D",
		"grayColor3": "#02395A",
		"grayColor4": "#979eb7",
		"grayColor5": "#d5d9e7",
		"grayColor6": "#f2f3f7",
		"grayColor7": '#484747',
		"grayColor8": "#DADADA",
		"grayColor9": "#989898",
		"darkGrey": '#333333',
		"black": "#000000",
		"white": "#FFFFFF",
		"blue": '#42BAFF',
		"red": '#D63228',
		"pink": '#FD1E51',
		"pink2": '#FF5279',
		"yellow": '#FFD452',
		"green": '#0B7F3D',
		"orange": '#EE7748',
		"aaa": "#333333",
		"mustColor": "#D11021",
		"shouldColor": "#0B7F3D",
		"couldColor": "#FBBD0E"
	}
};

const entityProfiles = []

const skins = {
	fontFamily: fontFamilyObj,
	globalProfiles: globalProfiles,
	entityProfiles: entityProfiles
};

export default skins;
