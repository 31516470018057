import React from 'react';
import styles from './downArrowReport.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';
import {checkIfPresent} from 'util/utilFunctions';

const DownArrowReport = (props) => {
  const skin = props.skinGuide.globalProfiles.palette
  let color = skin.grayColor4;
  if (checkIfPresent(props.svgColor)) {
    color = props.svgColor;
  }
  return (
    <div styleName="main-component">
      <svg  viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.667 9.33358L8.00033 12.0002M8.00033 12.0002L5.33366 9.33358M8.00033 12.0002L8.00033 3.33374" stroke="#D11021" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    </div>
  );
}

export default applyWrappers(DownArrowReport, styles);
