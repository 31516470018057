import React from 'react';
import styles from './newActions.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const ActionImage = (props) => {
    const color = props.color;
    const secondaryColor = props.secondaryColor || '#FF5B7F';
    let svgId = props.svgId || 'teamLunch';
    svgId += Math.random();

    let containerStyles = {  };
    if (props.containerStyles) {
        containerStyles = {
            ...containerStyles,
            ...props.containerStyles
        }
    }


    return (
        <div style={containerStyles} styleName="main-component">
            <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.67139 8.17681C4.67139 8.0442 4.72407 7.91703 4.81783 7.82326C4.9116 7.72949 5.03878 7.67681 5.17139 7.67681H7.50005V5.34814C7.50005 5.21554 7.55273 5.08836 7.6465 4.99459C7.74027 4.90082 7.86745 4.84814 8.00005 4.84814C8.13266 4.84814 8.25984 4.90082 8.35361 4.99459C8.44737 5.08836 8.50005 5.21554 8.50005 5.34814V7.67681H10.8287C10.9613 7.67681 11.0885 7.72949 11.1823 7.82326C11.276 7.91703 11.3287 8.0442 11.3287 8.17681C11.3287 8.30942 11.276 8.4366 11.1823 8.53036C11.0885 8.62413 10.9613 8.67681 10.8287 8.67681H8.50005V11.0055C8.50005 11.1381 8.44737 11.2653 8.35361 11.359C8.25984 11.4528 8.13266 11.5055 8.00005 11.5055C7.86745 11.5055 7.74027 11.4528 7.6465 11.359C7.55273 11.2653 7.50005 11.1381 7.50005 11.0055V8.67681H5.17139C5.03878 8.67681 4.9116 8.62413 4.81783 8.53036C4.72407 8.4366 4.67139 8.30942 4.67139 8.17681Z" fill={color} />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.87822 2.68937C6.9532 2.45934 9.04724 2.45934 11.1222 2.68937C12.3402 2.82537 13.3236 3.7847 13.4662 5.00937C13.7129 7.11403 13.7129 9.24003 13.4662 11.3447C13.3229 12.5694 12.3396 13.528 11.1222 13.6647C9.04724 13.8947 6.9532 13.8947 4.87822 13.6647C3.66022 13.528 2.67689 12.5694 2.53422 11.3447C2.28807 9.2401 2.28807 7.11397 2.53422 5.00937C2.67689 3.7847 3.66089 2.82537 4.87822 2.68937ZM11.0116 3.6827C9.01013 3.46086 6.99031 3.46086 4.98889 3.6827C4.61838 3.7238 4.27255 3.88862 4.00727 4.15051C3.74199 4.41241 3.57275 4.75609 3.52689 5.12603C3.28979 7.15335 3.28979 9.20139 3.52689 11.2287C3.57289 11.5985 3.74219 11.9421 4.00746 12.2038C4.27273 12.4656 4.61848 12.6303 4.98889 12.6714C6.97355 12.8927 9.02689 12.8927 11.0116 12.6714C11.3818 12.6302 11.7274 12.4654 11.9926 12.2036C12.2577 11.9419 12.4269 11.5984 12.4729 11.2287C12.71 9.20139 12.71 7.15335 12.4729 5.12603C12.4268 4.75643 12.2575 4.41313 11.9924 4.15152C11.7273 3.8899 11.3817 3.72522 11.0116 3.68403" fill={color} />
            </svg>
        </div>
    );
}



export default applyWrappers(ActionImage, styles);
