import React from 'react';
import styles from './actionLoader.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';

const ActionLoader = (props) => {

	const myStyles = getSkin(props.skinGuide);
	const skin = props.skinGuide.globalProfiles.palette;

	return (
		<div styleName="loader-container">
			<div styleName="svg-container">
				<div styleName="svg-wrapper">
					<img
						width="100%"
						height="100%"
						src={props.getImage("IMG_LOADING")}
						alt=""
					/>
				</div>
			</div>
			<div className={css(myStyles.loadingLabel)}>
				{props.getLabel('label_loading')}
			</div>
		</div>
	);
}

export default applyWrappers(ActionLoader, styles);