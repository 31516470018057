import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
	const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

	return StyleSheet.create({
		container: {
			background: `#FFFFFF`,
			boxShadow: `0px -14px 36px rgba(0, 0, 0, 0.12)`,
			backdropFilter: `blur(11px)`,
			borderTop: `solid 8px ${skin.green}`
		},
		responseContainer: {
			background: `#FFFFFF`
		},
		title: {
			fontFamily: theme.fontFamily.titleFamily,
			fontWeight: 600,
			lineHeight: "normal",
			fontSize: '1.5em',
			color: skin.black
		},
		subTitle: {
			...myTypography.body1,
			fontWeight: 350,
			fontSize: '0.875em',
			lineHeight: "normal",
			color: '#031A20'
		},
		responseMessage: {
			...myTypography.body1,
			fontWeight: "normal",
			// fontWeight: 600,
			fontSize: "0.875em",
			lineHeight: "23px",
			color: hexToRgbA(skin.grayColor1, 0.8)
		},
		personName: {
			...myTypography.body1,
			fontWeight: 350,
			fontSize: '14px',
			fontStyle: "italic",
			color: skin.grayColor4
		}
	});
}

export default getSkin;