import React from 'react';
import styles from './could.module.sass';
// import getSkin from './skin.js';
import applyWrappers from 'wrappers/ComponentWrapper';

const Skill = (props) => {

	// const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path="url(#clip0_3107_11667)">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M8.69454 10.4917C8.50704 10.679 8.25287 10.7842 7.98787 10.7842C7.72287 10.7842 7.46871 10.679 7.28121 10.4917L3.50921 6.72106C3.41636 6.62817 3.34272 6.5179 3.29249 6.39656C3.24226 6.27521 3.21642 6.14516 3.21645 6.01382C3.21649 5.88249 3.24239 5.75245 3.29267 5.63112C3.34296 5.5098 3.41665 5.39957 3.50954 5.30673C3.60243 5.21388 3.71269 5.14024 3.83404 5.09001C3.95539 5.03978 4.08544 5.01394 4.21678 5.01397C4.34811 5.014 4.47815 5.0399 4.59947 5.09019C4.7208 5.14048 4.83103 5.21417 4.92387 5.30706L7.98787 8.37039L11.0519 5.30639C11.1441 5.21084 11.2544 5.13461 11.3764 5.08214C11.4983 5.02967 11.6295 5.00203 11.7623 5.00081C11.8951 4.9996 12.0268 5.02484 12.1497 5.07506C12.2726 5.12528 12.3843 5.19948 12.4783 5.29333C12.5722 5.38718 12.6465 5.49879 12.6968 5.62167C12.7472 5.74454 12.7725 5.87621 12.7715 6.00898C12.7704 6.14176 12.7428 6.273 12.6905 6.39503C12.6381 6.51705 12.562 6.62743 12.4665 6.71973L8.69454 10.4917Z" fill="#141414" />
				</g>
				<defs>
					<clipPath id="clip0_3107_11667">
						<rect width="16" height="16" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</div>
	);
}

export default applyWrappers(Skill, styles);