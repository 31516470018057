import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
    const skin = globalProfiles.palette;
    const myTypography = typography(theme);

    return StyleSheet.create({
        completeContainer: {
            boxShadow: `0px 0px 15px 5px ${hexToRgbA(skin.black, 0.2)}`,
            background: skin.white,
            fontFamily: skins.fontFamily.bodyFamily,
        },
        headerContainer: {
            background: `linear-gradient(90deg, #972082 0%, #4B1C40 100%)`,

        },
        headerText: {
            fontSize: '1.5em',
            color: skin.white,
            fontWeight: 600,
            fontFamily: skins.fontFamily.titleFamily,
            lineHeight: '2.865vh',
        },
        headerDesc: {
            color: skin.white,
        },
        teamInfoText: {
            ...myTypography.body2,
            fontSize: '1em',
            lineHeight: '2.865vh',
            fontWeight: '350',
            color: skin.black,
            fontFamily: skins.fontFamily.bodyFamily,
        },
        cardContainer: {
            background: skin.white,
            border: `1px solid ${skin.grayColor9}`,
            boxSizing: 'border-box',
            boxShadow: `0px 4px 4px 0px ${skin.black, 0.1}`,
            borderRadius: '8px',
            zIndex: 10,
        },
        cardDesc: {
            ...myTypography.body2,
            fontSize: '0.875em',
            lineHeight: '2.604vh',
            fontWeight: '350',
            color: skin.black,
            padding: '0vh 2vw 1vh 2vw',
        },
        tmMemberName: {
            ...myTypography.body2,
            fontSize: '1em',
            fontWeight: '600',
            lineHeight: '2.604vh',
            color: skin.black,
        },
        tmMemberDesignation: {
            ...myTypography.body2,
            fontSize: '0.875em',
            lineHeight: '2.604vh',
            fontWeight: '600',
            color: `rgba(51, 51, 51, 1)`,
        },
        statName: {
            ...myTypography.body2,
            fontSize: '10px',
            lineHeight: '163.42%',
            color: skin.black,
            opacity: '0.5',
            borderRadius: '10px',
            marginBottom: '4px',
            textTransform: 'uppercase'
        },
        statValue: {
            ...myTypography.body2,
            fontSize: '10px',
            lineHeight: '14px',
            color: skin.black,
            fontWeight: 'bold',
            background: 'green'
        }
    });
}

export default getSkin;