import React from 'react';
import styles from './newActions.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const ActionImage= (props) => {
    const color = props.color;
    const secondaryColor = props.secondaryColor || '#FF5B7F';
    let svgId = props.svgId || 'teamLunch';
    svgId += Math.random();

    let containerStyles = {  };
    if (props.containerStyles) {
        containerStyles = {
            ...containerStyles,
            ...props.containerStyles
        }
    }


    return (
        <div style={containerStyles} styleName="main-component">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 15C12.875 15 15 12.949 15 8C15 12.949 17.11 15 22 15C17.11 15 15 17.11 15 22C15 17.11 12.875 15 8 15ZM2 6.5C5.134 6.5 6.5 5.182 6.5 2C6.5 5.182 7.857 6.5 11 6.5C7.857 6.5 6.5 7.857 6.5 11C6.5 7.857 5.134 6.5 2 6.5Z" stroke={color} stroke-width="2" stroke-linejoin="round"/>
            </svg>
        </div>
    );
}



export default applyWrappers(ActionImage, styles);
