import React from 'react';
import styles from './skill.module.sass';
// import getSkin from './skin.js';
import applyWrappers from 'wrappers/ComponentWrapper';

const Skill = (props) => {

	// const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M10 18.5V18.393C10 17.598 9.504 16.905 8.883 16.409C8.07246 15.7626 7.48289 14.8802 7.1959 13.884C6.90891 12.8878 6.93868 11.827 7.28109 10.8485C7.62351 9.86991 8.26164 9.02199 9.10716 8.42207C9.95268 7.82215 10.9638 7.49988 12.0005 7.49988C13.0372 7.49988 14.0483 7.82215 14.8938 8.42207C15.7394 9.02199 16.3775 9.86991 16.7199 10.8485C17.0623 11.827 17.0921 12.8878 16.8051 13.884C16.5181 14.8802 15.9285 15.7626 15.118 16.409C14.496 16.906 14 17.598 14 18.393V18.5M10 18.5V20.5C10 20.7652 10.1054 21.0196 10.2929 21.2071C10.4804 21.3946 10.7348 21.5 11 21.5H13C13.2652 21.5 13.5196 21.3946 13.7071 21.2071C13.8946 21.0196 14 20.7652 14 20.5V18.5M10 18.5H14M20 12.5H21M4 12.5H3M12 4.5V3.5M17.657 6.843L18.364 6.136M6.344 6.843L5.636 6.136M12 15.5V13.5" stroke={props.color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
		</div>
	);
}

export default applyWrappers(Skill, styles);