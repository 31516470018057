import { StyleSheet } from 'aphrodite';
import typography from 'defaults/typography';
import { hexToRgbA } from 'util/styleUtil';
import skins from 'defaults/skins'

const getSkin = (theme) => {
	const skin = theme.globalProfiles.palette;
	const myTypography = typography(theme);

	return StyleSheet.create({
		progressStartText: {
			...myTypography.body2,
			fontSize: '0.938em',
			// textTransform: 'uppercase',
			color: skin.white,
			lineHeight: 'normal'
		},
		progressEndText: {
			...myTypography.body2,
			fontSize: '0.938em',
			// textTransform: 'uppercase',
			color: skin.white,
			lineHeight: 1
		},
		completedDay: {
			background: skin.white
		},
		notCompletedDay: {
			background: hexToRgbA(skin.white, 0.3)
		},
		currentDay: {
			background: skin.white
		},
		dayTag: {
			...myTypography.body2,
			fontSize: '0.625em',
			color: skin.primaryColor,
			background: skin.white,
			fontWeight: 700,
			lineHeight: 1
		},
		pastDayContainer: {
			backgroundColor: skin.orange,
			borderRadius: '8px'
		},
		currentDayContainer: {
			backgroundColor: skin.white,
			borderRadius: '8px'
		},
		futureDayContainer: {
			backgroundColor: '#333333',
			borderRadius: '8px'
		},
		sprintContainer: {
			border: '0.5px solid #FFFFFF4D',
			borderRadius: '2px'
		},
		deadlineContainer: {
			color: skin.white,
			fontWeight: 600,
			fontSize: '0.75em',
			fontFamily: skins.fontFamily.bodyFamily
		},
		progressValue: {
			color: skin.white,
			fontWeight: 700,
			fontSize: '1em',
			fontFamily: skins.fontFamily.timerFamily
		},
	});
}

export default getSkin;