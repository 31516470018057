import React from 'react';
import styles from './blockerResolved.module.sass';
// import getSkin from './skin.js';
import applyWrappers from 'wrappers/ComponentWrapper';

const BlockerResolved = (props) => {

	// const myStyles = getSkin(props.skinGuide);
	return (
		<div styleName="main-component">
			<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="6" cy="6" r="5.5" stroke="#0B7F3D"/>
<path d="M3.05762 6.62474L4.58639 8.15351L8.66312 4.07678" stroke="#0B7F3D" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
		</div>
	);
}

export default applyWrappers(BlockerResolved, styles);