import React, { Component } from 'react';
import styles from './leaderboard.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';
import { checkIfPresent } from 'util/utilFunctions';

class Leaderboard extends Component {

    render() {
        const skin = this.props.skinGuide.globalProfiles.palette
        let color = skin.white;
        if (checkIfPresent(this.props.svgColor)) {
            color = this.props.svgColor;
        }
        return (
            <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.00003 9.75019V13.5002M4.50003 16.5002H13.5M9.77777 2.15044L10.569 3.74719C10.677 3.96919 10.965 4.18219 11.208 4.22344L12.6435 4.46344C13.5615 4.61794 13.7775 5.28919 13.116 5.95219L12 7.07719C11.811 7.26769 11.7075 7.63519 11.766 7.89844L12.0855 9.29044C12.3375 10.3929 11.757 10.8197 10.7895 10.2429L9.44478 9.44044C9.20178 9.29494 8.80127 9.29494 8.55377 9.44044L7.20828 10.2429C6.24528 10.8197 5.66028 10.3884 5.91228 9.29044L6.23178 7.89769C6.29028 7.63519 6.18678 7.26769 5.99778 7.07719L4.88178 5.95219C4.22478 5.28919 4.43628 4.61794 5.35428 4.46344L6.78978 4.22344C7.02828 4.18219 7.31628 3.96919 7.42428 3.74719L8.21628 2.14969C8.64828 1.28344 9.35027 1.28344 9.77777 2.14969M6.00003 15.7502C6.00003 14.6897 6.00003 14.1594 6.33003 13.8302C6.65853 13.5002 7.18878 13.5002 8.25003 13.5002H9.75003C10.8105 13.5002 11.3408 13.5002 11.67 13.8302C12 14.1594 12 14.6897 12 15.7502V16.5002H6.00003V15.7502Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        );
    }
}



export default applyWrappers(Leaderboard, styles);
