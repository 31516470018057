import React, { useState, useEffect, useRef } from 'react';
import styles from './action.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import ActionOptionsPopover from 'components/sprint/execution/actionOptionsPopover';
import { actionIds } from 'config/actions';

import TeamLunch from 'svgComponents/actions/teamLunch';
import Standup from 'svgComponents/actions/standup';
import MarketPulseSurvey from 'svgComponents/actions/marketPulseSurvey';
import RebuildFeature from 'svgComponents/actions/rebuildFeature';
import ReplanSprint from 'svgComponents/actions/replanSprint';
import Retrospect from 'svgComponents/actions/retrospect';
import TeamMotivationSurvey from 'svgComponents/actions/teamMotivationSurvey';
import UpdatePrd from 'svgComponents/actions/updatePrd';
import UserFeedback from 'svgComponents/actions/userFeedback';
import UserResearch from 'svgComponents/actions/userResearch';
import Training from 'svgComponents/actions/training';
import MeetTheTeam from 'svgComponents/actions/meetTheTeam';
import Mom from 'svgComponents/actions/mom';
import TalkOnAgile from 'svgComponents/actions/talkOnAgile';

import Inspire from '../../../../svgComponents/actions/newActions/Inspire.jsx';
import Demonstrate from '../../../../svgComponents/actions/newActions/Demonstrate.jsx';
import Stakeholders from '../../../../svgComponents/actions/newActions/Stakeholders.jsx';
import Consult from '../../../../svgComponents/actions/newActions/Consult.jsx';
import RetrospectNew from '../../../../svgComponents/actions/newActions/RetrospectNew.jsx';
import Review from '../../../../svgComponents/actions/newActions/Review.jsx';
import Reschedule from '../../../../svgComponents/actions/newActions/Reschedule.jsx';
import Negotiate from '../../../../svgComponents/actions/newActions/Negotiate.jsx';
import Coach from '../../../../svgComponents/actions/newActions/Coach.jsx';
import Lead from '../../../../svgComponents/actions/newActions/Lead.jsx';
import Facilitate from '../../../../svgComponents/actions/newActions/Facilitate.jsx';
import Prioritize from '../../../../svgComponents/actions/newActions/Prioritize.jsx';

import BlockerMust from '../../../../svgComponents/actions/newActions/BlockerMust.jsx';
import BlockerSolArch from '../../../../svgComponents/actions/newActions/BlockerSolArch.jsx';
import BlockerChallenge from '../../../../svgComponents/actions/newActions/BlockerChallenge.jsx';
import BlockerVisionary from '../../../../svgComponents/actions/newActions/BlockerVisionary.jsx';
import BlockerLater from '../../../../svgComponents/actions/newActions/BlockerLater.jsx';
import BlockerShould from '../../../../svgComponents/actions/newActions/BlockerShould.jsx';
import BlockerReposition from '../../../../svgComponents/actions/newActions/BlockerReposition.jsx';
import BlockerNothing from '../../../../svgComponents/actions/newActions/BlockerNothing.jsx';
import BlockerBrain from '../../../../svgComponents/actions/newActions/BlockerBrain.jsx';
import BlockerValidate from '../../../../svgComponents/actions/newActions/BlockerValidate.jsx';
import BlockerArch from '../../../../svgComponents/actions/newActions/BlockerArch.jsx';
import BlockerSpecs from '../../../../svgComponents/actions/newActions/BlockerSpecs.jsx';
import BlockerDetail from '../../../../svgComponents/actions/newActions/BlockerDetail.jsx';
import BlockerDiscuss from '../../../../svgComponents/actions/newActions/BlockerDiscuss.jsx';
import BlockerAssign from '../../../../svgComponents/actions/newActions/BlockerAssign.jsx';
import BlockerDesign from '../../../../svgComponents/actions/newActions/BlockerDesign.jsx';
import BlockerOwn from '../../../../svgComponents/actions/newActions/BlockerOwn.jsx';


const Action = (props) => {

	const myRef = useRef(null);
	const [isActionOnHover, setIsActionOnHover] = useState(false);

	let isCurrentActionSelected = props.uiState.showActionOptionPopover
		&& props.uiState.selectedActionId === props.action.id;

	if (props.uiState.showOverlay
		&& props.uiState.overlayComponent === 'STORY_POPUP'
	) {
		isCurrentActionSelected &= props.isActionsFromStoryPopup;
	}

	useEffect(() => {
		if (isCurrentActionSelected) {
			document.addEventListener('mousedown', closeOpenPopover, false);
		} else {
			document.removeEventListener('mousedown', closeOpenPopover, false);
		}
		return () => {
			document.removeEventListener('mousedown', closeOpenPopover, false);
		}
	});

	const closeOpenPopover = (e) => {
		if (myRef.current.contains(e.target)) {
			return;
		}
		props.setUiState({
			showActionOptionPopover: false,
			selectedActionId: null
		});
	}

	// const getLatestPrdId = () => {
	// 	let selectedPrdId = props.userPrds.selectedPrdId;

	// 	if (!selectedPrdId && props.userPrds.userPrdsList.length > 0) {
	// 		selectedPrdId = props.userPrds.userPrdsList[
	// 			props.userPrds.userPrdsList.length - 1
	// 		].prdId;
	// 	}

	// 	if (!selectedPrdId) {
	// 		return null;
	// 	}

	// 	return selectedPrdId;
	// }
	let blockerId = -1;
	const getBlockerFromAction = (actionId) => {
		const blockers = props.userBlockers.blockerList;
		for(let i = 0; i<blockers.length; i++){
			const blocker = blockers[i];
			for(let j=0; j<blocker.actions.length; j++){
				const blockerAction = blocker.actions[j];
				if(actionId == blockerAction.actionId) return blockerAction.blockerId;
			}
		}
	}

	const myStyles = getSkin(props.skinGuide);
	const skin = props.skinGuide.globalProfiles.palette;

	let selectedActionOptions = props.actions.actionOptionsList.filter(actionOption =>
		actionOption.actionId === props.action.id
	);
	if(props.isActionsFromStoryPopup){
		if(props.actionsList.length>0){
			blockerId = getBlockerFromAction(props.actionsList[0].id);
		}
		selectedActionOptions = props.actionsList.filter(actionOption =>
			actionOption.id === props.action.id
		);
	}
	// console.log(props)
	// console.log(selectedActionOptions)
	// if (props.action.id === actionIds.UPDATE_PRD) {
	// 	const updatePrdActionOption = selectedActionOptions[0];
	// 	selectedActionOptions = [];

	// 	const latestPrdId = getLatestPrdId();

	// 	let prdsList = props.prds.prdsList;

	// 	if (latestPrdId) {
	// 		prdsList = prdsList.filter(prd => prd.id > latestPrdId);
	// 	}

	// 	prdsList.map(prd => {
	// 		selectedActionOptions.push({
	// 			...updatePrdActionOption,
	// 			...prd
	// 		});
	// 		return 1;
	// 	})
	// }

	const renderActionPopover = () => {
		if (isCurrentActionSelected) {
			return (
				<ActionOptionsPopover
					{...props}
					selectedActionOptions={selectedActionOptions}
					setIsActionOnHover={setIsActionOnHover}
					isActionsFromStoryPopup={props.isActionsFromStoryPopup}
					blockerId={blockerId}
				/>
			);
		}
		return null;
	}

	const openActionOptionPopover = () => {
		const { tutorial } = props
		if (tutorial && tutorial.isRunning) {
			props.endTutorial()
		}

		props.setUiState({
			showActionOptionPopover: true,
			selectedActionId: props.action.id
		});
	}

	const renderActionImage = () => {
		let color = skin.black;
		let secondaryColor = '#FF5B7F';

		if (isCurrentActionSelected) {
			color = skin.white;
			secondaryColor = skin.white;
		}
		// console.log(props.action)
		switch (props.action.imageKey) {
			case 'team_lunch':
				return <TeamLunch svgId='team_lunch' color={color} secondaryColor={secondaryColor} />

			case 'standup':
				return <Standup svgId='standup' color={color} secondaryColor={secondaryColor} />

			case 'market_pulse_survey':
				return <MarketPulseSurvey svgId='market_pulse_survey' color={color} secondaryColor={secondaryColor} />

			case 'rebuild_feature':
				return <RebuildFeature svgId='rebuild_feature' color={color} secondaryColor={secondaryColor} />

			case 'replan_sprint':
				return <ReplanSprint svgId='replan_sprint' color={color} secondaryColor={secondaryColor} />

			case 'retrospect':
				return <Retrospect svgId='retrospect' color={color} secondaryColor={secondaryColor} />

			case 'team_motivation_survey':
				return <TeamMotivationSurvey svgId='team_motivation_survey' color={color} secondaryColor={secondaryColor} />

			case 'update_prd':
				return <UpdatePrd svgId='update_prd' color={color} secondaryColor={secondaryColor} />

			case 'user_feedback':
				return <UserFeedback svgId='user_feedback' color={color} secondaryColor={secondaryColor} />

			case 'user_research':
				return <UserResearch svgId='user_research' color={color} secondaryColor={secondaryColor} />

			case 'training':
				return <Training svgId='training' color={color} secondaryColor={secondaryColor} />

			case 'meet_the_team':
				return <MeetTheTeam svgId='meet_the_team' color={color} secondaryColor={secondaryColor} />

			case 'mom':
				return <Mom svgId='mom' color={color} secondaryColor={secondaryColor} />

			case 'talk_on_agile':
				return <TalkOnAgile svgId='talk_on_agile' color={color} secondaryColor={secondaryColor} />

			case 'action_inspire':
				return <Inspire color={color} />
			case 'action_demonstrate':
				return <Demonstrate color={color} />;
			case 'action_stakeholders':
				return <Stakeholders color={color} />;
			case 'action_consult':
				return <Consult color={color} />;
			case 'action_retrospectnew':
				return <RetrospectNew color={color} />;
			case 'action_review':
				return <Review color={color} />;
			case 'action_reschedule':
				return <Reschedule color={color} />;
			case 'action_negotiate':
				return <Negotiate color={color} />;
			case 'action_coach':
				return <Coach color={color} />;
			case 'action_lead':
				return <Lead color={color} />;
			case 'action_facilitate':
				return <Facilitate color={color} />;
			case 'action_prioritize':
				return <Prioritize color={color} />;

			case 'BLOCKER_MUST':
				return <BlockerMust color={color} />;
			case 'BLOCKER_SOL_ARCH':
				return <BlockerSolArch color={color} />;
			case 'BLOCKER_CHALLENGE':
				return <BlockerChallenge color={color} />;
			case 'BLOCKER_VISIONARY':
				return <BlockerVisionary color={color} />;
			case 'BLOCKER_LATER':
				return <BlockerLater color={color} />;
			case 'BLOCKER_SHOULD':
				return <BlockerShould color={color} />;
			case 'BLOCKER_REPOSITION':
				return <BlockerReposition color={color} />;
			case 'BLOCKER_NOTHING':
				return <BlockerNothing color={color} />;
			case 'BLOCKER_BRAIN':
				return <BlockerBrain color={color} />;
			case 'BLOCKER_VALIDATE':
				return <BlockerValidate color={color} />;
			case 'BLOCKER_ARCH':
				return <BlockerArch color={color} />;
			case 'BLOCKER_SPECS':
				return <BlockerSpecs color={color} />;
			case 'BLOCKER_DETAIL':
				return <BlockerDetail color={color} />;
			case 'BLOCKER_DISCUSS':
				return <BlockerDiscuss color={color} />;
			case 'BLOCKER_ASSIGN':
				return <BlockerAssign color={color} />;
			case 'BLOCKER_DESIGN':
				return <BlockerDesign color={color} />;
			case 'BLOCKER_OWN':
				return <BlockerOwn color={color} />;

			default:
				return <UserFeedback svgId='user_feedback' color={color} secondaryColor={secondaryColor} />
		}
	}

	let actionContainerClass = css(myStyles.actionContainer);
	if (isCurrentActionSelected) {
		actionContainerClass = css(myStyles.selectedActionContainer);
	} else if (isActionOnHover) {
		actionContainerClass = css(myStyles.hoverActionContainer);
	}

	let actionStyle = "action-container";
	if(props.isActionsFromStoryPopup){
		actionStyle = "action-container-story"
	}

	let actionNameClass = css(myStyles.actionName);
	if(props.isActionsFromStoryPopup) {
		actionNameClass = css(myStyles.actionNameStory)
	}

	let actionImageStyle = "action-image-container";
	if(props.isActionsFromStoryPopup) {
		actionImageStyle = "action-image-story"
	}

	return (
		<div ref={myRef} styleName={selectedActionOptions.length === 0 ? "action-wrapper disable-action" : "action-wrapper"}
			onMouseOver={() => { setIsActionOnHover(true) }}
			onMouseOut={() => { setIsActionOnHover(false) }}
		>
			<div onClick={openActionOptionPopover} styleName={actionStyle} className={actionContainerClass}>
				<div  styleName={actionImageStyle}>
					{renderActionImage()}
				</div>
				<div styleName="action-name" className={actionNameClass}>
					{
						props.getLabel(props.action.name)
					}
				</div>
			</div>
			<div>
				{renderActionPopover()}
			</div>
		</div>
	);
}

export default applyWrappers(Action, styles);