import React from 'react';
import styles from './decrementArrow.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';

const DecrementArrow = (props) => {
    const skin = props.skinGuide.globalProfiles.palette
    const color = props.color || skin.white;

    return (
        <div styleName="main-component">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 9.33333L8.33333 12M8.33333 12L5.66667 9.33333M8.33333 12L8.33333 3.3335" stroke="#D11021" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        </div>
    );
}



export default applyWrappers(DecrementArrow, styles);
